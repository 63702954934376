/* eslint no-undef: 0 */
'use strict';
import localePtBR from './pt-BR';

/*
Tag deve seguir o IETF's BCP 47, https://www.w3.org/International/articles/language-tags/

 Ao adicionar um novo idioma, adicionar também em src/plugins/vuetify.js

 */

export const i18nConfig:any = {
  fallbackLocale: 'pt-BR',
  datetimeFormats: {
    // 'en-US': {
    //   numeric: {
    //     year: 'numeric', month: 'numeric', day: 'numeric'
    //   },
    //   short: {
    //     year: 'numeric', month: 'short', day: 'numeric'
    //   },
    //   long: {
    //     year: 'numeric', month: 'short', day: 'numeric',
    //     hour: 'numeric', minute: 'numeric', hour12: false
    //   },
    //   veryLong: {
    //     year: 'numeric', month: 'short', day: 'numeric',
    //     weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
    //   }
    // },
    'pt-BR': {
      numeric: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric', hour12: false
      },
      veryLong: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
      }
    }
  },
  numberFormats: {
    // 'en-US': {
    //   currency: {
    //     style: 'currency',
    //     currency: 'BRL',
    //     currencyDisplay: 'symbol'
    //   }
    // },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol'
      }
    }
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true
};

export const languages:{[key: string]:any} = {
  // 'en-US': {
  //   flag: 'us',
  //   name: 'English',
  //   database: 'en',
  //   hreflang: 'en_US',
  //   vuetify: 'en',
  //   iso: 'en-US',
  // },
  'pt-BR': {
    flag: 'br',
    name: 'Português',
    database: 'ptbr',
    hreflang: 'pt_BR',
    vuetify: 'pt',
    iso: 'pt-BR',
  }
};

/**
 *
 * @type {({database: string, flag: string, hreflang: string, iso: string, name: string})[]}
 */
export const languagesList = [
  languages['pt-BR'],
];

export const languagesFiles:{[key: string]:any} = {
  'pt-BR': localePtBR
};

export function getLocale(inputLocale?:string|any) {
  return fcmBestAvailableLocale(Object.keys(languages), i18nConfig.fallbackLocale,
    inputLocale,
    storageGetItem('locale') || [],
    typeof navigator!=='undefined'?(navigator.languages || [navigator.language]):[]);
}


export function getLocalePriority(inputLocale:any) {
  return fcmBestAvailableLocale(Object.keys(languages), i18nConfig.fallbackLocale,
    ...inputLocale,
    storageGetItem('locale') || [],
    typeof navigator!=='undefined'?(navigator.languages || [navigator.language]):[]);
}

export function setLocale(locale:string) {
  console.log('setLocale', locale);
  if(locale) {
    storageSetItem('locale', locale);
  }
}

// Baseado na BestAvailableLocale abstract operation
// https://tc39.github.io/ecma402/#sec-bestavailablelocale
export function fcmBestAvailableLocale(availableLocales:string[], fallback:string, ...localePriority:any[]) {
  try {
    if(!Array.isArray(availableLocales)) {
      if(availableLocales) {
        availableLocales = [availableLocales];
      }
      else {
        return fallback;
      }
    }
    availableLocales = availableLocales.map((locale:string) =>
      locale
        .split('-')
        .map((localePart:any) => localePart.toLowerCase())
        .join('-')
    );

    let last = 0;
    for (let i = 0; i < localePriority.length; i++) {
      if(!Array.isArray(localePriority[i])) {
        if(localePriority[i]) {
          localePriority[i] = [localePriority[i]];
        }
        else {
          localePriority[i] = [];
          continue;
        }
      }
      localePriority[i] = localePriority[i].map((locale:string) =>
        locale
          .split('-')
          .map((localePart:any) => localePart.toLowerCase())
          .join('-')
      );

      for(let j = 0; j<localePriority[i].length; j++) { // buscando locale identico
        if(availableLocales.includes(localePriority[i][j])) {
          // @ts-ignore
          return Intl.getCanonicalLocales(localePriority[i][j])[0];
        }
      }

      for(let j = 0; j<localePriority[i].length; j++) { // buscando locale parecido
        let subLocale = localePriority[i][j];
        while(subLocale && subLocale.length>1) {
          for(let k = 0; k<availableLocales.length;k++) {
            if(availableLocales[k].indexOf(subLocale)===0) {
              // @ts-ignore
              return Intl.getCanonicalLocales(availableLocales[k])[0];
            }
          }
          last = subLocale.lastIndexOf('-');
          if(last===-1) {
            break;
          }
          subLocale = subLocale.substring(0, last);
        }
      }
    }
  }catch(err) {
    console.error('invalid availableLocales',err);
  }
  return fallback;
}




/*
  Operação de tradução para o node server, para o Vue utilizar o plugin vue-i18n já devidamente configurado no Vue
 */

export function translate(locale:string, key:string, objValues:any = null) {
  let res = key.split('.').reduce(function(o, k) {
    return o && o[k];
  }, languagesFiles[locale]);

  if(typeof res!=='undefined') {
    if(objValues) {
      return res.replace(/\{([^}]+)}/gi, function(matched:string, key:string) {
        return objValues[key] || matched;
      });
    }
    return res;
  }

  res = key.split('.').reduce(function(o, k) {
    return o && o[k];
  }, languagesFiles[i18nConfig.fallbackLocale]);

  if(typeof res!=='undefined') {
    if(objValues) {
      return res.replace(/\{[^}]+}/gi, function(matched:string) {
        return objValues[matched.substring(1, matched.length-1)] || '';
      });
    }
    return res;
  }
  return key;
}

export function translateDb(locale:string, obj:any) {
  if(typeof obj==='string') {
    return obj;
  }
  if(languages[locale] && obj && obj[languages[locale].database]) {
    return obj[languages[locale].database];
  }
}




export function formatCurrency(locale:string, input:number, currency?:string, currencyDisplay?:string) {
  if(!i18nConfig.numberFormats[locale]) {
    console.warn('i18n Locale not found', locale);
  }
  return Number(input).toLocaleString(locale,
    {
      style: 'currency',
      currency: currency || i18nConfig.numberFormats[locale]?.currency?.currency || 'BRL',
      currencyDisplay: currencyDisplay || i18nConfig.numberFormats[locale]?.currency?.currencyDisplay || undefined
    });
}


export function getLocalEnvironment(req:any, extraLang:any = null, getPathCloudFunctions=false) {
  let lang:any[] = [];
  let isDark = false;
  let pathCloudFunctions = '';
  if(extraLang) {
    if ('string' === typeof extraLang) {
      lang = [extraLang];
    }
    else if (Array.isArray(extraLang)) {
      lang = extraLang;
    }
  }
  if(req) {
    if(req.query && req.query.lang) {
      lang.push(req.query.lang);
    }
    if(req.params && req.params.lang) {
      lang.push(req.params.lang);
    }
    if(req.acceptsLanguages) {
      const tmp = req.acceptsLanguages();
      lang = lang.concat(tmp);
    }
    if(req.query && req.query.isDark) {
      isDark = JSON.parse(req.query.isDark) || false;
    }
    if(getPathCloudFunctions) {
      for(let i=0;i<((req.url ||'').split('/').length-2);i++) {
        pathCloudFunctions += '../';
      }
    }
  }
  const locale = getLocalePriority(lang);
  return {
    t: function(key:string, objValues = null) {
      return translate(locale, key, objValues);
    },
    trdb: function(key:string) {
      return translateDb(locale, key);
    },
    formatCurrency: function(input:number) {
      return formatCurrency(locale, input);
    },
    locale: locale,
    locale_: locale.replace(/-/g, '_'),
    isDark: isDark,
    pathCloudFunctions: pathCloudFunctions,
  };
}

function storageGetItem(key:string) {
  try {
    return window.localStorage.getItem(key);
    // eslint-disable-next-line
  } catch(err) {}
  return null;
}

function storageSetItem(key:string, string:string) {
  try {
    return window.localStorage.setItem(key, string);
    // eslint-disable-next-line
  } catch(err) {}
}


