/**
 * Quando hover typescript no front, incorporar o FcmBatch.ts no front
 * Arquivo gerado com base em fcm/lib/src/FcmBatch.ts
 * Não edite o arqivo FcmBatch.js diretamente
 */

import { writeBatch } from 'firebase/firestore';
// import { writeBatch} from 'https://www.gstatic.com/firebasejs/10.0.0/firebase-firestore.js';

import type {WriteBatch, SetOptions} from '@firebase/firestore';
import type {FcmFirestore, DocumentReference} from './FcmFirestore';


export class FcmBatch {
  batch: WriteBatch;
  maxItems = 499;
  countActual = 0;
  onCommit = false;
  total = 0;
  fcmFirestore: FcmFirestore;
  constructor(fcmFirestore: FcmFirestore, maxItems:number=499) {
    this.fcmFirestore = fcmFirestore;
    this.maxItems = maxItems;
    this.batch = writeBatch(this.fcmFirestore._firestore);
  }
  check() {
    if (this.onCommit) {
      throw new Error('Não é possível adicionar dados enquanto commita');
    }
    return true;
  }

  async set(documentRef: DocumentReference, data: any, options?: SetOptions) {
    if (!this.check()) {
      return false;
    }
    if (options) {
      this.batch.set(documentRef.fcmGetRef(), data, options);
    } else {
      this.batch.set(documentRef.fcmGetRef(), data);
    }
    return this.incAndCommitIfNeed();
  }

  async delete(documentRef: DocumentReference) {
    if (!this.check()) {
      return false;
    }
    this.batch.delete(documentRef.fcmGetRef());
    return this.incAndCommitIfNeed();
  }

  /**
   * Não é tratado o formato field, value...
   * @param documentRef
   * @param data
   */
  update(documentRef: DocumentReference, data: any) {
    if (!this.check()) {
      return false;
    }
    this.batch.update(documentRef.fcmGetRef(), data);
    return this.incAndCommitIfNeed();
  }
  async commit() {
    this.onCommit = true;
    await this.batch.commit();
    this.batch = writeBatch(this.fcmFirestore._firestore);
    this.countActual = 0;
    this.onCommit = false;
  }
  async commitIfNeed() {
    if (this.countActual>=this.maxItems) {
      return this.commit();
    }
  }
  async incAndCommitIfNeed() {
    this.countActual++;
    this.total++;
    return this.commitIfNeed();
  }
}
