import {createI18n} from 'vue-i18n';
import localePtBR from '@common/i18n/pt-BR';
import {i18nConfig, getLocale, languages} from '@common/i18n';

const locale = getLocale();
document.documentElement.setAttribute('lang', locale);

let fcmMissingKey = null;
if(process.env.NODE_ENV !== 'production') {
  // @ts-ignore
  window.fcmMissingKeys = {};
  fcmMissingKey = function(language:string, key:string) {
    // @ts-ignore
    window.fcmMissingKeys[key] = '';
  };
}

// @ts-ignore
export const i18n = new createI18n({
  ...i18nConfig,
  locale: locale,
  messages: {
    'pt-BR': localePtBR,
  },
  missing: fcmMissingKey,
  globalInjection: true,
});

if(languages[locale]) {
  i18n.localeDatabase = languages[locale].database;
}

export const t = i18n.global.t;
