import { createRouter, createWebHistory } from 'vue-router';
import {routes} from '@/router/routes';
import { useAuthStore } from '@/stores/auth';
import {defaultRoutes} from '@common/AuthUtils';
import {getLocale, setLocale, languages} from '@common/i18n';
import {vuetify} from '@/plugins/vuetify';
import {useLayout} from '@/layouts/composables/layout';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    else {
      return { x: 0, y: 0 };
    }
  }
});

function redirectToLogin(workspaces:any, routeTo:any, routeFrom:any, next:any) {
  // Pass the original route to the login component
  if(workspaces?.length>0) {
    next({ name: defaultRoutes.routeNotLogged[workspaces?.[0]] || 'login', query: { redirectFrom: routeTo?.fullPath } });
  }
  else {
    next({ name: 'login', query: { redirectFrom: routeTo?.fullPath } });
  }
}

router.beforeEach((routeTo, routeFrom, next) => {
  const authStore = useAuthStore();
  if(routeTo.query.locale || routeTo.query.lang) {
    const locale = getLocale(routeTo.query.locale || routeTo.query.lang);
    setLocale(locale);
    vuetify.locale.current = languages[locale].vuetify;

    // if(typeof window !=='undefined' && window.VueI18n) {
    //   window.VueI18n.locale = locale;
    //   if(languages[locale]) {
    //     window.VueI18n.localeDatabase = languages[locale].database;
    //     if(router.app && router.app.$vuetify && router.app.$vuetify.theme) {
    //       router.app.$vuetify.lang.current = languages[locale].vuetify;
    //     }
    //     else if(vuetify && vuetify.framework && vuetify.framework.theme) {
    //       vuetify.framework.lang.current = languages[locale].vuetify;
    //     }
    //   }
    // }

    if(document && document.documentElement) {
      document.documentElement.setAttribute('lang', locale);
    }
    delete routeTo.query.locale;
    delete routeTo.query.lang;
    return next({...routeTo});
  }

  if (!routeTo.meta.authRequired) return next();

  let workspaces:any = [];
  let rules:any = [];
  let companyModules:any = {};
  let needCompany:any = false;
  if(routeTo.meta) {
    workspaces = routeTo.meta.workspaces || [];
    rules = routeTo.meta.rules || [];
    companyModules = routeTo.meta.companyModules || [];
    needCompany = routeTo.meta.needCompany || false;
  }

  if (authStore.loggedIn) {
    // Validate the local user token...
    return authStore.validate({workspaces, rules, companyModules}).then(({isOk, user, currentCompanyId, error}) => {
      if(!isOk && error==='notLogged') {
        redirectToLogin(workspaces, null, routeFrom, next);
        return false;
      }
      if(!isOk && error==='denied') {
        console.warn('accessDenied');
        // TODO FCM
        // if(window.vueApp && window.vueApp.$swal && window.vueApp.$t) {
        //   window.vueApp.$swal({
        //     toast: true,
        //     position: 'top-end',
        //     icon: 'error',
        //     title: window.vueApp.$t('router.accessDenied'),
        //     showConfirmButton: false,
        //     timer: 2000
        //   });
        // }
        if(routeTo.name!==defaultRoutes.routeLogged[workspaces?.[0]]) {
          next({ name: defaultRoutes.routeLogged[workspaces?.[0]] || 'login' });
        }
        else {
          next();
        }
        return false;
      }
      // if(rules && rules.length>0) {
      //   let isValid = false;
      //   if(user.rules) {
      //     for(let r of rules) {
      //       if(user.rules[r]) {
      //         isValid = true;
      //         break;
      //       }
      //     }
      //   }
      //   if (!isValid){
      //     console.warn('accessDenied');
      //     if(window.vueApp && window.vueApp.$swal && window.vueApp.$t) {
      //       window.vueApp.$swal({
      //         toast: true,
      //         position: 'top-end',
      //         icon: 'error',
      //         title: window.vueApp.$t('router.accessDenied'),
      //         showConfirmButton: false,
      //         timer: 2000
      //       });
      //     }
      //
      //     if(routeTo.name!==defaultRoutes.routeLogged[workspaces?.[0]] ) {
      //       next({ name: defaultRoutes.routeLogged[workspaces?.[0]] || 'login' });
      //     }
      //     else {
      //       next();
      //     }
      //     return false;
      //   }
      // }
      if(needCompany && !currentCompanyId) {
        console.warn('Need Company');
        // if(window.vueApp && window.vueApp.$swal && window.vueApp.$t) {
        //   window.vueApp.$swal({
        //     toast: true,
        //     position: 'top-end',
        //     icon: 'warning',
        //     title: window.vueApp.$t('router.needCompany'),
        //     showConfirmButton: false,
        //     timer: 2000
        //   });
        // }

        if(routeTo.name!==defaultRoutes.companySelect[workspaces?.[0]]) {
          next({ name: defaultRoutes.companySelect[workspaces?.[0]] || 'login', query: { redirectFrom: routeTo.fullPath } });
        }
        else {
          next();
        }
        return false;
      }
      return next();
    });
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin(workspaces, routeTo, routeFrom, next);
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  if(routeTo.query.isDark) {
    const {setTheme} = useLayout();
    // @ts-ignore
    const isDark = JSON.parse(routeTo.query.isDark) || false;

    if (isDark) {
      setTheme('volan-dark');
    }
    else {
      setTheme('volan-light');
    }

    delete routeTo.query.isDark;
    return next({...routeTo});
  }


  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      if (route.meta && route.meta.beforeResolve) {
        await new Promise<void>((resolve, reject) => {
          // If a `beforeResolve` hook is defined, call it with
          // the same arguments as the `beforeEnter` hook.
          // @ts-ignore
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // @ts-ignore
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        });
      }
    }
  } catch (error) {
    return;
  }
  next();
});


export default router;
