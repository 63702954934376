<script setup lang="ts">
import {computed} from 'vue';

import AppSubMenu from './AppSubMenu.vue';
import {useRoute} from 'vue-router';
import {Layout} from '@common/AuthUtils';
import {routes} from '@/router/routes';
import {t} from '@/plugins/i18n';


const route = useRoute();

// Usar de preferência routeName ao invés de to
type ItemMenu = {
  id?: string,
  label?: string,
  icon?: string,
  url?: string,
  to?: string,
  target?: string,
  items?: ItemMenu[],
  routeName?: string,
  excludeActiveOnAdd?: boolean,
  class?: string,
}

const modelUser: ItemMenu[] = [
  {
    items: [
      {
        id: 'dashboard',
        icon: 'mdi-view-dashboard-outline',
        label: 'all.dashboard',
        to: '/client/dashboard',
        routeName: 'client/dashboard'
      },
      {
        id: 'followUpAppointments',
        icon: 'mdi-file-refresh-outline',
        label: 'all.followUpAppointments',
        to: '/client/followUpAppointments',
        routeName: 'client/followUpAppointments'
      },
      {
        id: 'cashbackTransactions',
        icon: 'mdi-account-cash-outline',
        label: 'all.cashbackTransactions',
        to: '/client/cashbackTransactions',
        routeName: 'client/cashbackTransactions'
      },
      {
        id: 'kudos',
        icon: 'mdi-forum',
        label: 'all.kudos',
        to: '/client/kudos',
        routeName: 'client/kudos'
      },
      {
        id: 'improvements',
        icon: 'mdi-chat-alert',
        label: 'all.improvements',
        to: '/client/improvements',
        routeName: 'client/improvements'
      },
      {
        id: 'ratings',
        icon: 'mdi-star',
        label: 'all.ratings',
        to: '/client/ratings',
        routeName: 'client/ratings'
      },
      {
        id: 'ratcompanyChannelsings',
        icon: 'mdi-forum-plus',
        label: 'all.companyChannels',
        to: '/client/companyChannels',
        routeName: 'client/companyChannels'
      },
      // { icon: 'mdi-database-outline', label: 'all.bigData', to: '/client/BigData', routeName:'client/BigData' },
      // { icon: 'mdi-currency-usd', label: 'all.billing', to: '/client/billing', routeName:'client/billing' },
      // {
      //   icon: 'mdi-check-decagram-outline',
      //   label: 'all.safety',
      //   model: false,
      //   children: [
      //
      //   ]
      // },
      // { icon: 'mdi-plus-box-outline', label: 'all.newEvent', to: '/client/events?add=1', routeName:'client/events' },

      {icon: 'mdi-post', label: 'all.posts', to: '/client/posts', routeName: 'client/posts'},
      {icon: 'mdi-post', label: 'all.postsCategories', to: '/client/postsCategories', routeName: 'client/postsCategories'},
      {
        id: 'registers',
        icon: 'mdi-account-circle-outline',
        label: 'all.registers',
        items: [
          {
            id: 'patients',
            icon: 'mdi-account-multiple',
            label: 'all.clients',
            to: '/client/patients',
            routeName: 'client/patients'
          },
          {
            id: 'users',
            icon: 'mdi-account-group-outline',
            label: 'all.users',
            to: '/client/users',
            routeName: 'client/users'
          },
        ],
      },
      // FCM_INJECT_MENU_DESKTOP  // Do not change that comment
    ]
  }
];

const modelAdmin: ItemMenu[] = [
  {
    items: [
      {icon: 'mdi-view-dashboard', label: 'all.dashboard', to: '/admin/dashboard', routeName: 'admin/dashboard'},
      {icon: 'mdi-shield-account', label: 'all.adminUsers', to: '/admin/admin_users', routeName: 'admin/admin_users'},
      {icon: 'mdi-forum-outline', label: 'all.channels', to: '/admin/channels', routeName: 'admin/channels'},
      {icon: 'mdi-post', label: 'all.posts', to: '/admin/posts', routeName: 'admin/posts'},
      {icon: 'mdi-post', label: 'all.postsCategories', to: '/admin/postsCategories', routeName: 'admin/postsCategories'},
      {icon: 'mdi-domain', label: 'all.companies', to: '/admin/companies', routeName: 'admin/companies'},
      {
        icon: 'mdi-badge-account', label: 'access.menuAccess',
        items: [
          {
            icon: 'mdi-account-network',
            label: 'access.menuAccessProfiles',
            to: '/admin/accessProfiles',
            routeName: 'admin/accessProfiles'
          },
          {
            icon: 'mdi-clipboard-account',
            label: 'access.menuAccessModules',
            to: '/admin/accessModules',
            routeName: 'admin/accessModules'
          },
          {
            icon: 'mdi-account-details-outline',
            label: 'access.menuAccessActions',
            to: '/admin/accessActions',
            routeName: 'admin/accessActions'
          },
        ]
      },
      {icon: 'mdi-account-multiple', label: 'all.users', to: '/admin/users', routeName: 'admin/users'},


      // FCM_INJECT_MENU_DESKTOP  // Do not change that comment
    ]
  }
];

const routeToList: string[] = [];

function fillListRoutes(rs: any[]) {
  for (const route of rs) {
    if (route.children) {
      fillListRoutes(route.children);
    } else if (route.name) {
      routeToList.push(route.name);
    }
  }
}

fillListRoutes(routes);


function checkifExistRoutes(items: ItemMenu[]) {
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i].items?.length) {
      // @ts-ignore
      checkifExistRoutes(items[i].items);
    }
    // @ts-ignore
    else if (items[i].routeName && !routeToList.includes(items[i].routeName)) {
      items[i].label = t(items[i].label || '') + ' * ';
      items[i].routeName = 'Todo';
    }
  }
}

checkifExistRoutes(modelUser);
checkifExistRoutes(modelAdmin);

const modelComputed = computed<ItemMenu[]>(() => {
  if (route.meta.layout === Layout.ADMIN) {
    return modelAdmin;
  } else if (route.meta.layout === Layout.CLIENT) {
    // eslint-disable-next-line
    let model = modelUser;
    // TODO restringir rotas de acordo com o usuário

    return model;
  }
  return [];
});

</script>

<template>
  <AppSubMenu :model="modelComputed"/>
</template>

<style lang="scss" scoped></style>
