export default {
  format: {
    time: 'HH:mm',
    date: 'DD/MM/YYYY',
    dateHour: 'DD/MM/YYYY HH:mm',
    dateSecond: 'DD/MM/YYYY HH:mm:ss'
  },
  login: {
    login: 'Entrar',
    goReset: 'Esqueci minha senha',
    goLogin: 'Retornar ao login',
    accessClient: 'Acesso Cliente',
    accessAdmin: 'Acesso Admin',
    facebook: 'Entrar com Facebook',
    google: 'Entrar com Google',
    apple: 'Entrar com Apple',
    phone: 'Entrar com Telefone',
    facebookRegister: 'Cadastro com Facebook',
    googleRegister: 'Cadastro com Google',
    appleRegister: 'Cadastro com Apple',
    phoneRegister: 'Cadastro com Telefone',
    emailRegister: 'Cadastro com Email',
    getPhoneNumber: 'Insira seu número de telefone',
    getPhoneCode: 'Insira seu código de verificação enviado ao seu celular',
    resetCompany: 'Resetar Senha',
    reset: 'Enviar email',
    error: {
      generic: 'Houve algum erro ao efetuar login',
      phoneEmpty: 'Número de telefone vazio',
      phoneCodeEmpty: 'Código de verificação vazio',
      loginTitle: 'Erro ao efetuar login',
      changePasswordTitle: 'Erro ao alterar a senha',
      providerTitle: 'Erro ao adicionar provider',
      resetNotFound: 'Reset de senha não encontrado',
      accessDenied: 'Acesso negado'
    },
    providerCode: {
      'auth/invalid-email': 'Email inválido',
      'auth/user-disabled': 'Conta desabilitada',
      'auth/user-not-found': 'Usuário não encontrado',
      'auth/wrong-password': 'Senha incorreta',
      'auth/credential-already-in-use': 'Credencial já utilizada',
      'auth/email-already-exists': 'Email já utilizado',
      'auth/too-many-requests': 'Muitas tentativas, tente novamente mais tarde',
      'auth/operation-not-allowed': 'Tipo de login não configurado, tente outra forma de login',
      'auth/network-request-failed': 'Parece que você não está conectado na internet',
      missingEmail: 'O email é necessário',
      missingPassword: 'A senha é necessária',
      internal: 'Erro interno',
      notFound: 'Esse usuário não existe'
    },
    addFacebook: 'Adicionar Facebook',
    addGoogle: 'Adicionar Google',
    addApple: 'Adicionar Apple',
    providers: 'Métodos de autenticação',
    remove: 'Remover',
    providerId: 'Provider',
    email: 'E-mail',
    uid: 'uid',
    changePassword: 'Alterar senha',
    addPassword: 'Adicionar Senha',
    resetClient: 'Resetar senha',
    register: 'Registrar',
    registerClient: 'Registrar',
    resetPassword: 'Resetar senha',
    makeRegister: 'Efetuar cadastro',
    title: 'Entrar',
    password: 'Senha',
    copyright: '© ClinicCompanion',
    choseAnotherPassword: 'Escolha uma nova senha',
    resetSuccess: 'Senha alterada com sucesso',
    userAssociated: 'Usuário existente associado a essa empresa, a senha dele é a senha previamente cadastrada',
    welcomeClient: 'Bem vindo ao ClinicCompanion',
    welcomeClientDev: 'ClinicCompanion, área de desenvolvimento',
    goLoginAdmin: 'Acesso Admin',
    goLoginClient: 'Acesso Doutor'
  },
  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    novenber: 'Novembro',
    december: 'Dezembro'
  },
  all: {
    companyChannels: 'Canais da empresa',
    cashbackTransactions: 'Cashback',
    ratings: 'Avaliações',
    kudos: 'Depoimentos',
    improvements: 'Melhorias',
    followUpAppointments: 'Consultas de retorno',
    channels: 'Canais',
    import: 'Importar',
    externalPayments: 'Pagamentos tomador',
    systemNeedUpdate: 'É necessário atualizar a página',
    bi: 'Power BI',
    map: 'Mapa eventos',
    queryInFirestore: 'Buscar dados no firestore',
    CheckTransactions: 'Verificar transações',
    ParallelUniverse: 'Universo paralelo',
    guideUpperCase: 'GUIA',
    upperCaseInAllSystem: 'Letra maiúscula em tudo que for salvo.',
    ocr: 'OCR',
    ocrAmazonCredit: 'Créditos de OCR',
    dashboardOcr: 'Dashboard OCR',
    externalPayment: 'Pagamento externo',
    guideOcrAmazon: 'Ocr 2.0',
    view: 'Visualizar',
    transfersItems: 'Itens de repasse',
    transfers: 'Repasses',
    generatePdf: 'Gerar pdf',
    notCalculateAutomaticEmergency: 'Não calcular emergência automática na criação do evento.',
    userTransfer: 'Tranferências',
    appeal: 'Recurso',
    appeals: 'Recursos',
    blueAccount: 'Conta Azul',
    typesOfPayment: 'Tipos de pagamento',
    customFields: 'Campos customizados',
    to: 'até',
    emissionDate: 'Data de emissão',
    reportTransactions: 'Relatório de transações',
    warningReplaceAttendanceFee: '* Se for adicionado um novo valor de recebimento do co-participativo a transação antiga será substituída. E o valor produzido passará a ser o novo valor recebido.',
    confirmReceiveAttendanceFee: 'Realizar recebimento do co-participativo?',
    receive: 'Receber',
    imports: 'Importações',
    posts: 'Publicações',
    postsCategories: 'Categorias de publicações',
    postsPublishers: 'Publicadores de publicações',
    templatesImportEvents: 'Templates para importação de eventos',
    importEvents: 'Importar eventos',
    surgerySideBar: 'Cirurgia/Honorário',
    consultationSideBar: 'Consulta',
    examSideBar: 'Exame/ SP/SADT',
    surgery: 'Cirurgia',
    consultation: 'Consulta',
    exam: 'Exame',
    cobrancas: 'Cobranças',
    financial: 'Financeiro',
    integrationConfig: 'Configurações da integração',
    scheduleConfig: 'Configurações da escala',
    tooltips: {
      eventFromAxRegWithId: 'Evento importado do AxReg, id: ',
      eventFromAxReg: 'Evento importado do AxReg',
      eventExportToContaAzul: 'Evento exportado para o Conta Azul',
      downloadXLSX: 'Donwload de planilha',
      invertOrderByDate: 'Inverter ordenação de data',
      filter: 'Filtros',
      selectAll: 'Selecionar todos',
      removeSelect: 'Remover seleção',
      selectEvent: 'Selecionar evento',
      expandEvent: 'Expandir evento',
      minimizeEvent: 'Minimizar evento',
      expandContract: 'Expandir contrato',
      minimizeContract: 'Minimizar contrato',
      actionsOnContract: 'Ações no contrato',
      actionsOnEvent: 'Ações no evento',
      actionsOnEvents: 'Ações nos eventos',
      observations: 'Observações',
      showEventStatus: 'Mostrar histórico de status do evento',
      eventFinishedCannotCreateTransactionAppeal: 'Em evento finalizado não é permitido adicionar transações de recebimento ou de glosa. Os valores adicionados agora ficarão salvos somente no recurso.',
    },
    translate: 'Tradução',
    tools: 'Ferramentas',
    advancedResources: 'Recursos avançados',
    adminResources: 'Recursos de administrador',
    atTime: 'às',
    observations: {
      type: {
        RevertStatus: 'Mudança de status',
        Pending: 'Pendência',
        Message: 'Evento',
        Guide: 'Observação da guia',
        Remove: 'Remoção do evento',
      }
    },
    addedCommentToa: 'adicionou um comentário à ',
    addedCommentTo: 'adicionou um comentário ao ',
    showInGuide: 'Mostrar na guias/xml',
    verifyProcedures: 'Procedimentos',
    verifyEvents: 'Verificar eventos',
    protocols: 'Protocolos',
    items: 'Itens',
    empty: 'Vazio',
    exclude: 'Excluir',
    questions: 'Perguntas',
    fillWithANSPattern: 'Preencher com padrão ANS',
    mandatoryFieldsGuidesXMLAndNewEvent: 'Campos obrigatórios das(os) guias/XML\'s e lançamento do evento',
    mandatoryFieldsGuidesXML: 'Campos obrigatórios das(os) guias/XML\'s',
    mandatoryFields: 'Campos obrigatórios',
    safety: 'Segurança',
    responses: 'Respostas',
    eventsNumber: 'Nº de eventos: ',
    eventsAverage: 'Ticket médio: ',
    active: 'Ativo',
    inactive: 'Inativo',
    importResume: 'Resumo da importação',
    importedEvents: 'Evento(s) importado(s): ',
    notImportedEvents: 'Evento(s) não importado(s): ',
    notImported: 'Não importados',
    imported: 'Importados',
    importationMultiple: 'Importação múltipla',
    integrationsMultiples: 'Integração múltipla',
    taxes: 'Taxas',
    hours: 'Horas',
    axregIntegrations: 'Integraçoes do AXReg',
    noAssignments: 'Sem atribuições',
    myAnesthesias: 'Minhas anestesias',
    noMesssage: 'Sem conteúdo',
    guideConsultation: 'Guia de consulta',
    additionalData: 'Dados adicionais',
    complementationValue: 'Valor complementação: ',
    attendanceFeeValue: 'Valor co-participativo: ',
    attendanceFeeValueReceived: 'Valor co-participativo recebido: ',
    add: 'Adicionar',
    cuts: 'Glosas',
    reasonsCut: 'Motivos de glosa',
    createTheNote: 'Crie a nota',
    monthFilter: 'Filtrar mês',
    producedReceived: 'Produzido e Recebido',
    isEmergency: 'É Emergência?',
    guideSPSADT: 'Guia SP/SADT',
    guideFees: 'Guia de honorários',
    save: 'Salvar',
    OK: 'OK',
    selectGlosaReasons: 'Selecione os motivos da glosa',
    addReason: 'Adicionar motivo',
    addGeneralCut: 'Glosa geral',
    addCut: 'Adicionar glosa',
    download: 'Baixar',
    nameSheet: 'Nome da Planilha',
    cbhpm: 'CBHPM',
    associateds: 'Associados',
    notAssociateds: 'Não Associados',
    helpAnesthetistNotAssociated: 'Todos os anestesistas que não estão em nenhum integração com o AXREG.',
    helpAnesthetistAssociated: 'Todos os anestesistas que estão em pelo menos uma integração em alguma instituição no AXREG.',
    helpInsuranceNotAssociated: 'Todos os convênios que não estão em nenhum integração com o AXREG.',
    helpInsuranceAssociated: 'Todos os convênios que estão em pelo menos uma integração em alguma instituição no AXREG.',
    helpNotAssociated: 'Todos os cirurgiões que não estão em nenhum integração com o AXREG.',
    helpAssociated: 'Todos os cirurgiões que estão em pelo menos uma integração em alguma instituição no AXREG.',
    dataSurgeonAXREG: 'Dados do cirurgião no AXREG:',
    dataSurgeonVolan: 'Dados do cirurgião no Volan:',
    dataAnesthetistAXREG: 'Dados do Anestesista no AXREG:',
    dataAnesthetistVolan: 'Dados do Anestesista no Volan:',
    dataInsuranceAXREG: 'Dados do Convênio no AXREG:',
    dataInsuranceVolan: 'Dados do Convênio no Volan:',
    hospitalDefault: 'Hospital Padrão',
    itemsNotAssociateds: 'Items do AXREG não associados ao Volan: ',
    allItems: 'Relação de todos os items do AXREG e do Volan: ',
    showNotAssociateds: 'Mostrar não associados?',
    eventReports: 'Eventos',
    reports: 'Produção',
    schedules: 'Escala',
    schedulesLimits: 'Turnos',
    core: 'Controle e Registro',
    demands: 'Demandas',
    workloads: 'Horários do profissional',
    exceptionServices: 'Exceções de trabalho',
    onCallSchedule: 'Escalas de plantões',
    workplaces: 'Locais de trabalho',
    export: 'Exportar',
    value: 'Valor',
    helpInformation: 'Processando, glosas e perdas. Todos estão sendo comparados com o mesmo período (mês) do ano anterior.',
    loading: 'Carregando',
    att: 'Atualizar',
    over: 'Over',
    produced: 'Produzido',
    received: 'Recebido',
    processing: 'Processando',
    glosas: 'Glosas',
    loss: 'Perdas',
    overview: 'Visão geral',
    axreg: 'Axreg',
    axregToFrom: 'Axreg De/Para',
    waiting: 'Aguardando',
    integrations: 'Integrações',
    all: 'Todos',
    showAll: 'Mostrar todos',
    notAssociate: 'Não associados',
    anesthetistsIntegration: 'Integração de anestesistas',
    surgeonsIntegration: 'Integração de cirurgiões',
    specialties: 'Especialidades',
    sigla: 'Sigla',
    othersRecentsPosts: 'OUTRAS PUBLICAÇÕES RECENTES.',
    lastPostsDescription: 'Logo abaixo você encontra as publicações mais recentes.',
    lastPublications: 'ÚLTIMAS PUBLICAÇÕES',
    lastPublicationsInThisCAtegory: 'ÚLTIMAS PUBLICAÇÕES NESTA CATEGORIA',
    recents: 'RECENTES',
    backToHome: 'VOLTAR AO INÍCIO',
    publishedIn: 'PUBLICADO EM ',
    keepReading: 'CONTINUAR LENDO>>',
    othersCategories: 'OUTRAS CATEGORIAS',
    subscribeStart: 'Começar',
    know: 'Conheça',
    contact: 'Contato',
    rightsReserved: 'Volan 2020. Todos os direitos reservados',
    next: 'Próximo',
    knowMore: 'Saiba Mais',
    notImplemented: 'Não implementado',
    attendance: 'Atendimento',
    settings: 'Configurações',
    schedule: 'Horários',
    information: 'Informação',
    informations: 'Informações',
    performance: 'Performance',
    history: 'Histórico',
    of: 'de',
    step: 'Etapa',
    category: 'Categoria',
    subCategory: 'Sub Categoria',
    profile: 'Perfil',
    categories: 'Categorias',
    users: 'Usuários',
    conclude: 'Concluir',
    close: 'Fechar',
    cancel: 'Cancelar',
    nothingToList: 'Lista vazia',
    search: 'Pesquisar',
    additionals: 'Adicionais',
    createProduct: 'Criar Produto',
    editCategory: 'Editar Categoria',
    companyConfigs: 'Configurações',
    edit: 'Editar',
    create: 'Novo',
    clients: 'Clientes',
    regions: 'Regiões',
    region: 'Região',
    utcOffset: 'UtcOffset',
    jobs: 'Jobs',
    tags: 'Tags',
    tagAssociate: 'Tag associada',
    company_tags: 'Tags Empresa',
    blog: 'Blog',
    faq: 'Faq',
    pages: 'Páginas',
    blogCategories: 'Blog Categorias',
    yes: 'Sim',
    no: 'Não',
    contactSupport: 'Entre em contato com o suporte',
    exports: 'Exportar',
    changeImage: 'Alterar imagem',
    companies: 'Empresas',
    notifications: 'Notificações',
    dashboard: 'Dashboard',
    configs: 'Configurações',
    billing: 'Faturamento',
    registers: 'Cadastros',
    newEvent: 'Novo evento',
    patients: 'Pacientes',
    hospitals: 'Hospitais',
    contracts: 'Contratos',
    medicalSpecialties: 'Especialidades médicas',
    insurances: 'Planos de saúde',
    anesthetists: 'Anestesistas',
    surgeons: 'Cirurgiões',
    services: 'Serviços',
    goBack: 'Voltar',
    test: 'Teste',
    switchDarkTheme: 'Tema escuro',
    switchLanguage: 'Idioma',
    logout: 'Sair',
    changeCompany: 'Alterar Empresa',
    test3: 'Página de teste3',
    test2: 'Página de teste2',
    versionNav: 'Volan',
    events: 'Eventos',
    noCompanySelected: 'Nenhuma empresa selecionada',
    clearSelectedCompany: 'Limpar empresa selecionada',
    adminUsers: 'Usuários adm',
    inbox: 'Contatos',
    quiz: 'Quiz',
    ok: 'Ok',
    bigData: 'Big Data',
    about: 'Sobre',
    faqs: 'Faq',
    faqsCategories: 'Categorias de Faq',
    changes: 'Mudanças',
    guidesOcr: 'Guias OCR *',
    historyTitle: 'Histórico',
    historyShow: 'Mostrar histórico',
    historyShowSuperAdmin: 'Mostrar histórico(SuperAdmin)',
    isSuperAdminAccess: '* Apenas para Super Admin',
    oneSelected: '1 item selecionado',
    multiSelected: 'itens selecionados',
    invoices: 'Notas',
    batches: 'Lotes',
    onlySuperAdmin: 'SuperAdmin',
    onlySuperAdminTeste: '(Em Teste)',
    newTicket: 'Novo Ticket',
    regionUtcOffset: 'UtcOffset',
    forms: 'Formulários',
    FormsResponses: 'Respostas dos Formulários',
    formsCategories: 'Tipos de Formulários',
    importSurgeons: 'Importar cirurgiões',
    exportSurgeons: 'Exportar cirurgiões',
    confirmImportSurgeons: 'Verifique as informações e conclua no final da tela',
    importAnesthetists: 'Importar anestesistas',
    exportAnesthetists: 'Exportar anestesistas',
    confirmImportAnesthetists: 'Verifique as informações e conclua no final da tela',
    birthDate: 'Nascimento',
    functionsEnabled: 'Funcionalidades habilitadas',
    notValuedEvents: 'Eventos não faturáveis (sem valor)',
    customEventStatus: 'Status de evento customizado (Depois de aguardando conferência)',
    customEventStatusName: 'Nome do status customizado',
    customEventStatusAction: 'Ação do status customizado (Ex. Status \'Em auditoria\' ação \'Auditado\')',
    periodOfPayments: 'Períodos de pagamento',
    partialLostStatus: 'Status parcialmente perdido',
    partialCutStatus: 'Status parcialmente glosado',
    contractInstallment: 'Parcelas do contrato',
    valuePerHour: 'Valor hora',
    valuePerHourLabel: '* Ao ativar o valor hora, os campos de data início e data fim do evento serão obrigatórios para todos os convênios. O campo valor hora aparecerá no Dashboard, nos eventos e no campo Produzido da Produção. Ao habilitar essa opção, ela começa a valer para os eventos lançados no próximo dia. No Dashboard, começa a valer a partir do próximo mês.',
    spreadsheetConfig: 'Campos da tabela',
    noSuportLocalStorage: 'Navegador obsoleto ou com os cookies desabilitados, atualize o navegador e ou habilite os cookies',
    billingContract: 'Contratos',
    periodOfPayment: 'Períodos de pagamento',
    configOnCallSchedule: 'Configuração de Plantão',
  },
  form: {
    canSpeakWithOwner: 'Pode falar com o dono',
    isAmbassador: 'Embaixador',
    channelName: 'Nome do canal',
    patientEdit: 'Edição do usuário',
    patientRegister: 'Cadastro de paciente',
    rating: 'Avaliação',
    typeTransaction: 'Tipo de transação',
    dtAppointment: 'Data do atendimento',
    typesTransactions: {
      POSITIVE: 'Positivo',
      NEGATIVE: 'Negativo',
    },
    ratings: 'Avaliações',
    text: 'Mensagem',
    read: 'Lido',
    subject: 'Assunto',
    channels: 'Canais',
    isOwner: 'Canal com o dono',
    searchAppealsField: 'Buscar pelo número da guia ou nome do paciente',
    searchAppeals: 'Filtrar itens do recurso',
    batchCode: 'Código do lote',
    isFollowUp: 'É retorno',
    shouldBeFollowUp: 'Deveria ser retorno',
    notFollowUpNeitherShouldBe: 'Não é retorno e nem deveria ser',
    mode: 'Modo',
    templates: 'Templates',
    isTemplate: 'É template? (não é necessário publicar o formulário)',
    published: 'Publicado',
    attachmentsName: 'Anexos',
    invoiceNumber: 'Nº nota',
    emergency: 'Emergência',
    elective: 'Eletiva',
    _totalOpened: 'Valor em aberto',
    resolvedReports: 'Relatório de baixas',
    cobrancas: 'Cobranças',
    filterBy: 'Filtrar por',
    showCobrancasResolved: 'Visualizar baixados por cobrança',
    cannotRemoveProcedureAfterSentAppeal: 'Não pode ser removido depois que o recurso foi enviado',
    canRemoveProcedure: 'Remover procedimento',
    markedToAppeal: 'Marcado para recursar',
    qtdProcedures: 'Qtd. procedimentos',
    addProcedures: 'Adicionar procedimentos',
    reasonAppealed: 'Justificativa',
    numberGuide: 'Nº Guia',
    numberWallet: 'Carteirinha',
    startBilling: 'Início Fat.',
    endBilling: 'Fim Fat.',
    procedure: 'Procedimento',
    dtAppealed: 'Data do recurso',
    cutReason: 'Motivo da glosa',
    totalValue: 'Valor',
    totalAppealed: 'Valor do recurso',
    totalReceivedAppeal: 'Valor recebido do recurso',
    totalAccepted: 'Valor do acatado',
    reasonProvider: 'Justificativa prestador',
    appeal: 'Recurso',
    xml: 'XML',
    successDownload: 'Download gerado com sucesso!',
    errorDownload: 'Erro ao gerar download!',
    addEvent: 'Adicionar evento',
    numberGuideAppealByOperator: 'Número da guia de recurso de glosas atribuído pela operadora',
    objectAppealName: 'Objecto de recurso',
    objectAppeal: {
      guide: 'Recurso de guia',
      protocol: 'Recurso de protocolo',
    },
    guide: 'Guia',
    reasonGuideAppeal: 'Justificativa (no caso de recurso integral da guia)',
    cutReasonGuide: 'Código da glosa da guia',
    numberGuideOperator: 'Número da guia no operador',
    numberGuideProvider: 'Número da guia no prestador',
    guideDataAppeal: 'Dados do recurso da guia',
    reasonProtocolAppeal: 'Justificativa (no caso de recurso integral do protocolo)',
    cutReasonProtocol: 'Código da glosa do protocolo',
    numberProtocol: 'Número do protocolo',
    protocolDataAppeal: 'Dados do recurso do protocolo',
    batchData: 'Dados do lote',
    batchResource: 'Lote do recurso',
    willNotUseInAppeal: 'Este nome não será utilizado no recurso, serve apenas para identificar o recurso',
    createTypesOfPayment: 'É necessário criar os tipos de pagamento',
    createPeriodOfPayment: 'É necessário criar os períodos de pagamento',
    installmentsAmount: 'Quantidade de parcelas',
    periodOfPayment: 'Período dos pagamentos',
    typeOfPayment: 'Forma de pagamento',
    fieldName: 'Nome do campo',
    save: 'Salvar',
    username: 'Username',
    typeNumberWallet: 'Digitar número da carteirinha',
    addressName: 'Endereço',
    AXREG: 'AxReg',
    SPREADSHEET: 'Planilha',
    axreg: 'AxReg',
    spreadsheet: 'Planilha',
    anesthesiaMinutesHint: 'Se não digitar nada, ficará disponível para preencher na planilha. Se digitar um valor menor do que 1, também ficará disponível para preencher na planilha.',
    anesthesiaMinutes: 'Tempo da anestesia, em minutos',
    selectAnesthesiaStartAndEnd: 'Hora de início e fim da anestesia',
    showMinutesInterval: 'Selecionar tempo de intervalo entre os eventos',
    minutesInterval: 'Minutos de intervalo entre os eventos',
    minutesIntervalHint: 'Intervalo de tempo entre os eventos',
    showOnlyPayers: 'Mostrar apenas empresas pagantes',
    no: 'Não',
    nickName: 'Apelido',
    isPayer: 'Empresa pagante',
    typeObservation: 'Digitar a observação',
    selectSecondaryAnesthetist: 'Selecionar segundo anestesista',
    selectResident: 'Selecionar residente',
    selectAccommodation: 'Selecionar acomodação',
    showOptionalItems: 'Mostrar itens opcionais para criar evento.',
    showMandatoryItems: 'Mostrar itens obrigatórios para criar evento.',
    companies: 'Empresas',
    patientNoCPFInImportation: 'Paciente sem cpf ou cpf inválido, no momento da importação',
    integrationConfig: {
      showOnlyDone: 'Mostrar somente eventos realizados',
      importOnlyDone: 'Importar do AxReg somente eventos realizados',
      showActiveOnly: 'Mostrar somente integrações ativas',
    },
    companyData: 'Dados da emrpesa: ',
    healthInsuranceData: 'Dados do convênio: ',
    hospitalData: 'Dados dos hospitais: ',
    surgeonData: 'Dados dos cirurgiões: ',
    anesthetistData: 'Dados dos anestesistas: ',
    eventData: 'Dados dos eventos: ',
    additionalData: 'Dados adicionais: ',
    mandatoryFieldsInXML: 'Campos obrigatórios para enviar XML: ',
    mandatoryFieldsInNewEvent: 'Campos obrigatórios para criar um evento: ',
    scheduleConfig: {
      workplaceValue: 'Valor padrão de local de trabalho na escala',
      onCallScheduleValue: 'Valor padrão do plantão na escala',
      minimumTradeTimeInHours: 'Tempo mínimo para solicitar troca de plantão',
      minimumTradeTimeInHoursHint: 'Tempo em horas e minutos. Ex: 14:30 antes do plantão é o tempo mínimo para solicitar a troca.'
    },
    initialsLengthError: 'A sigla deve ter 2 ou 3 caracteres',
    addInstitutionKey: 'Adicionar chaves de instituição',
    addObservation: 'Adicionar observação',
    observation: 'Observação',
    observations: 'Observações',
    integratorKey: 'Chave de integração',
    institutionKey: 'Chave de instituição',
    nameInstitution: 'Nome da instituição',
    companyName: 'Nome da empresa',
    institutionKeys: 'Chaves de instituições',
    mainKey: 'Chave principal',
    answerRequired: 'Resposta obrigatória',
    yes: 'Sim',
    not: 'Não',
    idProc: 'Procedimento',
    porte: 'Porte',
    portePercent: 'Porcentagem do porte',
    portePercentHint: '0 representa 0%, 0.5 representa 50% e 1 representa 100%.',
    porteAnestesico: 'Porte anestésico',
    sendMail: 'Enviar email',
    cell: 'Celular',
    state: 'UF',
    concludeAndSendEmail: 'Concluir e enviar email',
    notFound: 'Não encontrado',
    quantity: 'Quantidade',
    answers: 'Respostas',
    allAnswers: 'Todas respostas',
    isAdmin: 'É administrador',
    all: 'Todos',
    today: 'Hoje',
    lastWeek: 'Últimos 7 dias',
    lastMonth: 'Últimos 30 dias',
    custom: 'Customizado',
    selectForm: 'Selecionar formulário',
    form: 'Formulário',
    restrictFilial: 'Restringir Filial',
    createdBy: 'Criado por',
    taxe: 'Taxa',
    taxeLabel: 'valor da taxa',
    selectCBHPM: 'Selecione a CBHPM',
    attachment: 'Anexo',
    attachments: {
      logoUrl: 'Logo',
      attachment: 'Anexo',
    },
    mandatoryKeys: 'Chave primária obrigatória: ',
    patient: 'Paciente',
    patientCPF: 'CPF do paciente',
    cbo: 'Código CBO',
    companyAccess: 'Acesso da Empresa',
    companyInfo: 'Informações da Empresa',
    companyContract: 'Contrato da Empresa',
    adminAccess: 'Acesso do Administrador',
    user: 'Nome de usuário',
    emailAdmin: 'Email administrador',
    userAdmin: 'Nome administrador',
    dateLimitStartUse: 'Limite para começar a usar',
    contractSignedAt: 'Contrato assinado em',
    cnes: 'Código CNES',
    tradeName: 'Razão social',
    selectInvoices: 'Selecione as notas',
    addInvoice: 'Adicionar nota',
    dateProcessing: 'Data de processamento',
    dateInvoice: 'Data da nota',
    dateBatch: 'Data do lote',
    dateSent: 'Data de envio do lote',
    favored: 'Favorecido',
    note: 'Observação',
    hospitals: 'Hospitais',
    hospital: 'Hospital',
    momName: 'Nome da mãe',
    hasCPF: 'Tem CPF?',
    healthInsurances: 'Convênios',
    healthInsurance: 'Convênio',
    anesthetists: 'Anestesistas',
    anesthetist: 'Anestesista',
    surgeons: 'Cirurgiões',
    type: 'Tipo',
    crmState: 'UF CRM',
    speciality: 'Especialidade',
    idCrmState: 'Id Estado do CRM',
    axregId: 'Axreg Id',
    specialty: 'Especialidade',
    insuranceWallet: 'Carteira do convênio',
    gender: {
      male: 'Masculino',
      female: 'Feminino',
      other: 'Outro',
      m: 'Masculino',
      f: 'Feminino',
      o: 'Outro',
    },
    tellTheSex: 'Informe o sexo',
    birthDate: 'Data de nascimento',
    code: 'Código',
    value: 'Valor',
    error: {
      invalidAmountHours: 'Quantidade de horas e minutos inválida',
      requireFields: 'Há campos obrigatórios ainda não preenchidos',
      warning: 'Atenção',
      startAndEndTitle: 'Hora de início e fim',
      startCantBeBefore: 'Hora de início não pode ser maior ou igual a hora de fim!',
      startAndEnd: 'Escolha o horário de início e fim do procedimento',
      invalidCPF: 'CPF  inválido',
      justNumbers: 'Digite apenas números',
      required: 'Esse campo é requerido',
      needPositive: 'Esse número precisa ser positivo',
      minLengthRequired: 'O tamanho mínimo é: ',
      maxLengthRequired: 'O tamanho máximo é: ',
      minLength: 'O campo é muito curto',
      maxLength: 'O campo é muito longo',
      update: 'Houve algum erro',
      email: 'Email inválido',
      image: {
        type: 'Tipo de arquivo inválido, esperado: PNG, JPEG',
        upload: 'Erro ao fazer ulpload da imagem, tente novamente mais tarde!'
      },
      invalidValue: 'Entrada inválida',
      formInvalid: 'Campo inválido',
      uploadImage: 'Erro ao fazer upload de imagem',
      closeEditor: 'Conclua ou cancele o editor antes de proseguir',
      showOnMenu: 'Mostrar no menu',
      regionAreaName: 'Erro ao obter nome da área da região',
      schedule: 'Erro a reduzir número de escalas',
      scheduleMax: 'Número máximo de escalas atingido',
      tagType: 'A tag deve ser aplicável a produtos e ou empresas',
      companyNeedApproved: 'É preciso aprovar a empresa para habilitar',
      needOnePayment: 'É necessário selecionar pelo menos uma forma de pagamento aceita(Pelo App ou na Entrega)',
      NeedRegion: 'É necessário definir uma região',
      changeOnlyAdmin: 'Entre em contato com o suporte para efetuar essa ação',
      passwordRequired: 'A senha é necessária',
      emailRequired: 'O email é necessário',
      createUser: 'Erro ao criar autenticação do usuário',
      changePassword: 'Erro ao alterar senha do usuário',
      changeEmail: 'Erro ao alterar email do usuário',
      cpfInvalid: 'CPF inválido',
      cpfExist: 'O CPF já existe',
      cpfError: 'Erro ao validar o cpf',
      copiedToClipboard: 'Falha ao copiar para o Clip Board',
      passwordConfirmError: 'As senhas são diferentes',
      passwordTryChange: 'Erro ao tentar alterar a senha',
      uploadError: 'Erro ao fazer upload',
      uploadErrorContinueAsk: 'Deseja salvar o evento sem fazer o upload?',
      isMatrizLocked: 'Só é possível alterar essa flag na criação',
      accessCompanyId: 'Acesso negado',
      emptyAddress: 'Nenhum endereço encontrado',
      listAddressError: 'Erro ao buscar endereço',
      onlyOnNew: 'Editável apenas na criação',
      needAddEvent: 'É necessário adicionar pelo menos 1 evento',
      companyNeed: 'Selecione uma empresa'
    },
    update: 'Atualizar',
    corporateName: 'Razão social',
    updateSuccess: 'Dados salvos',
    ownerName: 'Nome do proprietário',
    email: 'Email',
    password: 'Senha',
    currentPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    passwordConfirmation: 'Confirmação da nova senha',
    continue: 'Continuar',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    name: 'Nome',
    specialtyName: 'Nome da especialidade',
    prevStep: 'Etapa Anterior',
    nextStep: 'Próxima etapa',
    conclude: 'Concluir',
    close: 'Fechar',
    cancel: 'Cancelar',
    changePrimaryColor: 'Alterar cor primária',
    changeSecondaryColor: 'Alterar cor secundária',
    emailResetSuccess: 'Em instantes você receberá um email para redefinir a sua senha',
    emailResetError: 'Houve um erro ao tentar enviar email de redefinição de senha',
    changePassword: 'Alterar Senha',
    passwordNew: 'Nova Senha',
    passwordOld: 'Senha atual',
    address: {
      label: 'Endereço',
      city: 'Cidade',
      complement: 'Complemento',
      country: 'Pais',
      district: 'Bairro',
      number: 'Número',
      search: 'Buscar Endereço',
      state: 'Estado',
      street: 'Rua',
      zipcode: 'CEP',
      fieldWarning: 'Erro ao buscar endereço',
      emptyResult: 'Nenhum endereço encontrado',
      codigoIBGEMunicipio: 'Código IBGE município',
    },
    contact: 'Contato',
    description: 'Descrição',
    phone: 'Telefone',
    contactPhone: 'Telefone para contato',
    delete: 'Deletar',
    confirmDelete: 'Tem certeza que deseja deletar?',
    edit: 'Editar',
    add: 'Adicionar',
    permission: 'Permissão',
    active: 'Ativo',
    inactive: 'Inativo',
    isActive: 'Ativo?',
    create: 'Criar',
    isMandatory: 'Obrigatório',
    print: 'Imprimir',
    showIds: 'Mostrar Ids',
    visible: 'Visísvel',
    required: 'Obrigatório',
    getDeliveryAreaName: 'Nome da área de entrega',
    getRegionName: 'Nome da área da região',
    uniqueName: 'Identificador único',
    slug: 'Slug(url)',
    title: 'Título',
    subTitle: 'Legenda',
    bodyText: 'Texto',
    abstract: 'Resumo',
    response: 'Resposta',
    category: 'Categoria',
    isAdvanced: 'Edição avançada',
    alert: {
      emailAddSuccess: 'Email adicionado com sucesso',
      hasChangesPending: 'Há alterações pendentes, Tem certeza que deseja fechar?',
      importSuccess: 'Importado com sucesso',
      informationImportScratched: 'Registros riscados já existem no sistema',
      informationRedError: 'Registros em vermelho contém erro e não serão importados',
      confirmNoRevert: 'Tem certeza que deseja concluir essa ação?',
      isToUpDateInformations: 'Deseja atualizar as informações já existentes?',
      isToUpDateInformationsByProcId: 'Deseja atualizar as informações já existentes com base no id do procedimento?',
      notUpdate: 'Não atualizar',
    },
    region: 'Região',
    isTest: 'Mostrar em teste',
    isNew: 'novo',
    regionAll: 'Todas as Regiões',
    selectUser: 'Selecione o usuário',
    selectUsers: 'Selecione os usuários',
    selectCompany: 'Selecione a empresa',
    selectCompanies: 'Selecione as empresas',
    link: 'Link',
    search: 'Buscar',
    color: 'Cor',
    createdAt: 'Criado em',
    selectRules: 'Selecione os acessos',
    promptQuestion: '',
    confirmQuestion: 'Você tem certeza?',
    confirmQuestionYes: 'Sim',
    confirmQuestionCancel: 'Cancelar',
    choseNewPassword: 'Escolha uma nova senha',
    fixUserAuth: 'Corrigir Auth do usuário',
    rules: 'Acessos',
    crm: 'CRM',
    isAnesthetist: 'É anestesista',
    copiedToClipboard: 'Url copiada para o clipboard',
    accessCompany: 'Acessar empresa',
    isMatriz: 'É Matriz? (Utilizado para cooperativa, não pode ser alterado depois de criada a empresa)',
    isFilial: 'É filial?',
    matrizName: 'Empresa matriz',
    next: 'Próximo',
    linkUser: 'Vincular o usuário {user} na empresa {company}',
    removeFromCompany: 'Remover da empresa',
    removeFromCompanyConfirm: 'Tem certeza que deseja desvincular o usuário da empresa?',
    selectAccess: 'Selecione os acessos do usuário nessa empresa',
    addProcedure: 'Adicionar procedimento',
    addProcedureCustom: 'Adicionar procedimento customizado',
    utcOffset: 'UtcOffset',
    numberBatch: 'Número do lote',
    numberInvoice: 'Número da nota',
    generateHonorarioXml: 'Gerar XML de guia de Honorário',
    generateSADTXml: 'Gerar XML de guia de SP/SADT',
    generateConsultationXml: 'Gerar XML de guia de Consulta',
    sequencialTransacao: 'Sequencial Transação',
    actions: 'Ações',
    status: 'Estado',
    errorTitle: 'Error',
    updatedAt: 'Atualizado em',
    id: 'Id',
    selectFile: 'Selecione o arquivo',
    done: 'Ok',
    limitReached: 'Limite alcançado',
    date: 'Data',
    genderTitle: 'Sexo',
    countEvents: 'Quantidade de eventos',
    healthInsuranceName: 'Nome do convênio',
    isClosed: 'Lote fechado?',
    isSend: 'Lote aceito?',
    isVolanUser: 'É usuário do Volan?',
    isVolanUserInfo: 'Usuários Volan não aparecem no Core da Escala',
    batch: 'Lote',
    eventType: 'Tipo de Evento'
  },
  image: {
    crop: 'Crop',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateLeft: 'Girar Esquerda',
    rotateRight: 'Girar Direita',
    mirrorY: 'Espelhar Y',
    mirrorX: 'Espelhar X',
    show: 'Mostrar Imagem',
    remove: 'Deletar Imagem',
    selectNewFile: 'Selecionar um novo arquivo',
    backgroundColor: 'Cor do background'
  },
  calendar: {
    week: {
      '0': 'Domingo',
      '1': 'Segunda',
      '2': 'Terça',
      '3': 'Quarta',
      '4': 'Quinta',
      '5': 'Sexta',
      '6': 'Sábado'
    }
  },
  access: {
    linkCompany: 'Associar Empresa',
    generateTranslate: 'Gerar tradução',
    title: 'Acesso',
    admin: 'Administrador',
    user: 'Usuário',
    idForProfile: 'Escolha o id para o perfil',
    idForModule: 'Escolha o id para o módulo',
    idForAction: 'Escolha o id para a ação',
    errors: {
      idForProfileExist: 'O id já existe',
      denied: 'Acesso Negado'
    },
    generateRulesObject: 'Gerar objeto para as rules',
    addModule: 'Adicionar módulo',
    linkModule: 'Associar módulo',
    toggleAllButton: 'Alterar todas as ações',
    modules: 'Módulos',
    actions: 'Ações',
    toggleModelButton: 'Todas',
    menuAccess: 'Acessos',
    menuAccessProfiles: 'Perfis',
    menuAccessModules: 'Módulos',
    menuAccessActions: 'Ações',
    editor: 'editor',
    masterEditor: 'Editor Mestre',
    module: 'Módulo',
    tableProfilesModules: 'Módulos\\Perfis',
    dialogShowAccessTitle: 'Acessos',
    matrizOnly: 'Apenas para cooperativa',
    matriz: 'Cooperativa',
    writeToConfig: 'Migrar para config',
    userAccess: 'Acessos do usuário',
    sendEmailReset: 'Enviar email de redefinição de senha',
    setNewPassword: 'Definir uma nova senha',
    alerts: {
      haveSureSetNewPassword: 'Tem certesa que deseja definir uma nova senha para o usuário?',
    }
  },
  tools: {
    title: 'Ferramenta'
  },
  rules: {
    adm: {
      adm: 'Administrador',
      sell: 'Vendedor',
      analy: 'Analytics',
      sup: 'Suporte'
    },
    comp: {
      adm: 'Administrador',
      edit: 'Editor',
      mEdit: 'Master Editor',
      deli: 'Delivery',
      att: 'Atendente'
    }
  },
  router: {
    accessDenied: 'Acesso Negado',
    needCompany: 'É necessário selecionar uma empresa',
    modules: {
      anesthetists: 'Anestesistas',
      batches: 'Lotes',
      billing: 'Faturamento',
      companies: 'Empresa',
      dashboard: 'Dashboard',
      events: 'Eventos',
      eventsExports: 'Exportação de eventos',
      forms: 'Fomulários',
      healthInsurances: 'Convênios',
      hospitals: 'Hospitais',
      integrations: 'Integrações',
      invoices: 'Notas',
      ocr: 'Ocr',
      patients: 'Pacientes',
      schedule: 'Escala',
      surgeons: 'Cirurgiões',
      workloads: 'Horários de trabalho',
      slotCategories: 'Turnos',
      demands: 'Demandas',
      scheduleOff: 'Exceções de trabalho',
      scheduleOnCall: 'Escalas de sobreaviso',
      core: 'CORE',
      scheduleCharge: 'Cobranças',
    },
    actions: {
      list: 'Listar',
      listOwn: 'Listar Próprios',
      manageFilias: 'Gerenciar filiais',
      manager: 'Gestor',
      read: 'Ler',
      readOwn: 'Ler Próprios',
      rm: 'Remover',
      scheduler: 'Escalador',
      write: 'Gravar',
      writeOwn: 'Gravar Próprios',
    },
    profiles: {
      a: 'Administrador',
      b: 'Anestesista',
      c: 'Gestor de Escala',
      d: 'Escalador',
      e: 'Gestor Faturamento',
      f: 'Faturista',
      g: 'Residente'
    }
  },
  emailReset: {
    subject: 'Resetar senha',
    head: 'Recebemos uma solicitação para redefinir sua senha do Volan,\n acesse o link abaixo para definir uma nova senha',
    body: '',
    link: 'Resetar senha',
    hello: 'Olá'
  },
  emailAlerts: {
    subjectLabels: {
      demo_request: 'REQUISIÇÃO DE DEMONSTRAÇÃO',
      form_contact: 'Formulário de Contato',
      marketing_conversion: 'Conversão Google Ads',
    }
  },
  blog: {
    title: '',
    notFound: 'Post Não Encontrado'
  },
  page: {
    title: '',
    notFound: 'Página Não Encontrada'
  },
  faq: {
    title: 'Perguntas frequentes',
    notFound: 'Faq Não Encontrado'
  },
  inputError: {
    name: 'Preencha o nome',
    companyName: 'Preencha o nome da empresa',
    cnpj: 'Preencha o cnpj',
    priceRange: 'Preencha o intervalo de valor',
    businessType: 'Preencha o tipo de negócio',
    completeAddress: 'Termine de preencher o endereço',
    completeBank: 'Termine de preencher as informações bancárias',
    phone: 'Preencha o telefone',
    previousSend: 'Enviado anteriormente',
    needCanReceive: 'É necessário ter a conta de pagamento aprovada'
  },
  paginate: {
    prev: 'Prev',
    next: 'Next'
  },
  _404: {
    notFoundHeader: 'Página não encontrada, tente recarregar a página.',
    timeout: 'A página atingiu o tempo limite durante o carregamento. Tem certeza de que ainda está conectado à Internet?'
  },
  jobs: {
    type: 'Tipo',
    name: 'Nome',
    arg: 'argumentos'
  },
  apiErrorCode: {
    otherUser: 'O usuário não tem permissão para alterar a senha de outro.',
    statusError: 'Estado inválido',
    permissionDenied: 'O usuário não tem permissão para executar essa ação',
    unknown: 'Erro desconhecido',
    paymentError: 'Erro ao efetuar o pagamento',
    inputError: 'Erro nos parâmetros',
    exhaustedAttempts: 'Muitas tentativas',
    paymentInvalidValue: 'Valor de pagamento inválido',
    notFound: 'Não encontrado',
    OK: 'Sucesso',
    warningAnswered: 'Já respondido',
    userExists: 'Usuário já existe'
  },
  FcmComponents: {
    name: 'Nome',
    search: 'Buscar',
    save: 'Salvar',
    conclude: 'Concluir',
    selected: 'Selecionado',
    selecteds: 'Selecionados',
    confirmButton: 'Confirmar',
    warn: {
      noSave: 'Você fechou o form sem salvar os dados',
      invalidType: 'Tipo inválido'
    },
    dateMonth: 'Mês',
    dateYear: 'Ano',
    setTransparent: 'Definir transparente',
    cantOpenFile: 'Não foi possível abrir o arquivo',
    noDataText: 'Não há dados disponíveis',
    errors: {
      fileTypeNotAllowed: 'Typo de Arquivo não permitido',
      fileNotWillBeUpload: 'Não vai ser feito o upload do arquivo por causa de um erro com o arquivo',
      needFolder: 'É necessário definir um folder'
    },
    confirmDelete: 'Você tem certeza que deseja remover esse arquivo?',
    needUploadToServerToSeeInTab: 'É necessário concluir antes de abrir em nova aba'
  },
  optionsSort: {
    dtProcedure: 'Data do evento',
    numberGuide: 'Número da guia',
    patient: 'Paciente',
    anesthetist: 'Anestesista',
    hospital: 'Hospital',
    produced: 'Valor produzido',
    addedToBatch: 'Data de adição ao lote',
    asc: 'Crescente',
    desc: 'Decrescente',
  },
  statesSigla: {
    '--': 'Estrangeiro',
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins'
  },
  history: {
    item: {
      path: 'Chave',
      old: 'Valor anterior',
      value: 'Novo valor'
    },
    add: 'Adicionado',
    deleted: 'Deletado',
    modify: 'Modificado',
    tipExpandAll: 'alt + click: para expandir todos os itens',
  },
  company: {
    controlFlags: 'Flags de controle da empresa: ',
    type: 'Tipo de cadastro',
    agendaDisplay: 'Agrupamento da agenda',
    types: {
      personal: 'Pessoa física',
      company: 'Pessoa jurídica'
    },
    flags: {
    },
    configs: {
    },
    attachmentName: 'Anexo',
    attachments: 'Anexos customizados do anestesista',
    anesthetistCustomField: 'Campos customizados do anestesista',
  },
  acceptTerms: {
    acceptCookies: 'Utilizamos cookies para garantir que você tenha a melhor experiência em nosso site. Clique em OK para aceitar.',
    OK: 'OK'
  },
  menu: {
    changeSuperAdminToCompany: 'Ir para empresa',
    changeSuperAdminToAdmin: 'Ir para Admin'
  },
  auth: {
    selectProfiles: 'Selecione os perfis',
    selectProfilesMatriz: 'Selecione os perfis na Matriz'
  },
  FormInputType: {
    text: 'Texto',
    paragraph: 'Parágrafo',
    number: 'Número',
    radio: 'Escolha individual',
    checkbox: 'Escolha multipla',
    select: 'Seleção',
    date: 'Data',
    time: 'Hora',
    dateTime: 'Data e hora',
  },
  formInput: {
    limitToResponseActive: 'Tem limite para responder?',
    limitToResponseString: 'Limite para responder(horas)',
    type: 'Tipo',
    other: 'Mostrar "Outra..."',
    otherText: 'Outra...',
    otherInputLabel: '(Outra)',
    addOption: 'Add Opção',
    addSection: 'Add Seção',
    confirmRemoveSection: 'Tem certeza que deseja remover essa seção e todos os itens dessa seção?',
    confirmRemoveInput: 'Tem certeza que deseja remover essa questão',
    mandatory: 'Obrigatório',
    sessionName: 'Nome da seção',
    inputName: 'Nome da opção',
    inputOption: 'Opção',
    formNotFound: 'Formulário não encontrado',
    submitResponse: 'Enviar resposta',
    responseSend: 'Resposta enviada',
    responseOther: 'Outra',
    responseOtherTextField: 'Descreva',
    cancel: 'Cancelar',
    link: 'Vínculo',
    canEditResponse: 'Pode editar resposta',
    errorValidate: 'Há campos obrigatórios não preenchidos',
    errorOnWriteCall: 'Erro ao tentar gravar no banco',
    errorOnSave: 'Erro ao tentar salvar dados',
    published: 'Publicado',
    notPublished: 'Não publicado',
    restrictHospital: 'Restringir hospital',
    allowedHospitals: 'Hospitais permitidos',
    isMandatory: 'É obrigatório?',
    releasedAt: 'Publicado em:',
    isEvent: 'Associado a evento?',
    isClosed: 'Fechado?',
    warnings: {
      attentionEditCustomField: 'Editar esse campo irá impactar em todos os convênios e eventos utilizando esse campo'
    }
  },
  FormLinkType: {
    none: 'Nenhum',
    eventId: 'Id do evento',
    eventAnesthetistName: 'Nome do anestesista',
    eventPatientName: 'Nome do paciente',
    eventDate: 'Data da anestesia',
    userId: 'Id do usuário',
    userName: 'Nome do usuário',
    userEmail: 'Email do usuário',
    companyId: 'Id da empresa',
    companyName: 'Nome da empresa',
    eventSurgeonName: 'Nome do Cirurgião',
    eventHospitalName: 'Nome do hospital',
  },
  formInputEjs: {
    title: '',
    notFound: 'Post Não Encontrado'
  },
  shortCuts: {
    showShortCuts: 'Mostrar atalhos',
    sistem: 'Sistema',
    history: 'Tela de histórico',
    createEvent: 'Criar novo evento',
    title: 'Atalhos',
    nextHistory: 'Próxima modificação',
    prevHistory: 'Modificação anterior',
    conclude: 'Salvar'
  },
  sequential: {
    title: 'Números sequenciais',
    btn: 'Configurar sequenciais',
    batch: 'Lote',
    eventNumberGuide: 'Guia',
    protocol: 'Protocolo',
  },
  post: {
    name: 'Título',
    description: 'Descrição',
    published: 'Publicado',
    relatedPosts: 'Publicações relacionadas',
    date: 'Data do post',
    dateChange: 'Data da última modificação do post',
    publishers: 'Autores',
    categories: 'Categorias',
    slug: 'Slug (evite a todo custo alterar o slug depois de publicar o post)',
    sureSlugChange: 'Tem certeza que deseja alterar o slug? isso pode impactar o SEO',
    errors: {
      slugExist: 'Essa slug já existe',
      slugEmpty: 'É necessário uma slug',
    }

  }
};



















