<script lang="ts">
import {uniqueId} from 'lodash-es';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseVolanInput',
  inject: {
    volanForm: { default: null }
  },
  inheritAttrs: false,
  props: {
    // eslint-disable-next-line vue/require-default-prop
    modelValue: null,
    id: {
      type: String,
      default: '',
      require: false
    },
    disabled: {
      type: Boolean,
      require: false
    },
    readonly: {
      type: Boolean,
      require: false
    },
    label: {
      type: String,
      default: '',
      require: false
    },
    required: {
      type: Boolean,
      require: false
    },
    hint: {
      type: String,
      default: '',
      require: false
    },
    persistentHint: {
      type: Boolean,
      require: false
    },
    dense: { // Ainda não usado
      type: Boolean,
      require: false
    },
    hideDetails: {
      type: Boolean,
      require: false
    },
  },
  data(vm) {
    return {
      internalId: uniqueId('input_'),
      isFocused: false,
    };
  },
  computed: {
    computedId() {
      return this.id || `input-${this.internalId}`;
    },
    isDisabled() {
      // @ts-ignore
      return this.disabled || this.volanForm?.disabled;
    },
    isReadonly() {
      // @ts-ignore
      return this.readonly || this.volanForm?.readonly;
    },
    computedLabel() {
      return this.label + (this.required?' *':'');
    },
    hintComputed() {
      return (this.isFocused||this.persistentHint)?(this.hint||''):'';
    }
  },
  methods: {
    onInputFocus() {
      this.isFocused = true;
    },
    onInputBlur() {
      this.isFocused = false;
    }
  }
});
</script>
