<script lang="ts">
// @ts-nocheck
import PrimeButton from 'primevue/button';
import BaseVolanInput from './BaseVolanInput.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VolanBtn',
  components: {PrimeButton},
  extends: BaseVolanInput,
  inheritAttrs: true,
  props: {
  },
  data() {
    return {

    };
  },
  computed: {
  },
}) as any;
</script>

<template>
  <PrimeButton v-bind="$attrs" :disabled="isDisabled">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>
  </PrimeButton>
</template>
