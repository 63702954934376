/**
 * Tema para o Vuetify
 *
 * Usage in CSS:
 * Não pode ser utilizado transparência, dizem que irão adicionar transparencia no Vuetify 3
 *
 * .myClass {
 *   color: var(--v-[name]-base, [defaultValue]);
 * }
 *
 * Example:
 * .myClass {
 *   color: var(--v-navBarColor-base, #FF00FF);
 * }
 *
 *
 * Usage in Vuetify:
 *
 *  <VBtn color="navBarColor">
 *    Apenas um botão
 *  </VBtn>
 *
 *  <VBtn color="primary">
 *    Apenas um botão
 *  </VBtn>
 *
 *  <div class="primary--text">
 *  </div>
 *
 *
 * Obs: As cores base do vue podem ser utilizadas também como classes:
 *  <VBtn class="primary">
 *    Apenas um botão
 *  </VBtn>
 *
 * <VBtn class="eventProduced--text">
 *    Altera color e caret-color
 *  </VBtn>
 *
 *  <VBtn class="eventProduced">
 *    Altera background-color e border-color
 *  </VBtn>
 *
 * Obs2: É gerado o arquivo src/design/theme_compiled.css para melhorar o autocomplete da IDE, esse arquivo não deve ser utilizado
 * para nenhuma outra funcionalidade
 *
 */


export const themes = {
  light: {
    dark: false,
    colors: {
      // main colors vue
      primary: '#000000',
      secondary: '#424242', // ainda não definido
      accent: '#82b1ff', // ainda não definido
      anchor: '#1976d2',
      error: '#ff5252',
      info: '#2196f3',
      success: '#4caf50',
      warning: '#fb8c00',
      background: '#F8F8F8',
      backgroundMain: '#f6f6f6',
      empty: '#ff8181',

      bgDefault: '#f3f3f3',
      dangerToFrom: '#EB9A9C',
      activeToFrom: '#7aff9d',



      // NavBar
      navBarBg: '#F0F0F0',
      navBarText: '#000000',
      navBarTextSelected: '#FFFFFF',
      navBarItemSelectedBg: '#000000',
      navBarIcon: '#000000',
      navBarIconSelected: '#FFFFFF',

      // Geral
      label: '#000000',
      labelDisabled: '#bfbfbf',
      text: '#000000',
      textBg: '#FFFFFF',
      textRemove: '#ff0c18',
      buttonRemove: '#ff0c16',
      formHeadBg: '#fbfbfd',
      formSplitter: '#e2e2e3',
      formBodyBg: '#f9f9f9',
      formBorder: '#f1f1f2',
      buttonText: '#eeecec',
      buttonBg: '#000000',
      scroll: '#5a5a5a',
      scrollBg: '#f0f0f0',
      listItemBg: '#F4F4F5',
      listItemSelectedBg: '#dedede',
      listItemText: '#000000',
      listItemBorder: '#666',
      listItemTextSecondary: '#353535',
      modalBg: '#D3D3D3',
      modalHeaderBg: '#e9e7e7',
      eventHeaderBg: '#e9e7e7',

      // gray scale
      gray0: '#8F8F94',
      gray1: '#fafafa',
      gray2: '#eaeaea',
      gray3: '#999',
      gray4: '#888',
      gray5: '#666',
      gray6: '#444',
      gray7: '#333',
      gray8: '#111',
      gray9: '#202020',
      // primary
      primary50: '#e6fae7',
      primary100:  '#c3f1c3',
      primary200: '#9ae89c',
      primary300: '#68e070',
      primary400: '#32d74b',
      primary500: '#00ce19',
      primary600: '#00be08',
      primary700: '#00ab00',
      primary800: '#009900',
      primary900: '#007900',


      swal2Bg: '#e1e1e1',
      swal2Bg2: '#c8c8c8',
      swal2Text: '#19191a',
      swal2Text2: '#323234',
      swal2Border: '#505050',

      eventTextLight: '#f9f9f9',
      eventTextDark: '#121619',
      eventProduced: '#32D74A',
      eventOver: '#BE59F0',
      eventReceived: '#0C84FE',
      eventInProcessing: '#947c06',
      eventLoss: '#FF443A',
      eventDiscount: '#E400FA',
      eventDiff: '#FED609',
      eventGlosado: '#ffc400',
      eventGlosaIdentified: '#FF4500',

      reportsCell: '#FF5252',
      reportsSelectedCell: '#b9b9b9',
      bgReports: '#F9F9F9',
      bgReportsEven: '#D9D9D9',
      labelReportsStatus: '#FFF',

      eventEmpty: '#8E8E93',
      eventEmpty2: '#e1e1e1',
      eventOk: '#31D158',
      eventWarning: '#FED609',
      eventError: '#FF453A',

      eventCSInvert1: '#e1e1e1',
      eventCSInvert2: '#000000',
      eventCSAwaitingCheck: '#5BC7FA',
      eventCSPending: '#f3cd45',
      eventCSWaitSendGuia: '#FF9E0B',
      eventCSWaitPayment: '#0C84FE',
      eventCSWaitSendRecourse: '#FF9E0B',
      eventCSWaitRecourse: '#BE59F0',
      eventCSFinished: '#32D74B',
      eventCSReceived: '#32D74B',
      eventCSPartialLosed: '#FF453A',
      eventCSLosed: '#FF453A',
      eventCSPartialCuted: '#ED930A',
      eventCSCuted: '#ED930A',

      backGroundIcon: '#F0F0F0',
      bgListSearch: '#eaeaea',

      // Escala
      vacation: '#0a84ff',
      available: '#30d158',
      dayBetterOff: '#ffd60a',
      dayOff: '#ff375f',
      unavailable: '#8e8e93',
      bgGraySchedule: '#fafafa',
      bgCore: '#fafafa',
      bgCoreSchedule: '#e2e2e3',
      bgWeekendCore: '#eaeaea',
      headWorkload: '#fafafa',
      headWorkloadText: '#000',
      lineColor: '#E7E7E9',
      coreBorder: '#8F8F94',

      // Contrato
      contractValue: '#5BC7FA',
      contractValueToReceive: '#0C84FE',
      contractValueReceived: '#32D74B',
      contractValueNotReceived: '#FF453A',
      contractValuePrediction: '#FED609',
      contractOpen: '#32cd32',
      contractWaitPayment: '#0C84FE',
      contractClosed: '#FF453A',

      // cobrancas
      all: '#8890d6',
      waitingAnalysis: '#eb5757',
      analysing: '#cc8925',
      appealResponseReceived: '#45a676',
      finished: '#97d484',
    },
    variables: {}
  },
  dark: {
    dark: true,
    colors: {
      // main colors vue
      primary: '#8AE800',
      secondary: '#424242', // ainda não definido
      accent: '#ff4081', // ainda não definido
      anchor: '#2196f3',
      error: '#ff5252',
      info: '#2196f3',
      success: '#4caf50',
      warning: '#fb8c00',
      background: '#1C1C1E',
      backgroundMain: '#121212',
      empty: '#ff8181',

      bgDefault: '#282a2e',

      dangerToFrom: '#EB9A9C',
      activeToFrom: '#7aff9d',
      // NavBar
      navBarBg: '#131313',
      navBarText: '#FFFFFF',
      navBarTextSelected: '#000000',
      navBarItemSelectedBg: '#8AE800',
      navBarIcon: '#545457',
      navBarIconSelected: '#000000',

      // gray scale
      gray0: '#8F8F94',
      gray1: '#fafafa',
      gray2: '#eaeaea',
      gray3: '#999',
      gray4: '#888',
      gray5: '#666',
      gray6: '#444',
      gray7: '#333',
      gray8: '#111',
      gray9: '#202020',
      // primary
      primary50: '#e6fae7',
      primary100:  '#c3f1c3',
      primary200: '#9ae89c',
      primary300: '#68e070',
      primary400: '#32d74b',
      primary500: '#00ce19',
      primary600: '#00be08',
      primary700: '#00ab00',
      primary800: '#009900',
      primary900: '#007900',

      // Geral
      label: '#8F8F94',
      labelDisabled: '#606062',
      text: '#FFFFFF',
      textBg: '#000000',
      textRemove: '#DC2727',
      buttonRemove: '#FF0916',
      formHeadBg: '#131315',
      formSplitter: '#262628',
      formBodyBg: '#1C1C1F',
      buttonText: '#000000',
      buttonBg: '#09FE5E',
      scroll: '#5a5a5a',
      scrollBg: '#121212',
      listItemBg: '#313134',
      listItemSelectedBg: '#54545a',
      listItemText: '#FFFFFF',
      listItemBorder: '#626268',
      listItemTextSecondary: '#9d9da5',
      modalBg: '#1C1C1E',
      modalBg2: '#121619',
      modalHeaderBg: '#2b2b2d',
      eventHeaderBg: '#000000',
      // formBorder: 'rgba(255,255,255,0)',

      swal2Bg: '#19191a',
      swal2Bg2: '#323234',
      swal2Text: '#e1e1e1',
      swal2Text2: '#c8c8c8',
      swal2Border: '#505050',

      eventTextLight: '#f9f9f9',
      eventTextDark: '#121619',
      eventProduced: '#32D74A',
      eventOver: '#BE59F0',
      eventReceived: '#0C84FE',
      eventInProcessing: '#F8D00B',
      eventLoss: '#FF443A',
      eventDiscount: '#E400FA',
      eventDiff: '#FED609',
      eventGlosado: '#ffc400',
      eventGlosaIdentified: '#FF4500',

      reportsCell: '#FF5252',
      reportsSelectedCell: '#464646',
      bgReports: '#161616',
      bgReportsEven: '#262626',
      labelReportsStatus: '#000',

      eventEmpty: '#8E8E93',
      eventEmpty2: '#e1e1e1',
      eventOk: '#31D158',
      eventWarning: '#FED609',
      eventError: '#FF453A',


      eventCSInvert1: '#e1e1e1',
      eventCSInvert2: '#000000',
      eventCSAwaitingCheck: '#5BC7FA',
      eventCSPending: '#ffc400',
      eventCSWaitSendGuia: '#FF9E0B',
      eventCSWaitPayment: '#0C84FE',
      eventCSWaitSendRecourse: '#FF9E0B',
      eventCSWaitRecourse: '#BE59F0',
      eventCSFinished: '#32D74B',
      eventCSReceived: '#32D74B',
      eventCSLosed: '#FF453A',
      eventCSCuted: '#ED930A',

      backGroundIcon: '#09FE5E',
      bgListSearch: '#313134',

      revertStatusObservation: '#ff832b',
      pendingObservation: '#f1c21b',
      messageObservation: '#32be55',
      guideObservation: '#78a9ff',
      removeObservation: '#ff375f',

      // Escala
      vacation: '#0a84ff',
      available: '#30d158',
      dayBetterOff: '#ffd60a',
      dayOff: '#ff375f',
      unavailable: '#8e8e93',
      bgGraySchedule: '#202020',
      bgCore: '#0d0e0e',
      bgCoreSchedule: '#161718',
      coreBorder: '#474d50',
      bgWeekendCore: '#161718',
      headWorkload: '#1e1e1e',
      headWorkloadText: '#fff',
      lineColor: '#3A3A3C',

      // Contrato
      contractValue: '#5BC7FA',
      contractValueToReceive: '#0C84FE',
      contractValueReceived: '#32D74B',
      contractValueNotReceived: '#FF453A',
      contractValuePrediction: '#FED609',
      contractOpen: '#32cd32',
      contractWaitPayment: '#0C84FE',
      contractClosed: '#FF453A',

      // cobrancas
      all: '#a2abff',
      waitingAnalysis: '#eb5757',
      analysing: '#ffcc6d',
      appealResponseReceived: '#4cb782',
      finished: '#97d484',
    },
    variables: {}
  },
};










