import '@/db';
import { createApp } from 'vue';
import { registerPlugins } from '@/plugins';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as _ from 'lodash';
import App from './App.vue';

// @ts-ignore
window.moment = moment;
// @ts-ignore
window._ = _;

const app = createApp(App);

registerPlugins(app);
import './design/global.scss';

app.mount('#app');
