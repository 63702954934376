'use strict';
/*

indices em array
https://www.raymondcamden.com/2012/08/10/Searching-for-array-elements-in-IndexedDB

 */

import * as idb from 'idb';

export class FcmIndexedDB {
  stores:any[]= [];
  name='FcmIndexedBD_alterar';
  version=0;
  constructor(name:string, version:number, stores:any[]) {
    this.name = name;
    this.stores = stores;
    this.version = version;
  }
  async openDB() {
    return idb.openDB(this.name, this.version,
      {
        upgrade: (db:any, oldVersion:number, newVersion:number, transaction:any) =>{
          for(const s of db.objectStoreNames) {
            db.deleteObjectStore(s);
          }
          for(const i in this.stores) {
            const store = db.createObjectStore(this.stores[i].name, {
              keyPath: 'id',
              autoIncrement: false,
            });
            for (const index of this.stores[i].indexes || []) {
              store.createIndex(index, index, { unique: false });
            }
          }
          db.createObjectStore('config', {
            keyPath: 'id',
            autoIncrement: false,
          });
        },
        blocked: () =>{
          console.log('FcmIndexedDB: blocked');
        },
        blocking: () =>{
          console.log('FcmIndexedDB: blocking');
        },
        terminated: () =>{
          console.log('FcmIndexedDB: terminated');
        },
      });
  }

  async getDataById(storeName:string, id:string) {
    const db = await this.openDB();
    const tx = db.transaction(storeName, 'readonly', {durability: 'relaxed'});
    const store = tx.objectStore(storeName);
    const item = await store.get(id);
    db.close();
    return item;
  }

  async getAllData(storeName:string) {
    const db = await this.openDB();
    const tx = db.transaction(storeName, 'readonly', {durability: 'relaxed'});
    const store = tx.objectStore(storeName);
    const allSavedItems = await store.getAll();
    db.close();
    return allSavedItems;
  }
  async getAllDataByCompany(storeName:string, companyId:string) {
    const db = await this.openDB();
    // let tx = db.transaction(storeName, 'readonly');
    if(storeName==='users') {
      const index = db.transaction(storeName, 'readonly', {durability: 'relaxed'}).store.index('_companyId');
      const allSavedItems = await index.getAll(companyId);
      db.close();
      return allSavedItems;
    }
    else if(storeName==='anesthetists') {
      const tx = db.transaction(storeName, 'readonly', {durability: 'relaxed'});
      const store = tx.objectStore(storeName);
      const allSavedItems = await store.getAll();
      db.close();
      return allSavedItems.filter((el:any)=>{return (el.matrizAndCompanies||[]).includes(companyId);});
    }
    else if (storeName==='filiais') {
      const index = db.transaction(storeName, 'readonly', {durability: 'relaxed'}).store.index('matrizId');
      const allSavedItems = await index.getAll(companyId);
      db.close();
      return allSavedItems;
    }
    else {
      const index = db.transaction(storeName, 'readonly', {durability: 'relaxed'}).store.index('companyId');
      const allSavedItems = await index.getAll(companyId);
      db.close();
      return allSavedItems;
    }

  }
  async removeData(storeName:string, data:any) {
    if(!data) {
      return;
    }
    const db = await this.openDB();
    const promises = [];
    if(Array.isArray(data)) {
      for(const d of data) {
        promises.push(db.delete(storeName, d.id));
      }
    }
    else if(data) {
      promises.push(db.delete(storeName, data.id));
    }
    await Promise.all(promises);
    db.close();
  }
  async cleanStore(storeName:string) {
    const db = await this.openDB();
    await db.clear(storeName);
    db.close();
  }
  async putData(storeName:string, data:any) {
    if(!data) {
      return;
    }
    const db = await this.openDB();

    const tx:any = db.transaction(storeName, 'readwrite', {durability: 'relaxed'});
    const store:any = tx.objectStore(storeName);
    const promises = [];
    if(Array.isArray(data)) {
      for(const d of data) {
        promises.push(store.put(d));
      }
    }
    else if(data) {
      promises.push(store.put(data));
    }
    promises.push(store.done);
    promises.push(tx.complete);
    await Promise.all(promises);
    // await tx.complete;
    db.close();
  }
}

/*
// Get a value from a store:
const value = await db.get(storeName, key);
// Set a value in a store:
await db.put(storeName, value, key);

// Get a value from an index:
const value = await db.getFromIndex(storeName, indexName, key);

// Add 'And, happy new year!' to all articles on 2019-01-01:
  {
    const tx = db.transaction('articles', 'readwrite');
    const index = tx.store.index('date');

    for await (const cursor of index.iterate(new Date('2019-01-01'))) {
      const article = { ...cursor.value };
      article.body += ' And, happy new year!';
      cursor.update(article);
    }

    await tx.done;
  }


 */
