/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import {vuetify} from './vuetify';
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire';
import { firebaseApp } from '@/db';
import { createPinia } from 'pinia';
import { initStores } from '@/stores';
import router from '@/router';
import type {Router} from 'vue-router';
import {markRaw} from 'vue';
import PrimeVue from 'primevue/config';

// Types
import type { App } from 'vue';
import {GlobalPlugin} from '@/plugins/global';
import {i18n} from '@/plugins/i18n';
// @ts-ignore
import Tooltip from 'vue-directive-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// @ts-ignore
import VueTheMask from 'vue-the-mask';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}

export function registerPlugins(app: App) {
  app.use(router);
  const pinia = createPinia();
  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });
  app.use(pinia);

  initStores();
  app.use(vuetify);
  app.use(i18n);
  app.use(VueFire, {
    firebaseApp,
    modules: [VueFireFirestoreOptionsAPI({})]
  });
  app.use(PrimeVue, { ripple: true, inputStyle: 'filled'  });
  app.use(Tooltip);
  app.use(VueSweetalert2);
  app.use(GlobalPlugin);
  app.use(VueTheMask);
}
