<script setup lang="ts">
import {ref, watch} from 'vue';
// import {usePrimeVue} from 'primevue/config';
// import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import {useLayout} from '@/layouts/composables/layout';

defineProps({
  simple: {
    type: Boolean,
    default: false
  }
});

// const $primevue = usePrimeVue();
// const inputStyle = computed(() => $primevue.config.inputStyle);

const {setScale, layoutConfig, layoutState} = useLayout();
const scales = ref([12, 13, 14, 15, 16]);

watch(layoutConfig.menuMode, (newVal) => {
  if (newVal === 'static') {
    layoutState.staticMenuDesktopInactive.value = false;
  }
});

const decrementScale = () => {
  setScale(layoutConfig.scale.value - 1);
  applyScale();
};
const incrementScale = () => {
  setScale(layoutConfig.scale.value + 1);
  applyScale();
};

const inputSetScale = (scale:number) => {
  setScale(scale);
  applyScale();
};

const applyScale = () => {
  document.documentElement.style.fontSize = layoutConfig.scale.value + 'px';
};
// const onInputStyleChange = (value?: string) => {
//   $primevue.config.inputStyle = value;
// };


</script>

<template>
  <Sidebar v-model:visible="layoutState.configSidebarVisible.value" position="right"
           class="layout-config-sidebar w-20rem">
    <h5>Scale</h5>
    <div class="flex align-items-center">
      <Button icon="pi pi-minus" type="button" @click="decrementScale()" class="w-2rem h-2rem mr-2" text rounded
              :disabled="layoutConfig.scale.value === scales[0]"></Button>
      <div class="flex gap-2 align-items-center">
        <i class="pi pi-circle-fill text-300 jwCursorPointer" v-for="s in scales" :key="s"
           :class="{ 'text-primary-500': s === layoutConfig.scale.value }"
           @click="inputSetScale(s)"
        ></i>
      </div>
      <Button icon="pi pi-plus" type="button" @click="incrementScale()" class="w-2rem h-2rem ml-2" text rounded
              :disabled="layoutConfig.scale.value === scales[scales.length - 1]"></Button>
    </div>

<!--    <template v-if="!simple">-->
<!--      <h5>Input Style</h5>-->
<!--      <div class="flex">-->
<!--        <div class="field-radiobutton flex-1">-->
<!--          <RadioButton :modelValue="inputStyle" name="inputStyle" value="outlined" inputId="outlined_input"-->
<!--                       @update:modelValue="onInputStyleChange"></RadioButton>-->
<!--          <label for="outlined_input">Outlined</label>-->
<!--        </div>-->
<!--        <div class="field-radiobutton flex-1">-->
<!--          <RadioButton :modelValue="inputStyle" name="inputStyle" value="filled" inputId="filled_input"-->
<!--                       @update:modelValue="onInputStyleChange"></RadioButton>-->
<!--          <label for="filled_input">Filled</label>-->
<!--        </div>-->
<!--      </div>-->
<!--      <h5>Novo menu</h5>-->
<!--      <InputSwitch v-model="layoutConfig.newMenu.value" ></InputSwitch>-->
<!--    </template>-->
  </Sidebar>
</template>

<style lang="scss" scoped></style>
