// Conveniently import this file anywhere to use db
import {config} from '@/config';
import {firestore, FieldPath, Timestamp, GeoPoint, FieldValue,
  FcmFirebase, AggregateField} from '@/libs/FcmFirestore';
import {initializeApp} from 'firebase/app';
import { getAnalytics, setUserProperties, logEvent } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getDatabase, connectDatabaseEmulator, ref, set, update } from 'firebase/database';
import { getAuth, connectAuthEmulator} from 'firebase/auth';


// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/database';
// import 'firebase/auth';
// import 'firebase/functions';
import Bowser from 'bowser';
import type {Functions} from '@firebase/functions';

// import 'firebase/analytics';


//
// <!-- Load the Firebase SDKs before loading this file -->
// <script src="/__/firebase/init.js"></script>
//
// fetch('/__/firebase/init.json').then(async response => {
//   firebase.initializeApp(await response.json());
// });


const firebaseApp = initializeApp(config.firebase);

firestore.fcmInit(firebaseApp);
// firebase.firestore().settings({cacheSizeBytes: 3e+8});
// firebase.firestore().enablePersistence({synchronizeTabs: true}).catch((err)=>{
//   console.error('Firestore, Erro ao habilitar Persistência, pode ser que tenha outra aba aberta do sistema', err);
// });

let _analytics:any = null;

interface FcmFunctionsType extends Functions {
  httpsCallable: (...args:any[]) => any;
}


if(config.configId!=='emulator') {
  _analytics = getAnalytics(firebaseApp);
  const browserInfo = Bowser.parse(window.navigator.userAgent);

  setUserProperties(_analytics, {
    revision: import.meta.env.VUE_APP_VERSION_NUMBER || 0,
    plataform: browserInfo.platform.type,
    os: browserInfo.os.name,
    osVersion: browserInfo.os.version,
    osVersionName: browserInfo.os.versionName,
    browser: browserInfo.browser.name,
    browserVersion: browserInfo.browser.version,
  });
}

const analytics = { // Wraper da analytics no formato da analytics antiga
  logEvent: (...args:any[])=>{
    if(config.configId!=='emulator') {
      // @ts-ignore
      return logEvent(_analytics, ...args);
    }
  }
};


const realtimeDatabaseAdditionals:any[] = [];
// @ts-ignore
const functions: FcmFunctionsType = getFunctions(firebaseApp, config.region);
functions.httpsCallable = (...args:any[]) => {
  // @ts-ignore
  return httpsCallable(functions, ...args);
};
const database = getDatabase(firebaseApp);
const refDatabase = ref;
const setDatabase = set;
const updateDatabase = update;

function startRealtimeDatabaseAdditionals() {
  if (!realtimeDatabaseAdditionals.length) {
    for (const url of config.realtimeDatabaseAdditionals || []) {
      realtimeDatabaseAdditionals.push(getDatabase(firebaseApp, url));
    }
  }
}

const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// Debug functions local http and onCall
if(config.configId==='emulator') {
  firestore.useEmulator('localhost', 47673);
  connectFunctionsEmulator(functions, 'localhost', 47672);
  connectStorageEmulator(storage, 'localhost', 47678);
  connectAuthEmulator(auth, 'http://localhost:47676');
  connectDatabaseEmulator(database, 'localhost', 47677);
}
// @ts-ignore
FcmFirebase.functions = () =>{
  return functions;
};
// @ts-ignore
FcmFirebase.auth = () =>{
  return auth;
};

// @ts-ignore
window.firebaseInjectCommon = FcmFirebase;

if(import.meta.env.NODE_ENV !== 'production') {
  // @ts-ignore
  window.jwDebug ||= {};
  // @ts-ignore
  window.firebaseDebug = FcmFirebase;
  if(import.meta.env.USE_LOCAL_CLOUD) {
    firestore.useEmulator('localhost', 47673);
  }
  // @ts-ignore
  window.jwDebug.functions = functions;
  // @ts-ignore
  // window.firebaseDev = new FcmFirestore(initializeApp(listConfig.volandevjw.firebase, 'VolanDev'));
  // window.firebaseProd = new FcmFirestore(initializeApp(listConfig.volanjwcg.firebase, 'VolanProd'));
}

// firebase.firestore().enablePersistence({synchronizeTabs: true});





export {FieldPath, Timestamp, GeoPoint, FieldValue, firestore, firebaseApp,
  functions, database, auth, analytics, storage, AggregateField, realtimeDatabaseAdditionals, startRealtimeDatabaseAdditionals,
  refDatabase, setDatabase, updateDatabase};
