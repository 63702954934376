'use strict';
import {padStart} from 'lodash-es';
import moment from 'moment';


export function delay(ms:number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class FcmTimer {
  startTime:any=null;
  lastTime:any=null;
  lastTimeKey:any={};
  type=1;
  onlyKey:any = null;
  showBeginKey = false;
  constructor(onlyKey:any, showBeginKey=false) {
    // @ts-ignore
    if(typeof process==='object' && process.hrtime) {
      this.type = 2;
    }
    this.onlyKey = onlyKey;
    this.showBeginKey = showBeginKey;
  }

  start() {
    this.startTime = this.getTime();
    this.lastTime = this.getTime();
  }
  lap(name='', key=null) {
    const lastTime = this.getTime();
    if(key) {
      if(!this.onlyKey || this.onlyKey===key) {
        if(this.lastTimeKey[key] || this.showBeginKey) {
          console.log(
            ' '+(this.lastTimeKey[key]?(lastTime-this.lastTimeKey[key]):0).toFixed(3).padStart(10, ' ')
            +' '+(lastTime-this.startTime).toFixed(3).padStart(10, ' ')
            +' '+name
          );
        }
        this.lastTimeKey[key] = lastTime;
      }
    }
    else {
      console.log(
        ' '+(lastTime-this.lastTime).toFixed(3).padStart(10, ' ')
        +' '+(lastTime-this.startTime).toFixed(3).padStart(10, ' ')
        +' '+name
      );
      this.lastTime = lastTime;
    }
  }
  getTime() {
    if(this.type===2) {
      return Number(process.hrtime.bigint())/1e+6;
    }
    // eslint-disable-next-line
    return window.performance.now();
  }
}



export function minutes2field(sc:number) {
  if(!sc && sc!==0) {
    return '';
  }
  // @ts-ignore
  return (sc<0?'-':'')+padStart(Math.abs(Math.floor((sc||0)/60)) || 0, 2, '00')+':'+padStart((sc||0)%60 || 0, 2, '00');
}

export function field2minutes(field:string) {
  const sp = (field ||'').split(':');
  // @ts-ignore
  let ret = parseInt(sp[0] || 0) * 60;
  // @ts-ignore
  ret +=parseInt(sp[1] || 0);
  return ret;
}


export function minutes2schedule(sc:any) {
  if(!sc || sc ==='') {
    return {ini: '', end: ''};
  }
  sc.ini = minutes2field(sc.ini);
  sc.end = minutes2field((sc.end<=1440)?sc.end:sc.end-1440);
  return sc;
}

export function schedule2minutes(field:any) {
  if(!field || !field.ini || !field.end) {
    return {
      ini: null,
      end: null
    };
  }
  let ini = field2minutes(field.ini);
  let end = field2minutes(field.end);

  if(ini>1440) {
    ini=1440;
  }
  if(end>1440) {
    end=1440;
  }

  return {
    ini: ini,
    end: (end>=ini)? end: end+1440
  };
}




export function getDateMonth(value:any, utcOffset=-240) {
  let v;
  if (typeof value?.toDate === 'function') {
    v = moment(value.toDate());
  } else {
    v = moment(value);
  }
  if (!v.isValid()) {
    return null;
  }
  return v.utcOffset(utcOffset).format('YYYY-MM');
}


export function timeoutPromise(prom:any, time:any) {
  let timer:any;
  return Promise.race([
    prom,
    new Promise((_r, rej) => timer = setTimeout(rej, time))
  ]).finally(() => clearTimeout(timer));
}




