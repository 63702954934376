import {Workspace, defaultRoutes, Layout} from '@common/AuthUtils';
import { useAuthStore } from '@/stores/auth';
import {t} from '@/plugins/i18n';
import AppLayout from '@/layouts/AppLayout.vue';
import type {RouteRecordRaw} from 'vue-router';

/*
meta properties
{
  authRequired: false,
  needCompany: false,
  workspaces: [Workspace.CLIENT],
  companyModules: {
    anesthetists: ['list'],
  },
  breadcrumb: [t('all.admin'), t('all.dashboard')],
}

 */

declare module 'vue-router' {
  // eslint-disable-next-line
  interface RouteMeta {
    authRequired?: boolean,
    needCompany?: boolean,
    workspaces?: string[],
    companyModules?: any,
    breadcrumb?: string[],
    disableWidthLimit?: boolean,
    layout?: string,
  }
}

export const routes:RouteRecordRaw[] = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/generic/Home.vue'),
  //   meta: {
  //     authRequired: false,
  //     workspaces: [],
  //   },
  // },

  {
    path: '/',
    name: 'home',
    redirect: { name: 'client/login' }
  },

  {
    path: '/client',
    redirect: { name: 'client/login' }
  },
  {
    path: '/login',
    name: 'login',
    redirect: { name: 'client/login' }
  },
  {
    path: '/client/login',
    name: 'client/login',
    component: () => import('@/views/client/Login.vue'),
    meta: {
      beforeResolve(routeTo:any, routeFrom:any, next:any) {
        const authStore = useAuthStore();
        if (authStore.loggedIn) {
          next({ name: defaultRoutes.routeLogged[authStore.loggedIn] });
        } else {
          next();
        }
      },
    },
  },


  {
    path: '/',
    component: AppLayout, // layouts
    children: [
      {
        path: '/admin/dashboard/:id?',
        name: 'admin/dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', t('all.dashboard')],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/AboutView.vue'),
        meta: {
          authRequired: false,
          workspaces: [Workspace.NONE],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/debugComposition/:id?',
        name: 'admin/debugComposition',
        component: () => import('@/views/generic/debugComposition.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', 'Debug'],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/debugOptions/:id?',
        name: 'admin/debugOptions',
        component: () => import('@/views/generic/debugOptions.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', 'Debug'],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/companies/:id?',
        name: 'admin/companies',
        component: () => import('@/views/admin/Companies.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', 'Empresas'],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/users/:id?',
        name: 'admin/users',
        component: () => import('@/views/admin/Users.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', 'Empresas'],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/channels/:id?',
        name: 'admin/channels',
        component: () => import('@/views/admin/Channels.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: ['Admin', 'Empresas'],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/Todo/',
        name: 'Todo',
        component: () => import('@/views/generic/Todo.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['TODO'],
          layout: Layout.CLIENT,
        },
      },


      // {
      //   path: '/client/dashboard',
      //   name: 'client/dashboard',
      //   component: () => import('@/views/client/Dashboard.vue'),
      //   meta: {
      //     authRequired: true,
      //     workspaces: [Workspace.CLIENT],
      //     breadcrumb: ['Dashboard'],
      //     layout: Layout.CLIENT,
      //   },
      // },
      {
        path: '/client/dashboard',
        name: 'client/dashboard',
        component: () => import('@/views/generic/Todo.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Dashboard'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/followUpAppointments/:id?',
        name: 'client/followUpAppointments',
        component: () => import('@/views/client/FollowUpAppointments.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Client'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/cashbackTransactions/:id?',
        name: 'client/cashbackTransactions',
        component: () => import('@/views/client/CashbackTransactions.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Client'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/kudos/:id?',
        name: 'client/kudos',
        component: () => import('@/views/client/Kudos.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Client'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/improvements/:id?',
        name: 'client/improvements',
        component: () => import('@/views/client/Improvements.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Client'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/ratings/:id?',
        name: 'client/ratings',
        component: () => import('@/views/client/Ratings.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Rating'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/patients/:id?',
        name: 'client/patients',
        component: () => import('@/views/client/Patients.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Pacientes'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/users/:id?',
        name: 'client/users',
        component: () => import('@/views/client/Users.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Usuários'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/companyChannels/:id?',
        name: 'client/companyChannels',
        component: () => import('@/views/client/CompanyChannels.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Canais da empresa'],
          layout: Layout.CLIENT,
          needCompany: true,
        },
      },
      {
        path: '/client/selectCompany',
        name: 'client/selectCompany',
        component: () => import('@/views/client/SelectCompanies.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Empresas'],
          layout: Layout.CLIENT,
        },
      },

      {
        path: '/admin/accessProfiles/:id?',
        name: 'admin/accessProfiles',
        component: () => import('@/views/generic/Todo.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: [''],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/posts/:id?',
        name: 'admin/posts',
        component: () => import('@/views/admin/Posts.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: [''],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/admin/postsCategories/:id?',
        name: 'admin/postsCategories',
        component: () => import('@/views/admin/PostsCategories.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.ADMIN],
          breadcrumb: [''],
          layout: Layout.ADMIN,
        },
      },
      {
        path: '/client/posts/:id?',
        name: 'client/posts',
        component: () => import('@/views/client/Posts.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: ['Post'],
          layout: Layout.CLIENT,
        },
      },
      {
        path: '/client/postsCategories/:id?',
        name: 'client/postsCategories',
        component: () => import('@/views/client/PostsCategories.vue'),
        meta: {
          authRequired: true,
          workspaces: [Workspace.CLIENT],
          breadcrumb: [''],
          layout: Layout.CLIENT,
        },
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('@/views/generic/404.vue')
  },


];


















