
export const Workspace = {
  COMPANY: 'COMPANY', // esse workspace foi deixado de lado uma vez que não é usado no Volan
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  NONE: 'NONE',
};

export const Layout = {
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  NONE: 'NONE',
};

export const ExtraWorkspace = {
  COMPANY: {
    userDatabase: ''
  },
};

export const defaultClaims = {
  type: {},
  rules: {},
  c: {},
  info: null,
};


export const defaultRoutes:any = {
  routeLogged: {
    ADMIN: 'admin/dashboard',
    CLIENT: 'client/dashboard',
    COMPANY: 'company/dashboard',
  },
  routeNotLogged: {
    ADMIN: 'client/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  routeLogOut: {
    ADMIN: 'client/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  companySelect: {
    ADMIN: 'admin/companies',
    CLIENT: 'client/selectCompany',
    COMPANY: 'company/selectCompany',
  },
};
