// Pinia Store
import { defineStore } from 'pinia';
import {firestore, functions, auth} from '@/db';
import {signOut, onAuthStateChanged, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider,
  signInWithEmailAndPassword, getIdToken, getIdTokenResult, updatePassword} from 'firebase/auth';
import {eventHub} from '@/eventHub';
import {FcmLocalStorage} from '@/utils/FcmLocalStorage';
import {configLoadedObj, useConfigStore} from './config';
import {apiErrorCode, UserType} from '@common/status';
import {Workspace, defaultRoutes} from '@common/AuthUtils';
import {FcmEvent} from '@/utils/FcmEvent';
import {getUserAuth, getCompanyUser} from '@entities/UserEntity';
import {size, isEqual} from 'lodash-es';
import axios from 'axios';
// import { useRouter, useRoute } from 'vue-router';



function getSavedState(key:string):any {
  const tmp = FcmLocalStorage.getItem(key);
  if (tmp) {
    return JSON.parse(tmp);
  }
  return null;
}

function saveState(key:string, state:any) {
  FcmLocalStorage.setItem(key, JSON.stringify(state, function(k, v) {
    if(typeof v ==='object' && v && (v.wa==='FieldValue.serverTimestamp' || v.fa==='FieldValue.serverTimestamp') || k==='updatedAt') {
      return null;
    }
    return v;
  }));
}

function unsubscribeAll() {
  for(const i in unsubscribes) {
    if(typeof unsubscribes[i] === 'function') {
      unsubscribes[i]();
      unsubscribes[i]= null;
    }
  }
  unsubscribes = {};
  local = {};
}


function unsubscribe(name:string) {
  if(typeof unsubscribes[name] === 'function') {
    unsubscribes[name]();
    unsubscribes[name]= null;
  }
}
class ErrorAuth extends Error {
  code: any;
  constructor(message:string, code:any) {
    super(message);
    this.message = message;
    this.code = code;
  }
}

async function setDefaultAuthHeaders(that:any) {
  // @ts-ignore
  axios.defaults.headers.common.Authorization = auth.currentUser?.token || '';
  console.log('TODO setar headers para o fecth ao invés da axios');
}

export const userLoadedObj = new FcmEvent(10000);
export function getUserLoaded() {
  return userLoadedObj.getPromise();
}
userLoadedObj.getPromise().then(()=>{
  console.log('UserLoaded Loaded');
});

let unsubscribes:any = {};
let local:any= {};
let unsubscribeOnAuthStateChanged:any = null;
export let ON_LOGIN = false;

function snapshotError(name:string, that:any) {
  return function(args:any) {
    console.error('snapshotError', name, args);
    that.logOut();
  };
}

async function getClaims():Promise<any> {
  if (!auth.currentUser) {
    return null;
  }
  const idTokenResult = await getIdTokenResult(auth.currentUser);
  return {
    type: {},
    rules: {},
    c: {},
    info: null,
    ...idTokenResult?.claims?.jw as any,
  };
}


interface State {
  currentUser: any,
  currentCompany: any,
  currentCompanyId: undefined|null|string,
  currentCompanyMatrizId: undefined|null|string,
  isSuperAdminAccess: boolean,
  currentWorkspace: string,
  systemNeedReload: boolean,
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    currentUser: getSavedState('auth.currentUser'),
    currentCompany: getSavedState('auth.currentCompany'),
    currentCompanyId: getSavedState('auth.currentCompanyId'),
    currentCompanyMatrizId: getSavedState('auth.currentCompanyMatrizId'),
    isSuperAdminAccess: getSavedState('auth.isSuperAdminAccess'),
    currentWorkspace: getSavedState('auth.currentWorkspace'),
    systemNeedReload: false,
  }),
  getters: {
    loggedIn():any {
      let isValid = false;
      switch(this.currentWorkspace) {
        case Workspace.COMPANY:
          if (this.currentUser && this.currentCompany) {
            isValid = true;
          }
          break;
        case Workspace.ADMIN:
          if (this.currentUser) {
            isValid = true;
          }
          break;
        case Workspace.CLIENT:
          if (this.currentUser) {
            isValid = true;
          }
          break;
        default:
          if(this.currentUser) {
            console.warn('Inconsistencia encontrada, usuário sem workspace definido');
          }
      }
      if(isValid) {
        return this.currentWorkspace;
      }
      return false;
    },
    getterIsResident():boolean {
      if (this.isSuperAdminAccess) {
        return false;
      }
      return this.currentUser?._companyUser?.type===UserType.KRESIDENT_ANESTHETIST ||
        this.currentUser?._companyUser?.type===UserType.KRESIDENT_SURGEON ||
        this.currentUser?._companyUser?.profilesIds?.includes('g');
    }
  },
  actions: {
    init() {
      if(!unsubscribeOnAuthStateChanged) {
        unsubscribeOnAuthStateChanged = onAuthStateChanged(auth, (user) => {
          eventHub.emit('auth:firebaseOnAuthStateChanged', {firebaseUser: user});
          this.onAuthStateChanged({firebaseUser: user});
        });
      }
      window.addEventListener('storage', (e) => {
        if (e.key==='auth.currentCompanyId' && e.newValue && this.currentCompanyId
          && e.newValue!==this.currentCompanyId) {
          console.log('company changed TODO reload page', this.currentCompanyId, e.newValue);
          this.systemNeedReload = true;
          unsubscribeAll();
        }
      });
      setDefaultAuthHeaders(this);
      this.validate();
    },
    onlySignInWithEmailAndPassword({email, password}:{email: string, password:string}) {
      return signInWithEmailAndPassword(auth, email, password);
    },
    updatePassword(password:string) {
      if (auth.currentUser) {
        return updatePassword(auth.currentUser, password);
      }
    },

    async changeAuth() {
      eventHub.emit('auth:changeAuth', null);
      this.changeCompany();
    },
    async changeCompany(arg:any=null) {
      eventHub.emit('auth:changeCompany', arg);
    },

    async logIn({locale, provider, email, password, workspace=Workspace.CLIENT}:
                  {locale:string, provider:string, email:string, password:string, workspace:string}) {
      ON_LOGIN = true;
      let authUser:any =  null;
      try {
        if(provider==='password') {
          if(!email) {
            return Promise.reject(new ErrorAuth('', 'missingEmail'));
          }
          if(!password) {
            return Promise.reject(new ErrorAuth('', 'missingPassword'));
          }
          authUser = await signInWithEmailAndPassword(auth, email, password);
        }
        else {
          let providerObj = null;
          switch(provider) {
            case 'facebook.com':
              providerObj = new FacebookAuthProvider();
              break;
            case 'google.com':
              providerObj = new GoogleAuthProvider();
              break;
            case 'apple.com':
              providerObj = new OAuthProvider('apple.com');
              break;
            default:
              return Promise.reject(new Error('Unknown provider'));
          }
          if(locale) {
            providerObj.setCustomParameters({
              locale: locale
            });
          }
          authUser = await signInWithPopup(auth, providerObj);
        }
      } catch(error) {
        console.error('Erro em LogIn', error);
        ON_LOGIN = false;
        return Promise.reject(error);
      }
      await this.clearLogin();
      await this.onAuthStateChanged({firebaseUser: authUser.user, provider: provider, isClient: true, workspace: workspace, isLogin: true});
      ON_LOGIN = false;
    },
    async logInPhone({authUser}:any) {
      await this.clearLogin();
      await this.onAuthStateChanged({firebaseUser: authUser.user, provider: 'phone', isClient: true, isLogin: true});
      ON_LOGIN = false;
    },
    async teste() {

    },
    async logOut() {
      unsubscribeAll();
      this.SET_CURRENT();
      eventHub.emit('auth:changeUser', {userId: null, user: null});
      try {
        await signOut(auth);
      } catch(error) {
        // An error happened.
        console.error('Error on logout', error);
      }

      // @ts-ignore
      if(this.router.currentRoute.value?.meta?.authRequired) {
        // @ts-ignore
        const workspace = this.router.currentRoute.value?.meta?.workspace;
        // @ts-ignore
        console.log(defaultRoutes.routeNotLogged[workspace] || 'login');
        // @ts-ignore
        await this.router.push({name: defaultRoutes.routeNotLogged[workspace] || 'login'});
      }

      await this.clearLogin();
      await this.changeAuth();
    },
    async clearLogin() {
      try {
        for (let i = 0; i < FcmLocalStorage.length; i++) {
          const key = FcmLocalStorage.key(i);
          if(key?.startsWith('jwSearch')) {
            FcmLocalStorage.removeItem(key);
          }
        }
      } catch (err:any) {
        console.error('Navegador obsoleto ou com os cookies desabilitados, atualize o navegador e ou habilite os cookies', err.message);
      }
    },
    async authCompanyModulesValidate({module, action}:{module:string, action:string}) {
      if (this.currentWorkspace) { // Desabilitado a validação de perfis
        return true;
      }
      await userLoadedObj.getPromise();
      if(this.currentWorkspace!==Workspace.ADMIN && module && action) {
        await configLoadedObj.getPromise();
        // const userProfiles = (this.currentCompanyId?Object.keys(this.currentUser?.companyProfiles?.[this.currentCompanyId]||{}):null) || null;
        let userProfiles:any = null;
        if (this.currentCompanyMatrizId && this.currentCompanyId) {
          userProfiles = this.currentUser?._companyUser?.filiaisProfiles[this.currentCompanyId] || null;
        }
        else {
          userProfiles = this.currentUser?._companyUser?.profilesIds || null;
        }
        const configStore = useConfigStore();
        const configProfiles = configStore.configWeb?.companyProfiles || null;
        if(!configProfiles ||!userProfiles) {
          console.error('config.web.companyProfiles não encontrado', configProfiles, userProfiles);
          return false;
        }
        return !!configProfiles[module]?.[action]?.some((a:string) => userProfiles.includes(a));
      }
      if(!module || !action) {
        console.error('module ou action não encontrado', module, action);
      }
      return true;
    },
    async authCompanyModulesValidateMultiple({companyModules}: {companyModules:any}) {
      if (this.currentWorkspace) { // Desabilitado a validação de perfis
        return true;
      }
      await userLoadedObj.getPromise();
      if(this.currentWorkspace!==Workspace.ADMIN && companyModules && size(companyModules)>0) {
        await configLoadedObj.getPromise();
        const userProfiles = (this.currentCompanyId?Object.keys(this.currentUser?.companyProfiles?.[this.currentCompanyId]||{}):null) || null;
        const configStore = useConfigStore();
        const configProfiles = configStore.configWeb?.companyProfiles || null;
        if(!configProfiles ||!userProfiles) {
          console.error('config.web.companyProfiles não encontrado', configProfiles, userProfiles);
          return false;
        }
        let isValid = false;
        forModules: for(const moduleId in companyModules ||{}) {
          for(const actionId of companyModules[moduleId]||[]) {
            if(configProfiles[moduleId][actionId]?.some((a:string)=>userProfiles.includes(a))) {
              isValid = true;
              break forModules;
            }
          }
        }
        if(!isValid) {
          return false;
        }
      }
      return true;
    },
    async validate({workspaces, rules, companyModules}:any= {}) {
      await userLoadedObj.getPromise();
      // ADMIN tem acesso ao workspace COMPANY
      if(this.currentWorkspace!==Workspace.ADMIN &&
        workspaces?.length>0 && !workspaces?.includes?.(this.currentWorkspace)) {
        console.log('workspace', workspaces);
        console.log('state.currentWorkspace', this.currentWorkspace);
        console.log('state.currentUser', this.currentUser);
        await this.logOut();
        return {isOk: false, error: 'notLogged'};
      }
      // debugger;

      let isValid = false;
      switch(this.currentWorkspace) {
        case Workspace.COMPANY:
          if (this.currentUser && this.currentCompany) {
            isValid = true;
          }
          break;
        case Workspace.ADMIN:
          if (this.currentUser) {
            isValid = true;
          }
          break;
        case Workspace.CLIENT:
          if (this.currentUser) {
            isValid = true;
          }
          break;
        default:
          if(this.currentUser) {
            console.warn('Inconsistencia encontrada, usuário sem workspace definido');
          }
      }

      if (!isValid) {
        await this.logOut();
        return {isOk: false, error: 'notLogged'};
      }
      // Não é usado no Volan
      // if(this.currentWorkspace!==Workspace.ADMIN && rules && rules.length>0) {
      //   let isValid = false;
      //   if (this.currentUser.rules) {
      //     for (const r of rules) {
      //       if (this.currentUser.rules[r]) {
      //         isValid = true;
      //         break;
      //       }
      //     }
      //   }
      //   if(!isValid) {
      //     return {isOk: false, error: 'denied'};
      //   }
      // }

      if(this.currentWorkspace!==Workspace.ADMIN && companyModules && size(companyModules)>0) {
        await configLoadedObj.getPromise();
        let userProfiles:any = null;
        if (this.currentCompanyMatrizId && this.currentCompanyId) {
          userProfiles = this.currentUser?._companyUser?.filiaisProfiles[this.currentCompanyId] || null;
        }
        else {
          userProfiles = this.currentUser?._companyUser?.profilesIds || null;
        }
        const configStore = useConfigStore();
        const configProfiles = configStore.configWeb?.companyProfiles || null;
        if(!configProfiles ||!userProfiles) {
          console.error('config.web.companyProfiles não encontrado', configProfiles, userProfiles);
          return {isOk: false, error: 'denied'};
        }
        let isValid = false;
        forModules: for(const moduleId in companyModules ||{}) {
          for(const actionId of companyModules[moduleId]||[]) {
            if(configProfiles[moduleId][actionId]?.some((a:string)=>userProfiles.includes(a))) {
              isValid = true;
              break forModules;
            }
          }
        }
        if(!isValid) {
          return {isOk: false, error: 'denied'};
        }
      }

      /*    if(rules && rules.length>0) {
        isValid = false;
        if(!this.currentUser.rules) {
          console.warn('Acesso requerido');
          router.push({name: defaults.routeLogged[workspace] || 'login'});
          return false;
        }
        for(let r of rules) {
          if(this.currentUser.rules[r]) {
            isValid = true;
            break;
          }
        }
        if (!isValid){
          console.warn('Acesso requerido');
          router.push({name: defaults.routeLogged[workspace] || 'login'})
          return false;
        }
      } */


      return {isOk: true, user: this.currentUser, currentCompanyId: this.currentCompanyId, error: ''};
    },
    async onAuthStateChanged({firebaseUser, provider, isClient, isLogin}:any) {
      if (!isLogin && ON_LOGIN) {
        return;
      }
      if (firebaseUser && auth.currentUser) {
        let workspace = this.currentWorkspace;

        if(!workspace) {
          const cl = await getClaims();
          if(cl.type.cli) {
            workspace = Workspace.CLIENT;
          }
          else if(cl.type?.adm) {
            workspace = Workspace.ADMIN;
          }
          else if(cl.type?.comp) {
            workspace = Workspace.COMPANY;
          }
          else {
            console.warn('idTokenResult', cl);
          }
        }

        if(!workspace) {
          console.log('buscar workspace');
          const result = await functions.httpsCallable('auth-checkWorkspace')({id: firebaseUser.uid});
          const cl = result.data.data;
          if(result.data && result.data.errorCode===apiErrorCode.OK && cl) {
            if(cl.type?.cli) {
              workspace = Workspace.CLIENT;
            }
            else if(cl.type?.adm) {
              workspace = Workspace.ADMIN;
            }
            else if(cl.type?.comp) {
              workspace = Workspace.COMPANY;
            }
            else {
              console.warn('auth-checkWorkspace', result);
            }
            await getIdToken(auth.currentUser, true);
          }
          else {
            console.error('not workspace', firebaseUser);
            return this.logOut();
          }
        }

        let loaded = false;
        switch(workspace) {
          case Workspace.COMPANY:
            loaded = true;
            // this.loadUserCompany(firebaseUser.uid);
            break;
          case Workspace.ADMIN:
            loaded = true;
            await this.loadUserAdmin(firebaseUser.uid);
            if(this.currentCompanyId) {
              await this.loadCompanyOnClient(this.currentCompanyId, true);
            }
            break;
          case Workspace.CLIENT:
            loaded = true;
            await this.loadUserClient(firebaseUser.uid);
            if(this.currentCompanyId) {
              await this.loadCompanyOnClient(this.currentCompanyId, false);
            }
        }
        if(!loaded) {
          console.error('ERRO ao procurar workspace do usuário', firebaseUser);
          return this.logOut();
        }

        // if(isClient) {
        //   console.warn('ERRO ao procurar workspace do usuário, criando client: '+workspace, firebaseUser)
        //   await firestore.collection('company_users').doc(firebaseUser.uid).set({
        //     createdEnv: 'WEB',
        //     createdType: provider || 'unknown'
        //   }, {merge: true})
        //
        //   let result = await functions.httpsCallable('auth-checkWorkspace')({uid: firebaseUser.uid})
        //   if(result.data && result.data.errorCode===apiErrorCode.OK && result.data.data && result.data.data.type) {
        //     if(result.data.data.type.cli) {
        //       workspace = Workspace.name.CLIENT
        //     }
        //     else if(result.data.data.type.adm) {
        //       workspace = Workspace.name.ADMIN
        //     }
        //     else if(result.data.data.type.comp) {
        //       workspace = Workspace.name.COMPANY
        //     }
        //     else {
        //       console.warn('Erro ao buscar workspace, result', result);
        //     }
        //     await auth.currentUser.getIdToken(true);
        //   }
        //   if(workspace) {
        //     await dispatch('loadUserClient', firebaseUser.uid);
        //     return dispatch('changeAuth');
        //   }
        // }
      } else {
        if (isLogin) {
          console.error('not firebaseUser', firebaseUser);
        }
        return this.logOut();
      }
    },
    loadUserAdmin(userId:string) {
      return new Promise<void>((resolve, reject) => {
        if(userId===local.unsubscribeUserAdminId) {
          return resolve();
        }
        unsubscribeAll();
        local.unsubscribeUserAdminId = userId;
        this.isSuperAdminAccess = true;
        try {
          unsubscribes.userAdmin = firestore.collection('adminUsers').doc(userId)
            .onSnapshot((userDoc:any) => {
              const userData = userDoc.data();
              if(!userData) {
                this.logOut();
                console.error('Usuário sem userData userId: '+userId);
                eventHub.emit('auth:changeUser', {userId: null, user: null});
                return reject(new Error('Usuário sem userData userId: '+userId));
              }
              userData.id = userId;
              this.SET_CURRENT_WORKSPACE(Workspace.ADMIN);
              this.SET_CURRENT_USER(userData);
              userLoadedObj.resolve();
              resolve();
              eventHub.emit('auth:changeUser', {userId: userId, user: userData, isSuperAdminAccess: true});
            }, snapshotError('adminUsers6', this));
          if(this.currentCompanyId) {
            this.loadCompanyOnClient(this.currentCompanyId, true);
          }
        } catch(err) {
          console.error('Erro onSnapshot adminUsers', err);
          eventHub.emit('auth:changeUser', {userId: null, user: null});
        }
      });
    },

    async loadCompanyOnClient(companyId:string, isSuperAdmin = false) {
      return new Promise<void>((resolve, reject) => {
        if(unsubscribes.company && local.companyId && local.companyId===companyId) {
          console.log('loadCompanyOnClient SKIPPED');
          return resolve();
        }
        unsubscribe('company');
        if(!companyId) {
          this.SET_CURRENT_COMPANY(null);
          this.changeCompany();
          resolve();
        }
        local.companyId = companyId;
        unsubscribes.company?.();
        if (companyId) {
          try {
            let first = true;
            unsubscribes.company= firestore.collection('companies').doc(companyId)
              .onSnapshot((companyDoc:any) => {
                const companyData = companyDoc.data();
                companyData.id = companyId;
                if(!companyData) {
                  this.logOut();
                  console.error('company não encontrada companyId:'+companyId);
                  first = false;
                  return reject(new Error('company não encontrada companyId:'+companyId));
                }
                companyData.id = companyDoc.id;
                this.SET_CURRENT_COMPANY(companyData);
                this.changeCompany();
                if (first) {
                  first = false;
                  resolve();
                }
              }, snapshotError('companies3', this));
          } catch(err) {
            console.error('Erro onSnapshot companies', err);
          }
        }
      });
    },
    loadUserClient(userId:string) {
      return new Promise<void>(async (resolve, reject) => {
        if(userId===local.unsubscribeUserAuthId) {
          return resolve();
        }
        unsubscribeAll();
        local.unsubscribeUserAuthId = userId;
        try {
          let first = true;
          local.userId = (await firestore.collection('users').where('authsIds', 'array-contains', userId).fcmGet())?.[0]?.id || null;

          if (!local.userId) {
            console.error('User sem banco', userId);
            this.logOut();
            return resolve();
          }
          unsubscribes.client = firestore.collection('users').doc(local.userId)
            .onSnapshot((userDoc:any) => {
              const userData = userDoc.data();
              if(!userData) {
                this.logOut();
                userLoadedObj.reject();
                console.error('Usuário sem userData userId: '+local.userId);
                eventHub.emit('auth:changeUser', {userId: null, user: null});
                first = false;
                return reject(new Error('Usuário sem userData userId: '+local.userId));
              }
              userData.id = local.userId;
              this.SET_CURRENT_WORKSPACE(Workspace.CLIENT);
              if(!isEqual(userData?.companiesUser, this.currentUser?.companiesUser)) {
                auth.currentUser?getIdToken(auth.currentUser, true):'';
              }
              this.SET_CURRENT_USER(userData);
              userLoadedObj.resolve();
              console.log('TODO, validar se o usuário atual tem acesso a empresa selecionada', this);

              if(this.currentCompanyId) {
                const compU = getCompanyUser(userData, this.currentCompanyId, this.currentCompanyMatrizId);
                if (this.currentCompanyMatrizId) {
                  if (compU.active && compU.hasAccessFiliais) {
                    this.loadCompanyOnClient(this.currentCompanyId);
                  }
                  else {
                    this.SET_CURRENT_COMPANY(null);
                  }
                }
                else {
                  if (compU.active && compU.hasAccess) {
                    this.loadCompanyOnClient(this.currentCompanyId);
                  }
                  else {
                    this.SET_CURRENT_COMPANY(null);
                  }
                }
              }
              else {
                this.SET_CURRENT_COMPANY(null);
              }
              eventHub.emit('auth:changeUser', {userId: local.userId, user: userData});
              if (first) {
                first = false;
                resolve();
              }
            }, snapshotError('users5', this));
        } catch(err) {
          eventHub.emit('auth:changeUser', {userId: null, user: null});
          console.error('Erro onSnapshot users', err);
        }
      });
    },








    // Antigas mutations
    SET_CURRENT_WORKSPACE(newValue:string) {
      this.currentWorkspace = newValue;
      saveState('auth.currentWorkspace', newValue);
    },
    SET_CURRENT_USER(newValue:any) {
      this.currentUser = getUserAuth(newValue, this.currentCompanyId, this.currentCompanyMatrizId);
      saveState('auth.currentUser', this.currentUser);
      setDefaultAuthHeaders(this);
    },
    SET_CURRENT_COMPANY(newValue:any) {
      this.currentCompany = newValue || null;
      saveState('auth.currentCompany', newValue);
      this.currentCompanyId = (newValue||{}).id || null;
      if(newValue?.isFilial) {
        this.currentCompanyMatrizId = newValue?.matrizId || null;
      }
      else {
        this.currentCompanyMatrizId = null;
      }
      if (this.currentUser?._companyId!==this.currentCompanyId) {
        this.currentUser = getUserAuth(this.currentUser, this.currentCompanyId, this.currentCompanyMatrizId);
      }
      saveState('auth.currentUser', this.currentUser);
      saveState('auth.currentCompanyId', this.currentCompanyId);
      saveState('auth.currentCompanyMatrizId', this.currentCompanyMatrizId);
    },

    SET_CURRENT({workspace, user, company, companyId, isSuperAdminAccess}:any = {}) {
      this.currentWorkspace = workspace || null;
      this.currentCompanyId = (company||{}).id || companyId || null;

      this.currentUser = getUserAuth(user, this.currentCompanyId, this.currentCompanyMatrizId);
      this.currentCompany = company || null;
      this.isSuperAdminAccess = isSuperAdminAccess || false;

      if(company?.isFilial) {
        this.currentCompanyMatrizId = company?.matrizId || null;
      }
      else {
        this.currentCompanyMatrizId = null;
      }
      setDefaultAuthHeaders(this);
      saveState('auth.currentCompanyId', this.currentCompanyId);
      saveState('auth.isSuperAdminAccess', this.isSuperAdminAccess);
      saveState('auth.currentWorkspace', workspace || null);
      saveState('auth.currentUser', user || null);
      saveState('auth.currentCompany', company || null);
    },


    clearUser() {
      this.$reset();
    }
  }
});
