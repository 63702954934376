'use strict';

export const rules = {
  adm: [
    'adm',
    'sell',
    'analy',
    'sup',
  ],
  cli: [
  ],
  comp: [
    'adm',
    'edit',
    'deli',
    'att',
  ],
};

export const apiErrorCode = {
  permissionDenied: 'permissionDenied',
  statusError: 'statusError',
  inputError: 'inputError',
  unknown: 'unknown',
  exhaustedAttempts: 'exhaustedAttempts',

  paymentError: 'paymentError',
  bagError: 'bagError',
  paymentInvalidValue: 'paymentInvalidValue',
  notFound: 'notFound',
  emailNotConfig: 'emailNotConfig',

  bagEmptyProduct: 'bagEmptyProduct',
  bagEmptyAddress: 'bagEmptyAddress',
  OK: 'OK',
  pending: 'pending',
  warningAnswered: 'warningAnswered',
  timeOver: 'timeOver',
  userExists: 'userExists',

};

// response example: {isOk:false, errorCode: orderErrorCode.unknown, msg:''}

export const typesTransactions = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
};

export const VerifyStatus = {
  approved: 'approved',
  pending: 'pending',
  waitApproved: 'waitApproved',
};

export const JobState = {
  NOT_STARTED: 'NOT_STARTED',
  RUNNING: 'RUNNING',
  FINISH: 'FINISH',
};


export const Accommodation = {
  NURSERY: 'NURSERY',
  APARTMENT: 'APARTMENT',
  OUTPATIENT: 'OUTPATIENT',
  NONE: 'NONE',
};

export const AccommodationCodeOldXML = {
  NURSERY: '1',
  APARTMENT: '7',
  OUTPATIENT: '8',
};


export const AccommodationCodeVersion4XML = {
  NONE: '01',
  NURSERY: '01',
  APARTMENT: '02',
  OUTPATIENT: '01',
};

export const viaDeAcessoCodes = {
  '1': 'U',
  '0.5': 'M',
  '0.7': 'D',
};

export const viaDeAcessoCodes3x = {
  '1': '1',
  '0.5': '2',
  '0.7': '3',
};

export const source = {
  AXREG: 'AXREG',
  WEB: 'WEB',
  iOS: 'iOS',
  ANDROID: 'ANDROID',
  SYNC: 'SYNC',
  SCRYPT: 'SCRYPT',
  SPREADSHEET: 'SPREADSHEET',
};

export const dataSource = {
  KAXREG: 'KAXREG',             // Veio do AxReg
  KOCR: 'KOCR',                 // Lido com o OCR
  KSPREADSHEET: 'KSPREADSHEET', // Através da planilha
  KTYPED: 'KTYPED',             // Forma padrão, digitado no sistema
  KSPREADSHEETJUSTDOCTOR: 'KSPREADSHEETJUSTDOCTOR',     // Importado da planilha do Just Doctor
  KSPREADSHEETSIGA: 'KSPREADSHEETSIGA',  // Importado da planilha do SIGA
  KSPREADSHEETNOVAANESTESIOLOGIA: 'KSPREADSHEETNOVAANESTESIOLOGIA',  // Importado da planilha da Nova Anestesiologia - São José do Rio Preto
};

export const typesAxReg:any = {
  anesthetists: 'KAnesthetist',
  surgeons: 'KSurgeon',
  healthInsurances: 'KInsurance',
  hospitals: 'KHospital',
};

export const UserTypeOld = {
  ANESTHETIST: 'KAnesthetist',
  RESIDENT: 'KResident',
  USER: 'KUser'
};

export const UserType = {
  KANESTHETIST: 'KANESTHETIST',
  KSURGEON: 'KSURGEON',
  KDENTIST: 'KDENTIST',
  KRESIDENT_ANESTHETIST: 'KRESIDENT_ANESTHETIST',
  KRESIDENT_SURGEON: 'KRESIDENT_SURGEON',
  KUSER: 'KUSER',
  KSCRUB_NURSE: 'KSCRUB_NURSE',
  KNURSE: 'KNURSE',
};

export const UserBoardType = {
  KCRM: 'KCRM',
};

export const ProcedureType = {
  KOVERRIDEPORTE: 'KOVERRIDEPORTE',
  KOVERRIDE: 'KOVERRIDE',
  KCUSTOM: 'KCUSTOM',
  KCBHPM: 'KCBHPM'
};

export const EventStatus = {
  ProtocolCreated: 'ProtocolCreated',
  AwaitingCheck: 'AwaitingCheck',      // Aguardando Conferência
  Custom: 'Custom',                    // Customizado
  WaitSendGuia: 'WaitSendGuia',        // Aguardando Envio Guia/XML
  WaitPayment: 'WaitPayment',          // Aguardando Pagamento
  WaitSendRecourse: 'WaitSendRecourse',// Aguardando Envio de Recurso
  WaitRecourse: 'WaitRecourse',        // Aguardando Recurso
  Finished: 'Finished',                // Finalizado
  Received: 'Received',                // Recebido
  PartialLosed: 'PartialLosed',        // Parcialmente Perdido
  Losed: 'Losed',                      // Perdido
  PartialCuted: 'PartialCuted',        // Parcialmente Glosado
  Cuted: 'Cuted',                      // Glosado
  Released: 'Released',
  Imported: 'Imported',
  Pending: 'Pending',                   // Pendente
  CustomInfo: 'CustomInfo',                   // CustomInfo
};

export const EventStatusFinished = [
  EventStatus.Finished,
  EventStatus.Received,
  EventStatus.PartialLosed,
  EventStatus.Losed,
  EventStatus.PartialCuted,
  EventStatus.Cuted,
];

export const EventStatusUnfinished = [
  EventStatus.ProtocolCreated,
  EventStatus.AwaitingCheck,
  EventStatus.Custom,
  EventStatus.WaitSendGuia,
  EventStatus.WaitPayment,
  EventStatus.WaitSendRecourse,
  EventStatus.WaitRecourse,
  EventStatus.Pending,
  EventStatus.CustomInfo,
];

export const AppealStatus = {
  WaitSend: 'WaitSend',
  Sent: 'Sent',
  ResponseReceived: 'ResponseReceived',
  Finished: 'Finished',
};

export const ContractStatus = {
  Open: 'KOpen',
  WaitPayment: 'KWaitPayment',
  Closed: 'KClosed',
};

export const ContractInstallmentStatus = {
  WaitPayment: 'KWaitPayment',
  Received: 'KReceived',
};

export const MeasurementUnits = {
  Day: 'KDay',
  Week: 'KWeek',
  Month: 'KMonth',
  Year: 'KYear',
};

export const PersonTypes = {
  Event: 'KEvent',
};

export const FinancialItemTypes = {
  Income: 'KIncome',
  Outcome: 'KOutcome',
};

export const TeamTypes = {
  KANESTHETIST: 'KANESTHETIST',
  KSECONDARY_ANESTHETIST: 'KSECONDARY_ANESTHETIST',
  // KSURGEON: 'KSURGEON',
};

export const ObservationType = {
  Remove: 'Remove',
  RevertStatus: 'RevertStatus',
  Message: 'Message',
  Pending: 'Pending',
  Guide: 'Guide',
};


export const EventEstatusProcessing = [
  EventStatus.AwaitingCheck,
  EventStatus.Custom,
  EventStatus.WaitSendGuia,
  EventStatus.WaitPayment,
  EventStatus.WaitSendRecourse,
  EventStatus.WaitRecourse,
];

export const EventActions = {
  edit: 1,
  check: 2,
  sendGuia: 4,
  receiveAll: 8,
  loseAll: 16,
  recourseIdentify: 32,
  recourseSend: 64,
  finalize: 128,
  cancel: 256,
  glosaAll: 512,
  editProcedure: 1024,
  recourseReceived: 2048,
  showHistory: 4096,
  showInvoice: 8192,
  showAdditionalData: 16384,
  revertToAwaitCheck: 32768,
  remove: 65536,
  pending: 131072,
  editValues: 262144,
  addToBatch: 524288,
  customStatus: 1048576,
  customStatusAction: 2097152,
  showValues: 4194304,
  showCustomFields: 8388608,
  selectTypeOfPayment: 16777216,
  finalizeNotValued: 33554432,
  exportContaAzul: 67108864,
  addComment: 134217728,
  revertStatusToChangeValues: 268435456,
};

export const ActionsIntegrationsEvents = {
  edit: 1,
  delete: 2,
  associateAnesthetist: 4,
  associateSurgeon: 8,
  associateHospital: 16,
  associateHealthInsurance: 32,
  associatePatient: 64,
  becomeVolanEvent: 128,
  import: 256,
  associateSecondaryAnesthetist: 512,
  associateResident: 1024,
  associateType: 2048,
  associateAccommodation: 4096,
  updateEvent: 8192
};

export const TypeIntegrationsEvents = {
  IntegrationEvents: 'integrationsEvents',
  Imported: 'integrationsEventsImported',
};


export const EventActionsNeedConfirm = EventActions.check | EventActions.sendGuia
  | EventActions.receiveAll | EventActions.loseAll | EventActions.recourseIdentify
  | EventActions.recourseSend | EventActions.recourseReceived | EventActions.finalize
  | EventActions.remove | EventActions.customStatus | EventActions.customStatusAction;

export const EventActionsNeedConfirmMessage = EventActions.pending | EventActions.revertToAwaitCheck | EventActions.remove;

export const EventActionsNeedBillingAccess = EventActions.check | EventActions.sendGuia
  | EventActions.receiveAll | EventActions.loseAll | EventActions.recourseIdentify
  | EventActions.recourseSend | EventActions.recourseReceived | EventActions.finalize
  | EventActions.revertToAwaitCheck | EventActions.remove | EventActions.editProcedure;

export const EventAttachments = [
  {id: 'guide'},
  {id: 'record'},
  {id: 'rgo'},
  {id: 'term'},
  {id: 'apa'},
  {id: 'other'},
];

export const states = [
  {id:28, code:'--'},
  {id:1, code:'AC'},
  {id:2, code:'AL'},
  {id:3, code:'AP'},
  {id:4, code:'AM'},
  {id:5, code:'BA'},
  {id:6, code:'CE'},
  {id:7, code:'DF'},
  {id:8, code:'ES'},
  {id:9, code:'GO'},
  {id:10, code:'MA'},
  {id:11, code:'MT'},
  {id:12, code:'MS'},
  {id:13, code:'MG'},
  {id:14, code:'PA'},
  {id:15, code:'PB'},
  {id:16, code:'PR'},
  {id:17, code:'PE'},
  {id:18, code:'PI'},
  {id:19, code:'RJ'},
  {id:20, code:'RN'},
  {id:21, code:'RS'},
  {id:22, code:'RO'},
  {id:23, code:'RR'},
  {id:24, code:'SC'},
  {id:25, code:'SE'},
  {id:26, code:'SP'},
  {id:27, code:'TO'},
];


export const access = {
  user: 'user',
  admin: 'admin',
};

export const accessList = ['user', 'admin'];

export const Porte2PorteAnestesico = {
  '3A': 1,
  '3C': 2,
  '4C': 3,
  '6B': 4,
  '7C': 5,
  '9B': 6,
  '10C': 7,
  '12A': 8,
};

export const Porte2PorteAnestesicoOld = {
  '3A': '1',
  '3C': '2',
  '4C': '3',
  '6B': '4',
  '7C': '5',
  '9B': '6',
  '10C': '7',
  '12A': '8',
};

export const PorteAnestesico2Porte = {
  0: '0',
  1: '3A',
  2: '3C',
  3: '4C',
  4: '6B',
  5: '7C',
  6: '9B',
  7: '10C',
  8: '12A',
};


export const OcrCompType = {
  text: 'text',
  date: 'date',
  number: 'number',
  label: 'label'
};

export const OcrStatus = {
  KWaitingAnalyze: 'KWaitingAnalyze',
  KDone: 'KDone',
  KError: 'KError',
  KErrorThreshold: 'KErrorThreshold',
  KErrorNotFoundMatriz: 'KErrorNotFoundMatriz',
  KErrorEmptyCredits: 'KErrorEmptyCredits',
};



export const FormInputType = {
  text: 'text',
  paragraph: 'paragraph',
  number: 'number',
  radio: 'radio',
  checkbox: 'checkbox',
  select: 'select',
  date: 'date',
  time: 'time',
  dateTime: 'dateTime',
};

export const FormInputTypeReport = {
  one: 'one',
  exists: 'exists',
  multi: 'multi',
};

export const FormLinkType = {
  none: 'none',
  eventId: 'eventId',
  eventAnesthetistName: 'eventAnesthetistName',
  eventPatientName: 'eventPatientName',
  eventDate: 'eventDate',
  userId: 'userId',
  userName: 'userName',
  userEmail: 'userEmail',
  companyId: 'companyId',
  companyName: 'companyName',
  eventSurgeonName: 'eventSurgeonName',
  eventHospitalName: 'eventHospitalName',
};

export const EventType = {
  Honorario: 'Honorario',
  SPSADT: 'SPSADT',
  Consulta: 'Consulta',
};

export const CompanyType = {
  personal: 'personal',
  company: 'company',
};

export const defaultInGuide = {
  consultType: '1',
  accidenteIndication: '9',
  attendanceRN: 'N',
  attendimentType: '04',
  reasonToFinishAttendiment: '',
  tableCode: '22',
  tableCodeVersion2x: '16',
  grauPart: '06',
  reasonForLeavingConsultationGuide: '5',
  reasonForLeavingSADTGuide: '5',
  typeDisease: 'A',
};

export const AuthActions = {
  read: 'read',
  readOwn: 'readOwn',
  list: 'list',
  listOwn: 'listOwn',
  write: 'write',
  writeOwn: 'writeOwn',
  manager: 'manager',
  managerFiliais: 'managerFiliais',
  scheduler: 'scheduler',
  revertStatus: 'revertStatus',
  rm: 'rm', // remove
};

export const proportionToXml = {
  '1': '1',
  '0.7': '3',
  '0.5': '2',
};

export const BillingActions = {
  Received: 'Received',
  Losed: 'Losed',
  Glosado: 'Glosado',
  Discounted: 'Discounted'
};

export const ActionsOnFinishEvent = {
  RECEIVE: 'RECEIVE',
  LOSS: 'LOSS',
  GLOSA: 'GLOSA',
  DISCOUNT: 'DISCOUNT',
};

export const ScheduleChangeStatus = {
  Accepted: 'KAccepted',
  Rejected: 'KRejected',
  Error: 'KError',
  Pending: 'KPending',
  Cancelled: 'KCancelled'
};

export const ScheduleChangeType = {
  Exchange: 'KExchange',
  ReplaceSource: 'KReplaceSource',
  ReplaceDestiny: 'KReplaceDestiny',
};
export const techniquesUsedCodes = {
  KConventional: '1',
  KVideo: '2',
  KRobotic: '3',
  KNone: '1',
};
export const techniquesUsed = {
  KConventional: 'KConventional',
  KVideo: 'KVideo',
  KRobotic: 'KRobotic',
  KNone: 'KNone',
};

export const TaxBase = {
  Received: 'Received',
  Repasse: 'Repasse',
  BaseIRRF: 'BaseIRRF',
  Liquid: 'Liquid',
  LiquidValue: 'LiquidValue',
  Custom: 'Custom'
};

export const TaxType = {
  TAX: 'TAX',
  FEE: 'FEE',
};

export const TransferStatus = {
  OPEN: 'OPEN',
  LOCKED: 'LOCKED',
};

export const TransferMode = {
  ALL: 'ALL',
  RECEIVED: 'RECEIVED',
  RECURSED: 'RECURSED'
};

export const TransactionType = {
  PROCEDURE: 'PROCEDURE',
  COMPLEMENTATION: 'COMPLEMENTATION',
  ATTENDANCEFEE: 'ATTENDANCEFEE',
  EXPENSE: 'EXPENSE'
};


export const ProcedureUserCustom = {
  BASE: 'BASE',
  FINAL: 'FINAL',
  TABLE: 'TABLE'
};

export const OcrGuideStatus = {
  CREATED: 'CREATED',
  NEED_CLIENT: 'NEED_CLIENT',
  NEED_DEVELOPER: 'NEED_DEVELOPER',
  NEED_SUPPORT: 'NEED_SUPPORT',
  ON_TEST: 'ON_TEST',
  READY: 'READY'
};


export const OcrGuideCreditType = {
  PAYMENT: 'PAYMENT',
  GIFT: 'GIFT',
  TEST: 'TEST',
  GIFT_FIRST: 'GIFT_FIRST'
};

export const TransferType = {
  EVENTS: 'EVENTS',
  HOURS: 'HOURS'
};


export const ContractValidity = {
  KDetermined: 'KDetermined',
  KUndetermined: 'KUndetermined'
};

export const ContractType = {
  KHour: 'KHour',
  KEvent: 'KEvent',
};


export const EditorContentType = {
  HTML: 'HTML',
  MD: 'MD'
};

export const CompanyBillingType = {
  MEDICAL: 'MEDICAL',
  ANESTHETIST: 'ANESTHETIST'
};

export const CBHPMApplayTo = {
  MEDICAL: 'MEDICAL',
  ANESTHETIST: 'ANESTHETIST',
  ALL: 'ALL'
};

export const PayerType = {
  HEALTH_INSURANCE: 'HEALTH_INSURANCE',
  HOSPITAL: 'HOSPITAL'
};

export const TaxIdentifier = {
  TAX: 'TAX',
  INSS: 'INSS',
  IR: 'IR',
  IRJ: 'IRJ',
  CSLL: 'CSLL',
  PIS: 'PIS',
  COFINS: 'COFINS',
  PIS_TOMADOR: 'PIS_TOMADOR',
  COFINS_TOMADOR: 'COFINS_TOMADOR',
  CUSTOM: 'CUSTOM',
  OTHER: 'OTHER',
};




export const AgendaDisplayMode = {
  SHIFT: 'SHIFT',
  DEMAND: 'DEMAND'
};

export const CompanyId = {
  PETROLINA: 'ZhFAYvSOxOkMhoHXPt7O',
};


export const ReportsGenerator = {
  KPDF: 'KPDF',
  KXLSX: 'KXLSX',
  getDataParamsType: {
    FcmDateMonth: 'FcmDateMonth',
    FcmDateCustom: 'FcmDateCustom',
    VAutoComplete: 'VAutoComplete',
    VSelect: 'VSelect',
    FcmListDialog: 'FcmListDialog'
  },
  configs: {
    type: {
      KNUMBER: 'KNUMBER',
      KSTRING: 'KSTRING',
      KBOOLEAN: 'KBOOLEAN',
      KMONEY: 'KMONEY',
      KSEARCH: 'KSEARCH',
      KDATE: 'KDATE',
      KDATETIME: 'KDATETIME',
      KTIME: 'KTIME',
      KHOURS: 'KHOURS',
      KMINUTES: 'KMINUTES',
      KMONTHYEAR: 'KMONTHYEAR',
      KFUNCTION: 'KFUNCTION',
    }
  }
};
