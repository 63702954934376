// Wraper para o Local Storage, Wraper feito para impedir o safari matar a aplicação ao acessar o storage

let _storageIsEnable = true;

try {
  typeof window.localStorage;
} catch(err:any) {
  _storageIsEnable = false;
  console.error('FcmLocalStorage: Navegador obsoleto ou com os cookies desabilitados, atualize o navegador e ou habilite os cookies', err.message);
}

class _FcmLocalStorage {
  *[Symbol.iterator]() {
    if(_storageIsEnable) {
      // @ts-ignore
      return window.localStorage[Symbol.iterator]();
    }
  }
  key(...args:any[]) {
    if(_storageIsEnable) {
      // @ts-ignore
      return window.localStorage.key(...args);
    }
    return null;
  }
  removeItem(...args:any[]) {
    if(_storageIsEnable) {
      // @ts-ignore
      return window.localStorage.removeItem(...args);
    }
  }
  setItem(...args:any[]) {
    if(_storageIsEnable) {
      // @ts-ignore
      return window.localStorage.setItem(...args);
    }
  }
  getItem(...args:any[]) {
    if(_storageIsEnable) {
      // @ts-ignore
      return window.localStorage.getItem(...args);
    }
    return null;
  }
  clear() {
    if(_storageIsEnable) {
      return window.localStorage.clear();
    }
  }
  get length() {
    if(_storageIsEnable) {
      return window.localStorage.length;
    }
    return 0;
  }


  getObj(key:string) {
    if(_storageIsEnable) {
      try {
        const tmp = window.localStorage.getItem(key);
        if (tmp) {
          return JSON.parse(tmp);
        }
        return null;
      } catch(err) {
        return window.localStorage.getItem(key) || null;
      }
    }
    return null;
  }
  setObj(key: string, obj:any) {
    if(_storageIsEnable) {
      window.localStorage.setItem(key, JSON.stringify(obj, function(k, v) {
        if (typeof v === 'object' && v && (v.wa === 'FieldValue.serverTimestamp' || v.fa === 'FieldValue.serverTimestamp') || k === 'updatedAt') {
          return null;
        }
        return v;
      }));
    }
  }
  storageIsEnable() {
    return _storageIsEnable;
  }
}
//
// const handler = {
//   get: function(target, name) {
//     if(_storageIsEnable) {
//       return window.localStorage[name];
//     }
//     return undefined;
//   }
// };
// export const FcmLocalStorage = new Proxy(new _FcmLocalStorage(), handler);
export const FcmLocalStorage:_FcmLocalStorage = new _FcmLocalStorage();




