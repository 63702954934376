<script setup lang="ts">
import {useLayout} from '@/layouts/composables/layout';
import {useAuthStore} from '@/stores/auth';
import {Workspace} from '@common/AuthUtils';

const {layoutState} = useLayout();
const authStore = useAuthStore();

function logout() {
  layoutState.profileSidebarVisible.value = false;
  authStore.logOut();
}

</script>

<template>
  <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right"
           class="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">Bem vindo</span>
      <span class="text-color-secondary font-medium mb-5">{{authStore.currentUser?.name}}</span>
      <ul class="list-none m-0 p-0">
        <li>
          <RouterLink
            v-if="authStore.currentWorkspace===Workspace.CLIENT"
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            :to="`/client/users/${authStore.currentUser?.id}`"
          >
              <span>
                  <i class="pi pi-user text-xl text-primary"></i>
              </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Perfil</span>
            </div>
          </RouterLink>
        </li>
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click="logout()"
            >
                <span>
                    <i class="pi pi-power-off text-xl text-primary"></i>
                </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Sair</span>
            </div>
          </a>
        </li>
      </ul>
    </div>


  </Sidebar>
</template>
