'use strict';

export class FcmEvent {
  status = 'pending'; // 'pending' || 'resolved' || 'rejected'
  promise: Promise<void>|null = null;
  timeout:number;
  timeoutClear: any = null;
  countResolve = 0;
  count = 0;
  errorCode = ''; // 'unknown' || 'timeout'

  rejectedFn:any = null;
  resolvedFn:any = null;
  fns = [];
  firstStart = true;

  constructor(timeout=30000) {
    this.timeout = timeout;
    this.countResolve = 0;
    this.count = 0;
  }
  getPromise() {
    if(this.status==='resolved') {
      return Promise.resolve();
    }
    else if(this.status==='rejected') {
      return Promise.reject(new Error());
    }
    else {
      if(!this.promise) {
        this.promise = new Promise(this._promisesFunction.bind(this));
        // console.error('Promise não encontrada, isso não deveria acontecer');
        // return null;
      }
      return this.promise;
    }
  }
  reject(_err?:any) {
    this._rejectPromises();
  }
  resolve() {
    this._resolvePromises();
  }

  startTimer() {
    this.count = 0;
    // if(!this.firstStart) {
    // //  this.rejectedFn?.();
    // //  this.promise = new Promise(this._promisesFunction.bind(this));
    //   if(!this.promise) {
    //    // this.promise = new Promise(this._promisesFunction.bind(this));
    //   }
    // }

    if(this.promise) {
      this.reject();
    }
    this.promise = null;
    this.firstStart = false;
    this.status = 'pending';

    if (this.timeout > 0) {
      if(this.timeoutClear) {
        clearTimeout(this.timeoutClear);
      }
      this.timeoutClear = setTimeout(this._rejectPromises, this.timeout);
    }
  }

  setCountResolve(count:number) {
    this.countResolve = count;
  }

  _promisesFunction(resolve:any, reject:any) {
    this.resolvedFn = resolve;
    this.rejectedFn = reject;
  //  this.promise = null;
  }

  _rejectPromises() {
    if (this.status === 'pending') {
      console.warn('FcmEvent Timeout countResolve: ' + this.countResolve + ' count: ' + this.count);
      this.status = 'rejected';
      this.rejectedFn?.();
      this.resolvedFn = null;
      this.rejectedFn = null;
      this.promise = null;
    }
  }
  _resolvePromises() {
    if(this.status==='pending') {
      if(this.countResolve===0 || this.countResolve<=(++this.count)) {
        this.status = 'resolved';
        if(this.timeoutClear) {
          clearTimeout(this.timeoutClear);
        }
        this.resolvedFn?.();
        this.resolvedFn = null;
        this.rejectedFn = null;
        this.promise = null;
      }
    }
  }
}
