/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';


// import 'vuetify/styles';
import '@/design/vuetify.scss';
import { pt, en } from 'vuetify/locale';
// import {FcmLocalStorage} from '@/utils/FcmLocalStorage';
import { createVuetify } from 'vuetify';
import {themes} from '@/design/themes';
import {useLayout} from '@/layouts/composables/layout';
import { Scroll, Ripple, Resize } from 'vuetify/directives';

// function getIsDark() {
//   const isDark = typeof window!=='undefined'?FcmLocalStorage.getItem('darkMode'):null;
//   if(isDark==='true') {
//     return true;
//   }
//   if(isDark==='false') {
//     return false;
//   }
//   return true; // Setando dark mode como default
//   // isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
//   // return !!isDark;
// }


const layout = useLayout();

console.log('Start Vuetify', layout.isDarkTheme);

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export const vuetify =  createVuetify({
  locale: {
    messages: { pt, en },
    locale: 'pt',
    fallback: 'pt'
  },
  // theme: {
  //   defaultTheme: 'dark'
  // },
  theme: {
    defaultTheme: layout.isDarkTheme.value?'dark':'light',
    themes: themes
  },
  directives: {
    Ripple,
    Scroll,
    Resize
  }
});
