<script setup lang="ts">
import {useLayout} from '@/layouts/composables/layout';
import { watch, ref } from 'vue';

export interface Props {
  src?: any
  alt?: string,
}

const props = withDefaults(defineProps<Props>(), {
  src: null,
  alt: ''
});

const srcDisplay = ref<any>(null);
const pSrc = ref<any>(props.src);

const layout = useLayout();

async function defineSrc() {
  let src = null;
  if(props.src && typeof props.src==='object') {
    if(layout.isDarkTheme.value && props.src.dark) {
      src = props.src.dark;
    }
    else if(!layout.isDarkTheme.value && props.src.light) {
      src = props.src.light;
    }
    else if(props.src.url) {
      src = props.src.url;
    }
  }
  if(!src && props.src) {
    src = props.src.light || props.src.dark || props.src.url || props.src;
  }
  if (src instanceof Promise) {
    srcDisplay.value = (await src).default;
  }
  else {
    srcDisplay.value = src;
  }
}

const promiseDefine = defineSrc();
watch([pSrc, layout.isDarkTheme], async () => {
  await promiseDefine;
  console.log('eae', layout.isDarkTheme.value);
  await defineSrc();
});

</script>
<template>
  <img :src="srcDisplay" :alt="alt" v-bind="$attrs">
</template>


/*
  img: {
    dark: 'http://algodfddsf',
    light: 'http://algodfddsf',
    url: 'http://algodfddsf',
  }
*/
















