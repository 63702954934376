<script setup lang="ts">
import AppMenu from './AppMenu.vue';
import {useLayout} from '@/layouts/composables/layout';
import VolanBtn from '@/components/base/VolanBtn.vue';
import {useRoute, useRouter} from 'vue-router';
import {defaultRoutes, Layout, Workspace} from '@common/AuthUtils';
import {useAuthStore} from '@/stores/auth';

const {layoutState} = useLayout();
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

let timeout: NodeJS.Timeout | null = null;

const onMouseEnter = () => {
  if (!layoutState.anchored.value) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    layoutState.sidebarActive.value = true;
  }
};

const onMouseLeave = () => {
  if (!layoutState.anchored.value) {
    if (!timeout) {
      timeout = setTimeout(() => (layoutState.sidebarActive.value = false), 300);
    }
  }
};

// const anchor = () => {
//   layoutState.anchored.value = !layoutState.anchored.value;
// };

const swapWorkspaceSuperAdmin = () => {
  if (route.meta.layout === Workspace.ADMIN) {
    if (authStore.currentCompanyId) {
      router.push({name: defaultRoutes.routeLogged[Workspace.CLIENT]});
    }
    else {
      router.push({name: defaultRoutes.companySelect[Workspace.ADMIN]});
    }
  } else {
    router.push({name: defaultRoutes.routeLogged[Workspace.ADMIN]});
  }
};

function changeCompany() {
  // if (authStore.isSuperAdminAccess) {
  //
  // }
  if(authStore.currentWorkspace===Workspace.CLIENT) {
    router.push({ name: 'client/selectCompany' });
  }
  else if(authStore.currentWorkspace===Workspace.ADMIN) {
    router.push({ name: 'admin/companies' });
  }
}

</script>

<template>
  <div class="layout-sidebar" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="sidebar-header">
      <RouterLink :to="{ name: 'admin/dashboard' }" class="app-logo">
        <VolanImg
          src="/img/icons/logo-main.png"
          alt="Logo"
          class="imgNavbar"
        />
      </RouterLink>
      <VolanBtn v-if="authStore.currentWorkspace===Workspace.ADMIN" @click="swapWorkspaceSuperAdmin()">
        {{route.meta.layout === Layout.ADMIN ? $t('menu.changeSuperAdminToCompany') : $t('menu.changeSuperAdminToAdmin') }}
      </VolanBtn>
      <VolanBtn class="mt-2" @click="changeCompany()">
        {{authStore.currentCompanyId? authStore.currentCompany?.name : 'Selecionar Empresa' }}
        <VIcon class="ml-2">mdi-cached</VIcon>
      </VolanBtn>
<!--      <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" @click="anchor()"></button>-->
    </div>
    <div class="layout-menu-container">
      <AppMenu/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.imgNavbar {
  max-height: 28px;
  max-width: 100%;
  margin-top: 26px;
  margin-bottom: 26px;
}

</style>
