'use strict';

import {BaseEntity} from './BaseEntity';


export class CompanyUserEntity extends BaseEntity {
  active = true;
  hasAccess = true;
  isEmployee = true;
  canSpeakWithOwner = true;
  isAmbassador = false;
  // channelListIds: string[] = [];

  /**
   *
   * @param {CompanyUserEntity|object?} obj
   */
  constructor(obj?:CompanyUserEntity|any) {
    super(obj);
    this.setFcm(obj);
  }

  example() {
    this.setFcm({
    });
    return this;
  }
}

CompanyUserEntity._defaults = new CompanyUserEntity();

export const newCompanyUser = {
  active: true,
  hasAccess: true,
  isEmployee: true,
};

export const defaultCompanyUser = {
  active: true,
  hasAccess: false,
  isEmployee: false,
};





