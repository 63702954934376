// Pinia Store
import { defineStore } from 'pinia';
import {FcmEvent} from '@/utils/FcmEvent';
import {FcmCacheController} from '@/utils/caches/FcmCacheController';
import {eventHub} from '@/eventHub';
import {useAuthStore} from './auth';

const cacheController = new FcmCacheController();

interface State {
  users: any[],
  adminCompanies: any[],
}
export const cacheLoadedObj = new FcmEvent(30000);
// export const cacheLoaded = cacheLoadedObj.promise;


export function getCacheLoaded() {
  return cacheLoadedObj.getPromise();
}

cacheLoadedObj.getPromise().then(()=>{
  console.log('CacheLoaded Loaded');
}).catch(()=>{});

export const useCacheStore = defineStore('cache', {
  // convert to a function
  state: (): State => ({
    users: [],
    adminCompanies: [],
  }),
  getters: {
    getterActiveAdminCompanies(state) {
      return state.adminCompanies.filter(a=>a.active);
    }
  },
  actions: {
    init() {
      cacheController.init(this);
      eventHub.on('auth:changeCompany', (arg:any)=>{
        this.changeCompany();
      });
      eventHub.on('auth:changeUser', (arg:any)=>{
        this.changeUser();
      });
    },

    async changeCompany() {
      const authStore = useAuthStore();
      const companyId = authStore.currentCompanyId;
      const matrizId = authStore.currentCompanyMatrizId;
      const filialHiddenMatrizData = authStore.currentCompany?.flags?.filialHiddenMatrizData || false;
      if(companyId===null) {
        // await cacheController.clearCachesCompanies();
        await cacheController.startCompanyCaches(null, null, false, null);
      }
      else {
        await cacheController.startCompanyCaches(companyId, matrizId, filialHiddenMatrizData, authStore.currentCompany);
      }
      return 0;
    },
    async changeUser() {
      const authStore = useAuthStore();
      await cacheController.startUserCaches(authStore.currentUser?.id || null,
        (authStore.isSuperAdminAccess || authStore.currentWorkspace==='ADMIN' || false));
    },

    stopCache() {
      this.initCompany();
      this.initApp();
      this.initUser();
    },


    // antigas mutations
    initCompany() {
      this.users = [];
    },
    initApp() {
      // this.procedures = [];
    },
    initUser() {
      this.adminCompanies = [];
    },
    addItem({value, cacheName}:any) {
      // @ts-ignore
      const c = this[cacheName];
      const key = c.findIndex((a:any)=>a.id===value.id);
      if(key>=0) {
        c.splice(key, 1, value);
      }
      else {
        const key2 = c.findIndex((a:any)=>a.search>value.search);
        if(key2>0) {
          c.splice(key2-1, 0, value);
        }
        else {
          c.push(value);
        }
      }
    },
    addItems({values, cacheName}:any) {
      // @ts-ignore
      const c = this[cacheName];
      if(c.length===0) {
        values.sort(function(a:any, b:any) {
          if (a.search > b.search) {
            return 1;
          }
          if (a.search < b.search) {
            return -1;
          }
          return 0;
        });
        // for(const value of values) { // fazer dessa forma é muito, muito lento
        //   c.push(value);
        // }
        // @ts-ignore
        this[cacheName] = values;
      }
      else if(values?.length>5) { // Melhorar desempenho quando houver muitos itens atualizados
        const cNew = c.slice();
        for(const value of values) {
          const key = cNew.findIndex((a:any)=>a.id===value.id);
          if(key>=0) {
            cNew.splice(key, 1, value);
          }
          else {
            const key2 = cNew.findIndex((a:any)=>a.search>value.search);
            if(key2>0) {
              cNew.splice(key2-1, 0, value);
            }
            else {
              cNew.push(value);
            }
          }
        }
        // @ts-ignore
        this[cacheName] = cNew;
      }
      else {
        for(const value of values) {
          const key = c.findIndex((a:any)=>a.id===value.id);
          if(key>=0) {
            c.splice(key, 1, value);
          }
          else {
            const key2 = c.findIndex((a:any)=>a.search>value.search);
            if(key2>0) {
              c.splice(key2-1, 0, value);
            }
            else {
              c.push(value);
            }
          }
        }
      }
    },

    addItemNotSort({value, cacheName}:any) {
      // @ts-ignore
      const c = this[cacheName];
      const key = c.findIndex((a:any)=>a.id===value.id);
      if(key>=0) {
        c.splice(key, 1, value);
      }
      else {
        c.push(value);
      }
    },
    addItemsNotSort({values, cacheName}:any) {
      // @ts-ignore
      const c = this[cacheName];
      if(c.length===0) {
        for(const value of values) {
          c.push(value);
        }
      }
      else {
        for(const value of values) {
          const key = c.findIndex((a:any)=>a.id===value.id);
          if(key>=0) {
            c.splice(key, 1, value);
          }
          else {
            c.push(value);
          }
        }
      }

    },
    removeItems({values, cacheName}:any) {
      for(const value of values) {
        // @ts-ignore
        const key = this[cacheName].findIndex((a:any) => a.id === value.id);
        if (key >= 0) {
          // @ts-ignore
          this[cacheName].splice(key, 1);
        }
      }
    },

    removeItem({value, cacheName}:any) {
      // @ts-ignore
      const key = this[cacheName].findIndex((a:any)=>a.id===value.id);
      if(key>=0) {
        // @ts-ignore
        this[cacheName].splice(key, 1);
      }
    },


  }
});
