'use strict';

import {cloneDeep} from 'lodash-es';
import {BaseEntity} from './BaseEntity';
import {CompanyUserEntity} from './CompanyUserEntity';
import {deburr, deburrCpf} from '@common/StringUtils';

export class UserEntity extends BaseEntity {
  id = '';
  active = true;
  name = '';
  nameLowerCase = '';
  email = '';
  gender = '';
  hasAuth = false;
  birthDateISO = '';
  authsIds: string[] = [];
  birthDateTimestamp:any = null; // computado
  /**
   * @type {CompanyUserEntity|object|undefined}
   */
  _companyUser:CompanyUserEntity|any = null;
  /**
   * @type {Object.<string, CompanyUserEntity>|object}
   */
  companiesUser:{[key: string]: CompanyUserEntity}|{[key: string]: any} = {};
  companiesAccessIds: string[] = [];
  meta = {};
  _companyId:string|null = null;
  cpf = '';

  // static EntityInfos = {
  //   attrs = [
  //     setField('id', null, Type.String, null),
  //     setField('active', null, Type.Boolean, null)
  //   ]
  // }

  /**
   *
   * @param {UserEntity|object?} obj
   * @param {string?} currentCompanyId
   */
  constructor(obj?:any|UserEntity, currentCompanyId?:string) {
    super(obj);
    this.setFcm(obj);
    if (currentCompanyId) {
      if (this.companiesUser?.[currentCompanyId]) {
        this._companyUser = new CompanyUserEntity(this.companiesUser[currentCompanyId]);
        this._companyId = currentCompanyId;
      }
    }
  }

  fixComputed() {
    this.types = [];
    this.userBoardsResumes = [];
    this.companiesAccessIds = [];
    this.companyIds = [];
    for (const cId in this.companiesUser || {}) {
      const compUser = this.companiesUser[cId];
      if (this.hasAuth && compUser.active && compUser.isEmployee) {
        this.companiesAccessIds.push(cId);
      }
      if (compUser.active) {
        this.companyIds.push(cId);
      }
    }
    this.nameLowerCase = deburr(this.name);
    this.birthDateTimestamp = this.birthDateISO?new Date(this.birthDateISO):null;
    this.cpf = deburrCpf(this.cpf);
    this.active = Boolean(this.active);
  }

  example() {
    this.setFcm({
    });
    return this;
  }

  getFullMutation(key = 'id') {
    if (!this.batchesIds) {
      this.batchesIds = [];
    }
    return super.getFullMutation(key);
  }


  getCompanyUser(companyId?:string, matrizId?:string) {
    return getCompanyUser(this, companyId, matrizId);
  }

}

/**
 *
 * @param user
 * @param companyId
 * @param matrizId
 * @returns {CompanyUserEntity|null}
 */
export function getCompanyUser(user:any, companyId?:string|null, matrizId?:string|null) {
  if (companyId) {
    if (matrizId) {
      return user.companiesUser?.[matrizId] || null;
    }
    else {
      return user.companiesUser?.[companyId] || null;
    }
  }
}


export const defaultUser = {
  active: false,
  types: [],
  companyAndFiliaisIds: [],
  companiesUser: {},
};


export function getUserAuth(d:any, companyId?:string|null, matrizId?:string|null) {
  if (!d) {
    return null;
  }
  const user = cloneDeep(d);

  user._companyUser = null;
  user._companiesActives = [];
  if (companyId) {
    if (matrizId) {
      user._companyUser = user?.companiesUser?.[matrizId] || null;
      user._companyId = companyId;
      user.matrizId = companyId;
    }
    else {
      user._companyUser = user?.companiesUser?.[companyId] || null;
      user._companyId = companyId;
    }
  }
  return user;
}



UserEntity._defaults = new UserEntity();





