import _ from 'lodash';
import {cacheLoadedObj} from '@/stores/caches';
import type {FcmCacheController} from '@/utils/caches/FcmCacheController';
import {firestore, Timestamp} from '@/db';
import {traverse2FirestoreDate, transverse2Date} from '@common/ObjectUtils';
import {delay} from '@common/TimeUtils';

export const FcmLocalCache = {
  APP: 'APP',
  COMPANY: 'COMPANY',
  USER: 'USER'
};
export const FcmTypeCache = {
  DIRECT: 'DIRECT',
  FRAGMENT: 'FRAGMENT'
};
export const FcmStatusCache = {
  STOPPED: 'STOPPED',
  RUNNING: 'RUNNING',
};

export const LIMIT_CACHE_DEV = 9000;
export const CACHE_DB_VERSION = 59;


export class FcmItemCache {
  local = FcmLocalCache.APP;
  status = FcmStatusCache.STOPPED;
  unsubscribe:any = null;
  name:string;
  ref:any= null;
  companyId:string|null = null;
  matrizId:string|null = null;
  filialHiddenMatrizData = false;
  preLoader:any = null;
  beforePersist:any = null;
  fcmCacheController:FcmCacheController;
  isFirst= false;
  firstLoadResolve:any = null;
  firstLoadRejected:any = null;
  preRef:any = null;
  indexes = ['companyId'];
  startOnMatriz = false;
  startOnSuperAdmin = false;
  type: string;
  constructor({controller, name, collectionNameOrRef, local=FcmLocalCache.APP, type=FcmTypeCache.DIRECT,
    preLoader=null, indexes = undefined, startOnMatriz = false, startOnSuperAdmin = false,
    beforePersist = null}:
    {controller: FcmCacheController, name:string, collectionNameOrRef:any, local?:string, type?:string,
      preLoader?:any, indexes?:any, startOnMatriz?:boolean, startOnSuperAdmin?:boolean,
      beforePersist?:any}) {
    this.local = local;
    this.type = type;
    this.name = name;
    this.fcmCacheController = controller;
    if(typeof collectionNameOrRef==='string') {
      this.ref = firestore.collection(collectionNameOrRef);
    }
    else if(typeof collectionNameOrRef==='object') {
      this.ref = collectionNameOrRef;
    }
    else {
      console.error('FcmItemCache: collectionNameOrRef inválido');
      return;
    }
    if(preLoader) {
      this.preLoader = preLoader;
    }
    else {
      this.preLoader = function(item:any) {
        item.search = _.deburr(item.name||'').toLowerCase().trim();
        return item;
      };
    }
    if (indexes) {
      this.indexes = indexes;
    }
    this.startOnMatriz = !!startOnMatriz;
    this.startOnSuperAdmin = !!startOnSuperAdmin;
  }
  stop() {
    if(typeof this.unsubscribe === 'function') {
      this.unsubscribe();
      this.unsubscribe= null;
    }
    this.status = FcmStatusCache.STOPPED;
  }
  async clearCache() {
    await this.fcmCacheController.fcmIndexedDB.cleanStore(this.name);
  }
  async preStart(companyId=null, matrizId=null, filialHiddenMatrizData=false) {
    if (this.startOnMatriz && !this.fcmCacheController.company?.isMatriz) {
      return false;
    }
    if (this.startOnSuperAdmin && !this.fcmCacheController.isSuperAdminAccess) {
      return false;
    }
    let items = [];
    if(this.local===FcmLocalCache.COMPANY) {
      this.companyId = companyId;
      this.matrizId = matrizId;
      this.filialHiddenMatrizData = !!(this.matrizId && filialHiddenMatrizData);
      items = await this.fcmCacheController.fcmIndexedDB.getAllDataByCompany(this.name, this.companyId, this.matrizId);
      if(this.filialHiddenMatrizData) {
        items.filter((a:any)=>a.companyId===this.companyId);
      }
    }
    else {
      if(this.status === FcmStatusCache.RUNNING) {
        return false;
      }
      items = await this.fcmCacheController.fcmIndexedDB.getAllData(this.name);
    }
    traverse2FirestoreDate(Timestamp, items);
    if(typeof this.preLoader==='function') {
      for(const i in items) {
        items[i] = this.preLoader(items[i]);
      }
    }
    this.status = FcmStatusCache.RUNNING;
    this.fcmCacheController.store.addItems({values: items, cacheName: this.name});

    let greaterDate = {
      seconds: 0,
      nanoseconds: 0,
    };

    for(const p of items) {
      if(p?.meta?.updatedAt && (
        (p.meta.updatedAt.seconds===greaterDate.seconds && p.meta.updatedAt.nanoseconds>greaterDate.nanoseconds)
        ||(p.meta.updatedAt.seconds>greaterDate.seconds))) {
        greaterDate = p.meta.updatedAt;
      }
    }


    let ref = this.ref;
    if(this.name==='anesthetists') {
      ref = ref.where('matrizAndCompanies', 'array-contains', this.companyId);

      // const doc = await ref.get();
      // doc.forEach(doc => {
      //   console.log(doc.data().name);
      //   console.log(doc.data().companyIds);
      // })
    }
    else if(this.name==='users') {
      ref = ref.where('companyAndFiliaisIds', 'array-contains', this.companyId);
    }
    else if(this.name==='filiais') {
      ref = ref.where('matrizId', '==', this.companyId);
    }
    else if(this.local===FcmLocalCache.COMPANY) {
      if(this.matrizId && !this.filialHiddenMatrizData) {
        ref = ref.where('companyId', 'in', [this.companyId, this.matrizId]);
      }
      else {
        ref = ref.where('companyId', '==', this.companyId);
      }
    }
    if(process.env.NODE_ENV !== 'production' && LIMIT_CACHE_DEV) {
      ref = ref.limit(LIMIT_CACHE_DEV);
    }

    if(greaterDate.seconds>0) {
      // ref = ref.where('meta.updatedAt', '>', new Timestamp(greaterDate.seconds, greaterDate.nanoseconds)).orderBy('meta.updatedAt');
      ref = ref.orderBy('meta.updatedAt').startAfter(new Timestamp(greaterDate.seconds, greaterDate.nanoseconds));
    } else {
      console.log('Not found cache: '+this.name);
    }
    this.preRef = ref;
    return true;
  }
  async start(companyId=null, matrizId=null, filialHiddenMatrizData=false, startDelay=0) {
    if (this.startOnMatriz && !this.fcmCacheController.company?.isMatriz) {
      return;
    }
    if (this.startOnSuperAdmin && !this.fcmCacheController.isSuperAdminAccess) {
      return;
    }
    if(!this.preRef) {
      await this.preStart(companyId, matrizId, filialHiddenMatrizData);
    }
    if(!this.preRef) {
      return;
    }
    //    fcmTimer.lap('FcmCacheController FcmItemCache start antes firebase '+this.name, this.name);
    if(startDelay) {
      await delay(startDelay);
    }
    await new Promise((resolve, reject)=>{
      this.firstLoadResolve = resolve;
      this.firstLoadRejected = reject;
      this.isFirst = true;
      try {
        this.unsubscribe= this.preRef.onSnapshot(
          this.onSnapshot.bind(this),
          this.onSnapshotError.bind(this),
          this.onSnapshotConclude.bind(this)
        );
      } catch(err) {
        console.error('Erro ao ligar listener de cache', this.name, this, err);
      }

      this.preRef = null;
    });
    //    fcmTimer.lap('FcmCacheController FcmItemCache start end '+this.name, this.name);
  }
  async onSnapshotError(err:any) {
    console.error('Erro ao ligar listener de cache!', this.name, this, err);
  }
  async onSnapshotConclude() {
    console.log('conclude', this.name);
  }
  async onSnapshot(snapshot:any) {
    const addItems:any[] = [];
    // const removeItems = [];
    snapshot.docChanges().forEach((snap:any)=>{
      if (snap.type === 'added' || snap.type === 'modified') {
        let value = snap.doc.data();
        value.id = snap.doc.id;
        if(typeof this.preLoader==='function') {
          value = this.preLoader(value);
        }
        // if(value) {
        addItems.push(value);
        // }
        // else {
        //   removeItems.push({id: value.id});
        // }

      }
      else if (snap.type === 'removed') {  // Remove items removidos (Obs: removido !== de inativo)
        this.fcmCacheController.store.removeItem({value: {id: snap.doc.id}, cacheName: this.name});
        this.fcmCacheController.fcmIndexedDB.removeData(this.name, {id: snap.doc.id});
      }
      else {
        console.warn('Recebido dads estranhos do firebase', snap);
      }
    });

    if(addItems.length>0) {
      if(typeof this.beforePersist==='function') {
        for(const item of addItems) {
          this.beforePersist(item, addItems, this.fcmCacheController.store[this.name]);
        }
      }

      this.fcmCacheController.store.addItems({values: addItems, cacheName: this.name});
      //  fcmTimer.lap('FcmCacheController FcmItemCache start antes traverseToDate '+this.name, this.name+'_t');
      const addItemsIndexed = _.cloneDeep(addItems);
      transverse2Date(addItemsIndexed);
      //  fcmTimer.lap('FcmCacheController FcmItemCache start depois traverseToDate '+this.name, this.name+'_t');
      await this.fcmCacheController.fcmIndexedDB.putData(this.name, addItemsIndexed);
    }

    // if(removeItems.length>0) { // Remover items inativos do IndexedDB
    //   await this.fcmCacheController.fcmIndexedDB.removeData(this.name, removeItems);
    // }


    if(this.isFirst) {
      this.isFirst = false;
      if(typeof this.firstLoadResolve ==='function') {
        this.firstLoadResolve();
      }
      this.firstLoadResolve = null;
      this.firstLoadRejected = null;
      if(this.local===FcmLocalCache.COMPANY) {
        console.log('resolve', this.name);
        cacheLoadedObj.resolve();
      }
      else {
        console.log('finish', this.name);
      }
    }
    else {
      // if(removeItems.length>0) { // Remove items inativos do vuex
      //   this.fcmCacheController.store.removeItems({values: removeItems, cacheName: this.name})
      // }
    }
  }
}
