// Pinia Store
import { defineStore } from 'pinia';
import {firestore, database} from '@/db';
import {FcmEvent} from '@/utils/FcmEvent';
import {onValue, ref} from 'firebase/database';
let unsubscribeConfigWeb:any = null;
let unsubscribeInfoConnected:any = null;

interface State {
  configWeb: any,
  configApp: any,
  isOnline: boolean,
}

export const configLoadedObj = new FcmEvent(10000);
export function getConfigLoaded() {
  return configLoadedObj.getPromise();
}
configLoadedObj.getPromise().then(()=>{
  console.log('ConfigLoaded Loaded');
});

export const useConfigStore = defineStore('config', {
  // convert to a function
  state: (): State => ({
    configWeb: {},
    configApp: {},
    isOnline: false
  }),
  getters: {
  },
  actions: {
    init() {
      if(unsubscribeConfigWeb) {
        unsubscribeConfigWeb();
      }
      if(unsubscribeInfoConnected) {
        unsubscribeInfoConnected();
      }
      try {
        unsubscribeConfigWeb = firestore.collection('config').doc('web').onSnapshot((snapshot:any) => {
          const value = {...snapshot.data(), id: snapshot.id};
          if(value.minVersion?.version>=parseInt(process.env.VUE_APP_VERSION_NUMBER || '')) {
            if(window.navigator && navigator.serviceWorker) {
              navigator.serviceWorker.getRegistrations()
                .then(function(registrations) {
                  for(const registration of registrations) {
                    registration.unregister?.();
                  }
                });
            }
          }
          this.configWeb = value;
          configLoadedObj.resolve();
        },
        (error:any)=>{
          configLoadedObj.reject(error);
        },
        ()=>{
          // configLoadedObj.resolve();
        });
        unsubscribeInfoConnected = onValue(ref(database, '.info/connected'), (connectedSnap) => {
          this.isOnline = connectedSnap.val() === true;
        });
      } catch(err) {
        console.error('Erro onSnapshot config', err);
      }
    }
  }
});
