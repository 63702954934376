/* eslint no-undef: 0 */
'use strict';

import {deburr as _deburr, cloneDeep} from 'lodash-es';


// function printMatDebug(source, target, mat) {
//   let text = ' \t \t';
//   for (let j = 0; j <= target.length; j++) {
//     text+= (target[j]|| ' ')+'\t';
//   }
//   text+= '\n';
//   for (let i = 0; i <= source.length; i++) {
//     text+= ((i>0?source[i-1]:' ')|| ' ')+'\t';
//     for (let j = 0; j <= target.length; j++) {
//       text+= (mat[i][j] || ' ')+'\t';
//     }
//     text+= '\n';
//   }
//   console.log(text);
// }

// levenshtein alterado para substring, nesse algoritmo a ordem importa
export function fcmLevenshtein(source:string, target:string) {
  const result = []; // This is going to be a matrix. The result we're looking for can be found by flood-filling it.
  let i, j;

  // Let's fill the first line of the matrix with distances from each substring of the source to the empty string.
  for (i = 0; i <= source.length; i++) {
    result.push([i]);
  }

  // Same as above, but for a column. Also, the first element of the first column is already filled with a zero, hence j = 1.
  for (j = 1; j <= target.length; j++) {
    result[0].push(0);
  }

  for (i = 1; i <= source.length; i++) {
    for (j = 1; j <= target.length; j++) {

      // the element in the iteration doesn't exist yet. Let's create it.
      result[i].push(0);

      // Now let's get an edit distance between two characters.
      if (source[i-1] === target[j-1]) {

        // Notice how, for a comparison between two equal strings, the diagonal will be filled with zeroes.
        result[i][j] = result[i-1][j-1];
      } else {

        // Different characters. The formulae for edit distances are shown below.
        let minimum = Math.min(
          result[i-1][j] + 1, // This means we can bring the target string closer to the source one with a character deletion
          result[i][j-1] + 1  // Same as above, but with a character insertion
        );

        minimum = Math.min(
          minimum,
          result[i-1][j-1] + 1 // This means that we can bring the target closer to the source by means of a character change.
        );
        result[i][j] = minimum;
      }
    }
  }

  let fcmMin = target.length;
  for (j = 1; j <= target.length; j++) {
    if(fcmMin>result[source.length][j]) {
      fcmMin = result[source.length][j];
    }
  }
  //  printMatDebug(source, target, result);
  return fcmMin;

  // The actual distance we're looking for is the value stored in the lower right corner of the matrix.
  // return result[source.length][target.length];
}

export function levenshtein(source:string, target:string) {
  const result = []; // This is going to be a matrix. The result we're looking for can be found by flood-filling it.
  let i, j;

  // Let's fill the first line of the matrix with distances from each substring of the source to the empty string.
  for (i = 0; i <= source.length; i++) {
    result.push([i]);
  }

  // Same as above, but for a column. Also, the first element of the first column is already filled with a zero, hence j = 1.
  for (j = 1; j <= target.length; j++) {
    result[0].push(0);
  }

  for (i = 1; i <= source.length; i++) {
    for (j = 1; j <= target.length; j++) {

      // the element in the iteration doesn't exist yet. Let's create it.
      result[i].push(0);

      // Now let's get an edit distance between two characters.
      if (source[i-1] === target[j-1]) {

        // Notice how, for a comparison between two equal strings, the diagonal will be filled with zeroes.
        result[i][j] = result[i-1][j-1];
      } else {

        // Different characters. The formulae for edit distances are shown below.
        let minimum = Math.min(
          result[i-1][j] + 1, // This means we can bring the target string closer to the source one with a character deletion
          result[i][j-1] + 1  // Same as above, but with a character insertion
        );

        minimum = Math.min(
          minimum,
          result[i-1][j-1] + 1 // This means that we can bring the target closer to the source by means of a character change.
        );
        result[i][j] = minimum;
      }
    }
  }

  //  printMatDebug(source, target, result);
  // The actual distance we're looking for is the value stored in the lower right corner of the matrix.
  return result[source.length][target.length];
}



// É esperado as strings em lowercase e sem espaço no inicio e no fim
// let score = stringUtils.fcmlevenshteinNormalizeScore('start Buc', 'cafe starbucks - brasil');
// source.toLowerCase().trim();
export function fcmlevenshteinNormalizeScore(source:string, target:string) {
  if(!source) {return 0;}
  if(!target) {return 0;}
  let cont = 0;
  let scoreSearch = source.split(' ').reduce(function(o:number, term:string) {
    if(!term) {
      return o;
    }
    cont++;
    return o+(1-(fcmLevenshtein(term, target)/term.length));
  }, 0);
  scoreSearch /= cont || 1;
  return scoreSearch;
}

export function fcmlevenshteinNormalizeScoreOrdered(source:string, target:string) {
  if(!source) {return 0;}
  if(!target) {return 0;}
  return 1-(fcmLevenshtein(source, target)/Math.min(source.length,target.length));
}

export function levenshteinNormalizeScoreOrdered(source:string, target:string) {
  if(!source) {return 0;}
  if(!target) {return 0;}
  return 1-(levenshtein(source, target)/Math.min(source.length,target.length));
}


export function toTitleCase(phrase:string) {
  if(!phrase) {return '';}
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word:string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}


export function username(phrase:string) {
  if (!phrase) {
    return '';
  }
  phrase =_deburr(phrase);

  return phrase.replace(/[^A-Za-z\d_-]/g, '');
}

export function deburr(txt:string) {
  return _deburr(txt||'').toLowerCase().trim();
}

export function deburrCase(txt:string) {
  return _deburr(txt||'').trim();
}

export function deburrMatchOcr(txt:string) {
  return _deburr(txt||'').toLowerCase().trim();
}

export function deburrSlug(txt:string) {
  return (txt ||'').replace(/[^\dA-Za-z\-\/_]/g, '').trim();
}

export function deburrCpf(txt:string) {
  return (txt||'').replace(/[^\d]/g, '').trim();
}


export function clearName(txt:string) {
  // @ts-ignore
  return String(txt || '').replaceAll('\t\n\r', '').trim();
}

export function maskCPF(text:string) {
  const t = deburrCpf(text||'');
  return `${t[0]||''}${t[1]||''}${t[2]||''}.${t[3]||''}${t[4]||''}${t[5]||''}.${t[6]||''}${t[7]||''}${t[8]||''}-${t[9]||''}${t[10]||''}`;
}

export function getSearch(array:string[]) {
  return deburr(array.filter(a=>!!a).join(' '));
}

export function shuffle(input:string) {
  const a = input.split(''),
    n = a.length;

  for(let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join('');
}


export function getFragment(key:any, numberOfFragments:number) {
  let sum = 0;
  for(const i in key) {
    sum+=(key.charCodeAt(i)||0);
  }
  return sum%numberOfFragments;
}

export function getFragmentMult(key:any, numberOfFragments:number) {
  let sum = 0;
  for(const i in key) {
    // @ts-ignore
    sum+=(key.charCodeAt(i)||0)*i*21;
  }
  return sum%numberOfFragments;
}

export function validateCpf(value:string) {
  if (!value) {
    return true;
  }
  value = deburrCpf(value);

  let sum;
  let rest;
  let returnInvalid = false;
  sum = 0;
  if (value === '00000000000')
    returnInvalid = true;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))
    rest = 0;
  if (rest !== parseInt(value.substring(9, 10)))
    returnInvalid = true;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))
    rest = 0;
  if (rest !== parseInt(value.substring(10, 11)))
    returnInvalid = true;

  return !returnInvalid;
}

export function fallbackCopyTextToClipboard(text:string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export async function copyTextToClipboard(text:string) {
  if(typeof navigator==='undefined') {
    console.error('Async: Could not copy text!');
    return false;
  }
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch(err) {
    console.error('Async: Could not copy text: ', err);
  }
  return false;
}

export function json2csv(items:any) {
  let ret = '';
  for(const row of items) {
    for(const itemIndex in row) {
      let value = row[itemIndex];
      if(typeof value==='string') {
        value = '"'+value+'"';
      }
      ret += value + ';';
    }
    ret += '\n';
  }
  return ret;
}


export function json2csv2(items:string[], header:string[], replacer:any) {
  if(!replacer) {
    replacer = (key:string, value:string) => value === null ? '' : (typeof value!=='string'?value:value.replace('"', '\''));
  }
  return [
    header.join(','), // header row first
    ...items.map((row:any) => header.map((fieldName:string) => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n');
}


export function makeId(length:number) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



export function processNodeHtml(node:any, result:any, limit=10) {
  for(const el of node.childNodes || []) {
    if(el.nodeName==='#text' || el.nodeName==='#TEXT') {
      result.words += el.data?.split(' ')?.length || 0;
    }
    if(el.nodeName.startsWith('H') || el.nodeName.startsWith('h')) {
      const slug = deburrSlug(el.textContent)+ '_title';
      let sufix = '';
      if(result.slugs[slug]) {
        result.slugs[slug]++;
        sufix = result.slugs[slug];
      }
      else {
        result.slugs[slug] = 1;
      }
      if(!el.id) {
        el.id = slug+sufix;
      }
      result.topics.push({
        label: el.innerHTML,
        id: slug+sufix
      });
    }
    if(limit>0) {
      processNodeHtml(el, result, limit-1);
    }
  }
}

export function processHtml(html:string, result:any) {
  const div = document.createElement('div');
  div.innerHTML = html?.trim() || '';
  processNodeHtml(div, result);
}

function _upperCaseStrings(aux:any, upper:any, keys:any) {
  if (typeof aux === 'string' && upper) {
    return aux.toUpperCase();
  } else if (typeof aux === 'object') {
    for (const index2 in aux) {
      aux[index2] = _upperCaseStrings(aux[index2], !!keys[index2], keys);
    }
  }
  return aux;
}

export function upperCaseStrings(form:any, keys:any={}) {
  if (typeof form === 'string') {
    return form.toUpperCase();
  }
  let aux = cloneDeep(form);

  aux = _upperCaseStrings(aux, false, keys);
  return aux;
}
