import {cacheLoadedObj} from '@/stores/caches';
import {FcmItemCache, FcmLocalCache, CACHE_DB_VERSION} from './FcmItemCache';
import {getSearch} from '@common/StringUtils';
import {FcmIndexedDB} from '@/utils/FcmIndexedDB';

export class FcmCacheController {
  store:any = null;
  /**
   *
   * @type {Object.<string, FcmItemCache>}
   */
  caches:any = {};
  companyId:string|null = null;
  matrizId:string|null = null;
  company:any = null;
  filialHiddenMatrizData = false;
  appCachesInitialized = false;
  fcmIndexedDB:any = null;
  userId:string|null = null;
  isSuperAdminAccess = false;
  constructor() {
    this.caches = {
      users: new FcmItemCache({
        controller: this,
        name: 'users',
        collectionNameOrRef: 'users',
        local: FcmLocalCache.COMPANY,
        indexes: ['_companyId'],
        preLoader: (item:any) => {
          item._companyId = this.companyId;
          // @ts-ignore
          item._companyUser = item.companiesUser?.[this.companyId] || {};
          const searchList = [item.name, item._companyUser.acronym, item.email, item.id, item.document];
          item._boardInfo = '';
          for (const ub of item._companyUser.userBoardInfos || []) {
            searchList.push(ub.boardNumber);
            item._boardInfo += `${ub.boardNumber} ${ub.region} `;
          }
          item.search = getSearch(searchList);
          return item;
        }
      }),
      adminCompanies: new FcmItemCache({
        controller: this,
        name: 'adminCompanies',
        startOnSuperAdmin: true,
        collectionNameOrRef: 'companies',
        local: FcmLocalCache.USER,
        preLoader: (item:any) => {
          item.search = getSearch([item.name, item.cnpj, item.nickName, item.id]);
          return item;
        },
        beforePersist: (item:any, addItems:any, previousItems:any) => {
          if(item.matrizId) {
            item._matrizName = addItems.find((a:any)=>a.id===item.matrizId)?.name || '';
            if (item._matrizName) {
              item._matrizName = previousItems.find((a:any)=>a.id===item.matrizId)?.name || '';
            }
          }
        }
      }),
    };
    this.initBD();
    if(process.env.NODE_ENV !== 'production') {
      // console.log('Cache de apenas '+LIMIT_CACHE_DEV+' itens de cada cache em desenvolvimento');
    }
    // let count = 0;
    // for(const i in this.caches) {
    //   if(this.caches[i].local===FcmLocalCache.COMPANY) {
    //     count++;
    //   }
    // }
    // cacheLoadedObj.setCountResolve(count);
  }
  initBD() {
    this.fcmIndexedDB = new FcmIndexedDB('FcmJwCacheController', CACHE_DB_VERSION, this.caches);
  }
  init(store:any) {
    this.store = store;
  }
  async clearCaches() {
    console.log('clearCaches');
    for(const i in this.caches) {
      await this.caches[i].clearCache();
    }
  }
  async clearCachesCompanies() {
    await this.store.initCompany();
    console.log('clearCaches');
    for(const i in this.caches) {
      if(this.caches[i].local===FcmLocalCache.COMPANY) {
        await this.caches[i].clearCache();
      }
    }

  }
  async startCompanyCaches(companyId:string|null=null, matrizId:string|null=null, filialHiddenMatrizData=false, company=null) {
    console.log('startCompanyCaches');
    if(!matrizId && filialHiddenMatrizData) {
      this.filialHiddenMatrizData = false;
      console.warn('Inconsistência encontrada !matrizId and filialHiddenMatrizData');
    }
    this.company = company;
    if(this.companyId===companyId && this.matrizId===matrizId && this.filialHiddenMatrizData===filialHiddenMatrizData) {
      console.log('same company');
      return;
    }
    if(this.companyId) {
      cacheLoadedObj.startTimer();
    }
    //    fcmTimer.lap('FcmCacheController startCompanyCaches ini', 'startCompanyCaches');
    this.stopItemCaches(FcmLocalCache.COMPANY);
    if(this.companyId || this.matrizId) {
      await this.clearCachesCompanies();
    }
    this.companyId = companyId;
    this.matrizId = matrizId;
    this.filialHiddenMatrizData = filialHiddenMatrizData;

    await this.fcmIndexedDB.putData('config', {
      id: 'config',
      date: new Date(),
      companyId,
      matrizId,
      filialHiddenMatrizData,
      isMatriz: this.company?.isMatriz || false
    });


    const promises = [];
    if(this.companyId)  {
      for(const i in this.caches) {
        if(this.caches[i].local===FcmLocalCache.COMPANY) {
          promises.push(this.caches[i].preStart(this.companyId, this.matrizId, this.filialHiddenMatrizData));
        }
      }
      const resultCachesPreStart = await Promise.all(promises);
      let count = 0;
      for(const result of resultCachesPreStart) {
        if(result) {
          count++;
        }
      }
      cacheLoadedObj.setCountResolve(count);

      //      fcmTimer.lap('FcmCacheController startCompanyCaches finish precaches', 'startCompanyCaches');
      for(const i in this.caches) {
        if(this.caches[i].local===FcmLocalCache.COMPANY) {
          await this.caches[i].start(this.companyId, this.matrizId, this.filialHiddenMatrizData);
        }
      }

    }

    //    fcmTimer.lap('FcmCacheController startCompanyCaches end', 'startCompanyCaches');
  }
  async startAppCaches() {
    if(this.appCachesInitialized) {
      return;
    }
    this.stopAppCaches();
    cacheLoadedObj.startTimer();
    for(const i in this.caches) {
      if(this.caches[i].local===FcmLocalCache.APP) {
        await this.caches[i].start();
      }
    }
  }
  stopAppCaches() {
    if(this.appCachesInitialized) {
      return;
    }
    this.stopItemCaches(FcmLocalCache.APP);
    this.appCachesInitialized = true;
    this.store.initApp();
  }
  stopItemCaches(local:string) {
    for(const i in this.caches) {
      if(this.caches[i].local===local) {
        this.caches[i].stop();
      }
    }
  }

  async startUserCaches(userId=null, isSuperAdminAccess=false) {
    if(this.userId===userId && this.isSuperAdminAccess===isSuperAdminAccess) {
      return;
    }
    // await this.stopUserCaches();
    this.userId = userId;
    this.isSuperAdminAccess = isSuperAdminAccess;
    this.stopItemCaches(FcmLocalCache.USER);
    for(const i in this.caches) {
      if(this.caches[i].local===FcmLocalCache.USER) {
        await this.caches[i].start();
      }
    }
  }
  // async stopUserCaches() {
  //   this.stopItemCaches(FcmLocalCache.USER);
  //   for(const i in this.caches) {
  //     if(this.caches[i].local===FcmLocalCache.USER) {
  //       await this.caches[i].clearCache();
  //     }
  //   }
  //   this.userId = null;
  //   this.isSuperAdminAccess = false;
  //   this.store.commit('initUser');
  // }
}
