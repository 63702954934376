import moment from 'moment';
import {firestore, FieldValue, functions} from '@/db';
import {eventHub} from '@/eventHub';
import {Workspace} from '@common/AuthUtils';
import type { App, Plugin } from 'vue';
import {i18n} from '@/plugins/i18n';

// import {i18n} from '@/plugins/i18n';
import { useAuthStore } from '@/stores/auth';


// import {getNewSequential} from '@services/Sequentail';
// import {getNewSequentialMulti} from '@/services/Sequentail';

import {translateDb, i18nConfig, languages} from '@common/i18n';
import * as StringUtils from '@common/StringUtils';
import * as status from '@common/status';
import {deburr} from 'lodash-es';

// TODO temporario para compatibilidade
import _ from 'lodash';




type MsgBase = {
  arg: string|{title?: string, text?: string, timer?: number}
}

type Confirm = {
  arg: string|{title?: string, text?: string, confirmButtonText?: string, cancelButtonText?: string}
}

type Prompt = {
  arg: string|{title?: string, text?: string, confirmButtonText?: string, cancelButtonText?: string,
    textMessageOnRequired?: string, required?: boolean, inputValue?: any, inputAttributes?: any, input?:string}
}

export const GlobalPlugin: Plugin = {
  install(app: App, options: any) {
    const authStore = useAuthStore();
    // const i18n = useI18n();
    /*
    app.mixin({
      beforeDestroy: function () {
        console.log('unsubscribe function', app.config.globalProperties.$fcmUnsubscriber);
        // for(let us of app.config.globalProperties.$fcmUnsubscriber) {
        //   if(typeof us === 'function') {
        //     us();
        //   }
        // }
      //  app.config.globalProperties.$fcmUnsubscriber = [];
      }
    });
    app.config.globalProperties.$fcmUnsubscriber = [];
    */

    /*
    if(typeof window!=='undefined' && window.visualViewport) {
      const listener = () => {
        const MIN_KEYBOARD_HEIGHT = 300
        const isMobile = window.innerWidth < 768
        const isKeyboardOpen = isMobile && window.innerHeight<768
          && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
      }
      window.visualViewport.addEventListener('resize', listener);
    }
     */

    app.mixin({
      computed: {
        $isDark: function():boolean {
          // @ts-ignore
          return !!this.$vuetify?.theme?.dark;
        },
      }
    });

    app.config.globalProperties.$eventHub = eventHub; // Global event bus

    //  {{$jwZeroFill(tr.value, 5)}}       old: {{tr.date | jwZeroFill(5)}}
    app.config.globalProperties.$jwZeroFill = function(value:any, length=12) {
      if(!value) value = '';
      return value.toString().padStart(length, '0');
    };

    // {{$jwGetDate(tr.date)}}
    // {{$jwGetDate(tr.date, 'DD MM YYYY')}}   old: {{tr.date | jwToDate('DD MM YYYY')}}
    app.config.globalProperties.$jwGetDate = function(value:any, format:string ='') {
      if (!value) {
        return '';
      }
      if(!format) {
        format = i18n.global.t('format.date');
      }
      if(typeof value?.toDate==='function') {
        const v = moment(value.toDate());
        if(v.isValid()) {
          return v.format(format);
        }
      }
      const v = moment(value);
      if(v.isValid()) {
        return v.format(format);
      }
      return '';
    };

    app.config.globalProperties.$trdb = function(obj:any) {
      if(this.$i18n && this.$i18n.locale) {
        const t = translateDb(this.$i18n.locale, obj);
        if(t) {
          return t;
        }
      }
      return translateDb('pt-BR', obj);
    };

    app.config.globalProperties.$trdbLocale = function() {
      if(this.$i18n && this.$i18n.locale) {
        return (languages[this.$i18n.locale] || {}).database;
      }
      return (languages[i18nConfig.fallbackLocale] || {}).database;
    };

    /**
     * @param {string|Object=} arg
     * @param {string} [arg.title=''] arg.title
     * @param {string} [arg.text=''] arg.text
     * @param {number} [arg.timer=2500] arg.timer
     */
    app.config.globalProperties.$showMsg = function(arg: MsgBase) {
      // @ts-ignore
      let {title='', text=null, timer=2500} = arg || {};
      if(typeof arg==='string') {
        title = arg;
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    /**
     * @param {string|Object=} arg
     * @param {string} [arg.title=''] arg.title
     * @param {string} [arg.text=''] arg.text
     * @param {number} [arg.timer=3500] arg.timer
     */
    app.config.globalProperties.$showWarning = function(arg:MsgBase) {
      // @ts-ignore
      let {title='', text=null, timer=3500} = arg || {};
      if(typeof arg==='string') {
        title = arg;
      }
      if(!title && !text) {
        title = i18n.global.t('form.error.warning');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    /**
     * @param {string|Object=} arg
     * @param {string} [arg.title=''] arg.title
     * @param {string} [arg.text=''] arg.text
     * @param {number} [arg.timer=2500] arg.timer
     */
    app.config.globalProperties.$showSuccess = function(arg:MsgBase) {
      // @ts-ignore
      let {title='', text=null, timer=2500} = arg || {};
      if(typeof arg==='string') {
        title = arg;
      }
      if(!title && !text) {
        title = i18n.global.t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    /**
     * @param {string|Object=} arg
     * @param {string} [arg.title=''] arg.title
     * @param {string} [arg.text=''] arg.text
     * @param {number} [arg.timer=4500] arg.timer
     */
    app.config.globalProperties.$showError = function(arg:MsgBase) {
      // @ts-ignore
      let {title='', text=null, timer=4500} = arg || {};
      if(typeof arg==='string') {
        title = arg;
      }
      if(!title && !text) {
        title = i18n.global.t('form.error.update');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    /**
     *
     * @returns {Promise<boolean>}
     * @param arg
     */
    app.config.globalProperties.$showConfirm = async function(arg:Confirm):Promise<boolean> {
      // @ts-ignore
      let {title='', text=null, confirmButtonText = null, cancelButtonText = null} = arg;
      if(!title && !text) {
        title = i18n.global.t('form.confirmQuestion');
      }
      const result = await this.$swal({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: confirmButtonText || i18n.global.t('form.confirmQuestionYes'),
        cancelButtonText: cancelButtonText || i18n.global.t('form.confirmQuestionCancel')
      });
      return !!result.value;
    };

    app.config.globalProperties.$showPrompt = async function(arg:Prompt) {
      // @ts-ignore
      let {title='', text=null, input='text', confirmButtonText=i18n.global.t('form.confirmQuestionYes'), cancelButtonText=i18n.global.t('form.confirmQuestionCancel'),
        // @ts-ignore
        inputAttributes = {}, inputValue='', required=false, textMessageOnRequired=''} = arg;
      if(!title && !text) {
        title = i18n.global.t('form.promptQuestion');
      }
      const result = await this.$swal.fire({
        title: title,
        text: text,
        input: input,
        inputAttributes: inputAttributes,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        inputValue: inputValue,
        inputValidator: ()=>{return false;},
        preConfirm: (textConfirm?:string) => {
          if (required && !textConfirm) {
            this.$swal.showValidationMessage(textMessageOnRequired || i18n.global.t('form.answerRequired'));
          }
        }
      });
      // console.log('result', result);
      if(result.isConfirmed) {
        return result.value;
      }
      return false;
    };


    app.config.globalProperties.$newId = function(table='id') {
      return firestore.collection(table).doc().id;
    };
    // Old, chamando uma cloud que é muito lenta
    app.config.globalProperties.$newSequence = async function(id:string, table:string='', field:string='', extra:any=null) {
      try {
        return (await functions.httpsCallable('sequential-getNewSequential')({
          ...extra,
          id, table, field
        }))?.data?.data || 0;
      } catch(err) {
        console.error('Error $newSequence', err);
        return 0;
      }
    };

    // app.config.globalProperties.$newSequence = async function(id, table='', field='', extra=null) {
    //   try {
    //     return (await getNewSequential({
    //       ...extra,
    //       id, table, field
    //     })) || 0;
    //   } catch(err) {
    //     console.error('Error $newSequence', err);
    //     return 0;
    //   }
    // };
    app.config.globalProperties.$newSequenceMulti = async function(id:string, table:string='', field:string='', extra:any=null, qtd:number=1) {
      return 0;
      // TODO FCM
      // try {
      //   return (await getNewSequentialMulti({
      //     ...extra,
      //     id, table, field, qtd
      //   })) || 0;
      // } catch(err) {
      //   console.error('Error $newSequence', err);
      //   return 0;
      // }
    };

    app.config.globalProperties.$metaInfo = function(isNew=false, isAdmin=false) {
      let metaInfo = {};
      if(!isAdmin) {
        isAdmin = authStore.currentWorkspace===Workspace.ADMIN;
      }
      if (isNew) {
        metaInfo = {
          createdAt: FieldValue.serverTimestamp(),
          createdBy: authStore.currentUser?.id || '',
          createdByName: authStore.currentUser?.name || '',
          createdEnv: status.source.WEB,
        };
      }
      metaInfo = {
        ...metaInfo,
        updatedAt: FieldValue.serverTimestamp(),
        updatedBy: authStore.currentUser?.id || '',
        updatedByName: authStore.currentUser?.name || '',
        updatedEnv: status.source.WEB,
        updatedByAdmin: isAdmin,
      };
      return metaInfo;
    };

    app.config.globalProperties.$rules = {
      required: (value:any) => !!value || i18n.global.t('form.error.required'),
      requiredItemInArray: (value:any) => (value?.length > 0) || i18n.global.t('form.error.required'),
      counter: (value:any) => value.length <= 20 || i18n.global.t('form.error.maxLength'),
      minLength: (value:any) => {
        if(value && value.length > 0 && value.length < 3) {
          return i18n.global.t('form.error.minLengthRequired') + ' 3';
        } else {
          return true;
        }
      },
      minLengthCustom: (min:number) => {
        return (value:any) => {
          if(value?.length < min) {
            return i18n.global.t('form.error.minLengthRequired') + ' '+min;
          } else {
            return true;
          }
        };
      },
      maxLength: (max:number) => {
        return (value:any) => {
          if(value?.length > max) {
            return i18n.global.t('form.error.maxLengthRequired') + ' '+max;
          } else {
            return true;
          }
        };
      },
      countHours: (value:any) => {
        if(value && value.length > 0 && value.length < 5) {
          return i18n.global.t('form.error.invalidAmountHours');
        } else {
          return true;
        }
      } ,
      counterCNPJ: (value:any) => {
        if(value && value.length > 0 && value.length < 18) {
          return i18n.global.t('form.error.minLength') + ' ' + value.length+'/18';
        } else {
          return true;
        }
      } ,
      email: (value:any) => {
        if(!value) {return true;}
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || i18n.global.t('form.error.email');
      },
      numberDotComma: (value:any) => {
        const pattern = /^[0-9.,]*$/;
        return pattern.test(value) || i18n.global.t('form.error.invalidValue');
      },
      number: (value:any) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || i18n.global.t('form.error.justNumbers');
      },
      positive: (value:any) => {
        return (!value || value >=0) ||  i18n.global.t('form.error.needPositive');
      },
      cpf: (value:any)=>{
        if (!value) {
          return true;
        }
        if(StringUtils.validateCpf(value)) {
          return true;
        }
        return i18n.global.t('form.error.invalidCPF');
      }
    };

    // TODO FCM REMOVER
    app.config.globalProperties.$deburr = function(txt:string) {
      console.log('TODO remover');
      return deburr(txt|| '').toLowerCase().trim();
    };

    app.config.globalProperties.$money2Float = function(num:any) {
      if (num === '') {
        num = 0;
      } else {
        num = num.replace('R$ ', '');
        num = num.replace(/\./g, '');
        num = num.replace(',', '.');
        num = parseFloat(num);
      }
      return num;
    };

    app.config.globalProperties.$cpfExist =  async function(cpf:string, id:string, table:string) {
      if(!cpf) {
        return 'cpfInvalid';
      }
      if(StringUtils.validateCpf(cpf)!==true) {
        return 'cpfInvalid';
      }
      cpf = cpf.replace(/[.-]/g, '');
      if (cpf.length === 11) {
        try {
          const doc = await firestore.collection(table).where('cpf', '==', cpf).get();
          if(!doc.empty) {
            let find:any = false;
            if(id) {
              doc.forEach((obj)=>{
                if(obj.id===id) {
                  find = id;
                }
              });
            }
            if(!find) {
              return 'cpfExist';
            }
          }
          return 'cpfOk';
        } catch(error) {
          console.error('$cpfExist:', error);
          return 'cpfError';
        }
      }
      return 'cpfInvalid';
    };

    app.config.globalProperties.$sortByTimeStamp = function(items:any[], field:string) {
      return items.sort((a, b) => {
        if(a?.[field] && b?.[field]) {
          return b?.[field]?.seconds - a?.[field]?.seconds;
        }
        else if(a?.[field]) {
          return 1;
        }
        else if(b?.[field]) {
          return -1;
        }
        else {
          return 0;
        }
      });
    };

    app.config.globalProperties.$fcmClearAndReload = function() {
      if(window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function(registrations) {
            for(const registration of registrations) {
              registration.unregister?.();
            }
            window.location.reload();
          });
      }
      else {
        window.location.reload();
      }
    };

    app.config.globalProperties.$AuthActions = status.AuthActions;
    app.config.globalProperties.$companyModulesValidate = async function(module:string, action:string, displayMsg= false, actionOwn?:string, id?:string) {
      if(await authStore.authCompanyModulesValidate({module, action})) {
        return true;
      }
      if(actionOwn) {
        const hasOwn = await authStore.authCompanyModulesValidate({module, action: actionOwn});
        if(actionOwn && !id && hasOwn) {
          return true;
        }
        if(actionOwn && id && hasOwn && authStore.currentUser?.id===id) {
          return true;
        }
      }
      displayMsg && this.$showError({
        title: `${i18n.global.t('router.accessDenied')} ${i18n.global.t('router.modules.'+module)}: ${i18n.global.t('router.actions.'+action)}`,
        timer: 5500,
      });
      // console.log('DebugValidate');
      return false;
    };

    app.config.globalProperties.moment = moment;
    app.config.globalProperties._ = _;
  }
};
