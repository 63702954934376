/**
 * Quando hover typescript no front, incorporar o FcmFirestore.ts no front
 * Arquivo gerado com base em fcm/lib/src/FcmFirestore.ts
 * Não edite o arqivo FcmFirestore.js diretamente
 */


import { getFirestore, collection, getDocs, getDoc, doc, where, query, setDoc, onSnapshot,
  connectFirestoreEmulator, Timestamp, GeoPoint, serverTimestamp, limit, limitToLast,
  orderBy, startAfter, startAt, endAt, arrayUnion, arrayRemove, deleteField, increment,
  or, and, addDoc, updateDoc, deleteDoc, getCountFromServer, FieldPath as FieldPathFirestore,
  getAggregateFromServer, documentId, average, sum, count} from 'firebase/firestore';
// import { getFirestore, collection, getDocs, getDoc, doc, where, query, setDoc, onSnapshot,
// connectFirestoreEmulator, FieldPath, Timestamp, GeoPoint, FieldValue} from 'https://www.gstatic.com/firebasejs/10.0.0/firebase-firestore.js';
import type {FirebaseApp} from '@firebase/app';
import type {Firestore, SetOptions, WhereFilterOp} from '@firebase/firestore';
import {FcmBatch} from './FcmBatch';
import type {AggregateSpec} from 'firebase/firestore';
// import {AggregateSpec} from '@firebase/firestore';

function fcmSleep(ms:number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const FieldValue = {
  serverTimestamp: serverTimestamp,
  arrayUnion: arrayUnion,
  arrayRemove: arrayRemove,
  delete: deleteField,
  increment: increment,
};

const FieldPath = {
  documentId: documentId
};

export interface FcmAgregate {
  name: string,
  prop: string,
  agregate: 'average' | 'sum' | 'count'
}


const AggregateField = {
  average: average,
  sum: sum,
  count: count,
};

export class FcmFirestore {
  _app?:FirebaseApp;
  _firestore!:Firestore;
  FieldPath = FieldPath;
  Timestamp = Timestamp;
  AggregateField = AggregateField;
  GeoPoint = GeoPoint;
  FieldValue = FieldValue;
  constructor(app?:FirebaseApp) {
    if(app) {
      this.fcmInit(app);
    }
  }
  fcmInit(app?: FirebaseApp) {
    if(this._app!==undefined) {
      console.error('Não pode inicializar o firebase mais de uma vez!');
    }
    else {
      this._app = app;
      if(this._app) {
        this._firestore = getFirestore(this._app);
      }
      else {
        this._firestore = getFirestore();
      }
    }
  }

  collection(path:string):CollectionReference {
    return new CollectionReference(this, undefined, {path});
  }

  fcmNewId():string {
    return doc(collection(this._firestore, 'newId')).id;
  }
  useEmulator(host: string, port: number) {
    connectFirestoreEmulator(this._firestore, host, port);
  }
  batch(maxItems=499) {
    return new FcmBatch(this, maxItems);
  }
}

export class CollectionReference {
  fcmFirestore: FcmFirestore;
  path: string;
  hasOr: boolean=false;
  _withConverter?: any;

  queryArgs:any = [];

  constructor(fcmFirestore: FcmFirestore, before:any, changes:any) {
    this.fcmFirestore = fcmFirestore;
    this.path = changes?.path || before?.path;
    this.queryArgs = changes?.queryArgs || before?.queryArgs || [];
    this.hasOr = changes?.hasOr || before?.hasOr || false;
    this._withConverter = changes?._withConverter || before?._withConverter || undefined;
  }
  doc(docId?:string) {
    return new DocumentReference(this.fcmFirestore, this.path, docId);
  }
  get() {
    return getDocs(this.fcmGetRef());
  }

  fcmGetRef() {
    let ref = null;
    if(this.queryArgs.length) {
      if(this.hasOr) {
        ref = query(collection(this.fcmFirestore._firestore, this.path), and(...this.queryArgs));
      }
      else {
        ref = query(collection(this.fcmFirestore._firestore, this.path), ...this.queryArgs);
      }
    }
    else {
      ref = collection(this.fcmFirestore._firestore, this.path);
    }
    if (this._withConverter) {
      ref = ref.withConverter(this._withConverter);
    }
    return ref;
  }

  async fcmGet(): Promise<any[]> {
    return (await this.get()).docs.map((a:any)=>{
      const obj = a.data();
      obj.id = a.id;
      return obj;
    });
  }

  async fcmGetByDocsIds(ids: string[]): Promise<any[]> {
    return this.fcmGetByPropIds(documentId(), ids);
  }
  async fcmGetByPropIds(prop: string | FieldPathFirestore, ids: string[], op:WhereFilterOp='in'): Promise<any[]> {
    const result = [];
    for (let i=0;i<ids.length;i = i+10) {
      const ids10 = ids.slice(i, i+10);
      const trs = await this.where(prop, op, ids10)
        .get();
      for(const trObj of trs.docs) {
        result.push({
          ...trObj.data(),
          id: trObj.id
        });
      }
    }
    return result;
  }

  where(field:string | FieldPathFirestore, op:WhereFilterOp, value:any) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, where(field, op, value)]});
  }
  limit(size:number) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, limit(size)]});
  }
  limitToLast(size:number) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, limitToLast(size)]});
  }
  startAfter(item:any) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, startAfter(item)]});
  }
  startAt(item:any) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, startAt(item)]});
  }
  endAt(item:any) {
    return new CollectionReference(this.fcmFirestore, this,
      {queryArgs: [...this.queryArgs, endAt(item)]});
  }
  orderBy(orderByPath:string, orderByDirection?: 'desc' | 'asc') {
    if(orderByDirection) {
      return new CollectionReference(this.fcmFirestore, this,
        {queryArgs: [...this.queryArgs, orderBy(orderByPath, orderByDirection)]});
    }
    else {
      return new CollectionReference(this.fcmFirestore, this,
        {queryArgs: [...this.queryArgs, orderBy(orderByPath)]});
    }
  }

  or(args: Array<Array<any>>) {
    return new CollectionReference(this.fcmFirestore, this,
      {
        hasOr: true,
        queryArgs: [...this.queryArgs,
          or(...args.map((a)=>where(a[0], a[1], a[2])))]
      });
  }

  add(value:any) {
    return addDoc(collection(this.fcmFirestore._firestore, this.path), value);
  }

  // @ts-ignore
  onSnapshot(...args) {
    // @ts-ignore
    return onSnapshot(this.fcmGetRef(), ...args);
  }

  async fcmCount():Promise<number> {
    return (await getCountFromServer(this.fcmGetRef())).data().count || 0;
  }

  async fcmAgregate(agregate: AggregateSpec):Promise<any> {
    const result = (await getAggregateFromServer(this.fcmGetRef(), agregate));
    return result.data();
  }


  async fcmAgregateByPropIds(prop: string | FieldPathFirestore, ids: string[], agregate: AggregateSpec, op:WhereFilterOp='in'): Promise<any> {
    const result:any = {};
    for (let i=0;i<ids.length;i = i+10) {
      const ids10 = ids.slice(i, i+10);
      const trs = await this.where(prop, op, ids10).fcmAgregate(agregate);
      for(const prop in trs) {
        result[prop] ||= 0;
        result[prop] += trs[prop];
      }
    }
    return result;
  }

  async* fcmGenerator(cacheSize=100) {
    const ref = this.fcmGetRef();
    let itemsObj = await getDocs(query(ref, limit(cacheSize)));
    while(itemsObj.docs && itemsObj.docs.length>0) {
      const last = itemsObj.docs[itemsObj.docs.length-1];
      for(const index in itemsObj.docs) {
        const itemObj = itemsObj.docs[index].data();
        itemObj.id = itemsObj.docs[index].id;
        yield itemObj;
      }
      itemsObj = await getDocs(query(ref, limit(cacheSize), startAfter(last)));
    }
  }
  /*

  async function* (ref, limit=1000) {
  let itemsObj = await ref.limit(limit).get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index].data();
      Object.defineProperty(itemObj, 'id', {
        enumerable: false,
        value: itemsObj.docs[index].id
      });
      yield itemObj;
    }
    itemsObj = await ref.limit(limit).startAfter(last).get();
  }
}
   */

  async fcmSliceUpdate(items:Array<any>, pk='id', slice=100, delay=0) {
    let batch = this.fcmFirestore.batch(slice);
    let iAtual = 0;
    let iLocal = 0;
    let hasError = false;
    try {
      for(iAtual=0;iAtual<items.length;iAtual++) {
        await batch.set(this.doc(items[iAtual][pk]), items[iAtual], {merge:true});
        if(iLocal>=slice) {
          iLocal = 0;
          try {
            // console.log('Commit parcial', iAtual);
            await batch.commit();
            if(delay) {
              await fcmSleep(delay);
            }
            //   console.log('Commit end', iAtual);
          } catch(error) {
            hasError = true;
            console.error('Error, await batch.commit();', error);
          }
          batch = this.fcmFirestore.batch(slice);
        }
        else {
          iLocal++;
        }
      }

      if (iLocal > 0) {
        await batch.commit();
      }
    }catch(error) {
      hasError = true;
      console.error('Error, await batch.commit();', error);
    }
    if(hasError) {
      return Promise.reject(new Error('Error, await batch.commit();'));
    }
    return 'ok';
  }
  withConverter(obj:any) {
    return new CollectionReference(this.fcmFirestore, this,
      {_withConverter: obj});
  }

}

export class DocumentReference {
  fcmFirestore: FcmFirestore;
  path: string;
  docId: string;
  _withConverter?: any;
  constructor(fcmFirestore: FcmFirestore, path: string, docId?: string) {
    this.fcmFirestore = fcmFirestore;
    this.path = path;
    if(docId===undefined) {
      this.docId = doc(collection(this.fcmFirestore._firestore, path)).id;
    }
    else {
      this.docId = docId;
    }
  }
  async get() {
    return getDoc(this.fcmGetRef());
  }
  async fcmGet() {
    const doc = await getDoc(this.fcmGetRef());
    if (!doc.exists()) {
      return null;
    }
    const obj = doc.data();
    obj.id = doc.id;
    return obj;
  }
  fcmGetRef() {
    if (!this.docId) {
      console.error('Error FcmFirestore: docId não definido', this.docId, this.path);
    }
    if (this._withConverter) {
      return doc(this.fcmFirestore._firestore, this.path, this.docId).withConverter(this._withConverter);
    }
    return doc(this.fcmFirestore._firestore, this.path, this.docId);
  }

  get id() {
    return this.docId;
  }
  set(value:any, options?:SetOptions) {
    if(options) {
      return setDoc(this.fcmGetRef(), value, options);
    }
    return setDoc(this.fcmGetRef(), value);
  }

  update(value:any, options?:SetOptions) {
    if(options) {
      return updateDoc(this.fcmGetRef(), value, options);
    }
    return updateDoc(this.fcmGetRef(), value);
  }

  delete() {
    return deleteDoc(this.fcmGetRef());
  }

  // @ts-ignore
  onSnapshot(...args) {
    // @ts-ignore
    return onSnapshot(this.fcmGetRef(), ...args);
  }

  withConverter(obj:any) {
    this._withConverter = obj;
    return this;
  }





  // TODO
  // get parent(): DocumentReference<DocumentData> | null;
  // get path(): string;

}


const firestore = new FcmFirestore();

/**
 * Gambiarra para usar na comom de forma análoga ao admin
 */
const FcmFirebase = {
  firestore: ()=>(firestore)
};


// @ts-ignore
FcmFirebase.firestore.FieldPath = FieldPath;
// @ts-ignore
FcmFirebase.firestore.Timestamp = Timestamp;
// @ts-ignore
FcmFirebase.firestore.GeoPoint = GeoPoint;
// @ts-ignore
FcmFirebase.firestore.FieldValue = FieldValue;
// @ts-ignore
FcmFirebase.firestore.AggregateField = AggregateField;

export {firestore, FieldPath, Timestamp, GeoPoint, FieldValue, FcmFirebase, AggregateField};



