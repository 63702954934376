import { useConfigStore } from './config';
import { useAuthStore } from './auth';
import { useCacheStore } from './caches';

export function initStores() {
  const configStore = useConfigStore();
  const authStore = useAuthStore();
  const cacheStore = useCacheStore();
  configStore.init();
  authStore.init();
  cacheStore.init();
}



