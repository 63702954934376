import { toRefs, reactive, computed } from 'vue';
import {FcmLocalStorage} from '@/utils/FcmLocalStorage';
import {vuetify} from '@/plugins/vuetify';


export const themesConfig:{[key:string]:ThemeConfig} = {
  'saga-blue' : {
    theme: 'saga-blue',
    href: '/themes/saga-blue/theme.css',
    darkTheme: false,
  },
  // 'volan-light' : {
  //   theme: 'volan-light',
  //   href: '/themes/volan-light/theme.css',
  //   darkTheme: false,
  // },
  // 'volan-dark-lara' : {
  //   theme: 'volan-dark-lara',
  //   href: '/themes/volan-dark-lara/theme.css',
  //   darkTheme: true,
  // },
  // 'volan-light-lara' : {
  //   theme: 'volan-light-lara',
  //   href: '/themes/volan-light-lara/theme.css',
  //   darkTheme: false,
  // }
};

const replaceLink = (linkElement:HTMLLinkElement, href:any, onComplete?:any) => {
  if (!linkElement || !href) {
    return;
  }
  const id = linkElement.getAttribute('id') as string;
  const cloneLinkElement = linkElement.cloneNode(true) as HTMLLinkElement;
  cloneLinkElement.setAttribute('href', href);
  cloneLinkElement.setAttribute('id', id + '-clone');
  (linkElement.parentNode as HTMLLinkElement).insertBefore(cloneLinkElement, linkElement.nextSibling);
  cloneLinkElement.addEventListener('load', () => {
    linkElement.remove();

    const element = document.getElementById(id); // re-check
    element && element.remove();

    cloneLinkElement.setAttribute('id', id);
    onComplete && onComplete();
  });
};

export function getThemeConfig(input?:string): ThemeConfig {
  const theme = input || FcmLocalStorage.getItem('theme') || '';
  if (themesConfig[theme]) {
    return themesConfig[theme];
  }
  // else if (theme==='system') {
  //   if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //     return {...themesConfig['volan-dark'], isSystem: true};
  //   }
  //   else {
  //     return {...themesConfig['volan-light'], isSystem: true};
  //   }
  // }
  return themesConfig['saga-blue'];
}

const themeLink:HTMLLinkElement = document.getElementById('theme-link') as HTMLLinkElement;
const _theme = getThemeConfig();
replaceLink(themeLink, _theme.href);

const layoutConfig = reactive({
  menuMode: 'static',
  newMenu: true,
  darkTheme: _theme.darkTheme,
  theme: _theme.theme,
  scale: 14,
  isSystem: !!_theme.isSystem,
});

const layoutState = reactive({
  staticMenuDesktopInactive: false,
  overlayMenuActive: false,
  sidebarActive: false,
  anchored: false,
  overlaySubmenuActive: false,
  profileSidebarVisible: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false,
  activeMenuItem: null,
});

interface ThemeConfig {
  theme: string,
  href: string,
  darkTheme: boolean,
  isSystem?: boolean,
}


export function useLayout() {
  const setScale = (scale:number) => {
    layoutConfig.scale = scale;
  };

  const setTheme = (theme:string) => {
    const themeLink:HTMLLinkElement = document.getElementById('theme-link') as HTMLLinkElement;
    const tc = getThemeConfig(theme);
    if (!tc) {
      console.error('Erro ao trocar tema');
      return;
    }
    replaceLink(themeLink, tc.href, () => {
      layoutConfig.theme = theme;
      layoutConfig.darkTheme = tc.darkTheme;
      layoutConfig.isSystem = tc.isSystem || false;
    });
    FcmLocalStorage.setItem('theme', theme);
    vuetify.theme.global.name.value = tc.darkTheme ? 'dark' : 'light';
  };

  const setActiveMenuItem = (item:any) => {
    layoutState.activeMenuItem = item.value || item;
  };

  const onMenuToggle = () => {
    if (layoutConfig.menuMode === 'overlay') {
      layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
    }

    if (window.innerWidth > 991) {
      layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
    } else {
      layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
    }
  };

  const onProfileSidebarToggle = () => {
    layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible;
  };

  const onConfigSidebarToggle = () => {
    layoutState.configSidebarVisible = !layoutState.configSidebarVisible;
  };

  const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive);

  const isDarkTheme = computed(() => layoutConfig.darkTheme);

  const isDesktop = computed(() => window.innerWidth > 991);

  const isSlim = computed(() => layoutConfig.menuMode === 'slim');
  const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus');

  const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal');

  return {
    layoutConfig: toRefs(layoutConfig),
    layoutState: toRefs(layoutState),
    setScale,
    onMenuToggle,
    isSidebarActive,
    isDarkTheme,
    setActiveMenuItem,
    onProfileSidebarToggle,
    onConfigSidebarToggle,
    isSlim,
    isSlimPlus,
    isHorizontal,
    isDesktop,
    setTheme
  };
}
