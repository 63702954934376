'use strict';
/**
 * Base para entidades
 * Propriedades na raiz iniciados com _ são ignorados da grvação no banco
 * O firebase não aceita uma instância de classe como parâmetro, é necessário transformar em um objeto comum,
 *    toFirestore faz isso para você
 * Não há tratamento especial para o campo id
 * Passar propriedades inexixstentes na Entidade para o contrutor ou não tem efeito.
 * Definir uma propriedade nova diretamente funciona, mas não é recomendado
 */

import {isEmpty, clone, cloneDeep} from 'lodash-es';
import moment from 'moment';



export function internalToFirestore(obj:any, count=7) {
  if (typeof obj?.toDate==='function') {
    return obj.toDate();
  }
  // Deixei assim porque da pra mandar um Date pro firestore que ele salva também
  // Qualquer coisa me confirmar dpeois, Crominski.
  else if (obj instanceof Date) {
    return obj;
  }
  let lazy = null;
  if (obj instanceof BaseEntity) {
    lazy = {...obj};
  }
  else {
    lazy = clone(obj);
  }
  for (const key of Object.keys(lazy || {})) {
    if (key[0] === '_') {
      delete lazy[key];
    }
    else if (lazy[key] && typeof lazy[key]==='object') {
      if (typeof lazy[key].toFirestore==='function') {
        lazy[key] = lazy[key].toFirestore();
      }
      else {
        lazy[key] = internalToFirestore(lazy[key], count-1);
      }
    }
  }
  return lazy;
}

export function internalToFirestoreClear(obj:any, count=7) {
  if (typeof obj?.toDate==='function' || obj instanceof Date) {
    return obj.toDate();
  }
  let lazy = null;
  if (obj instanceof BaseEntity) {
    lazy = {...obj};
  }
  else {
    lazy = clone(obj);
  }
  for (const key of Object.keys(lazy || {})) {
    if (key[0] === '_') {
      delete lazy[key];
    }
    else if (lazy[key] && typeof lazy[key]==='object') {
      if (typeof lazy[key].toFirestoreClear==='function') {
        lazy[key] = lazy[key].toFirestoreClear();
      }
      else {
        const tmp = internalToFirestoreClear(lazy[key], count-1);
        if (isEmpty(tmp) && !(tmp instanceof Date) && typeof tmp==='object') {
          delete lazy[key];
        }
        else {
          lazy[key] = tmp;
        }
      }
    }
  }
  return lazy;
}

export class BaseEntity {
  [key: string]: any;
  static _defaults = {};
  constructor(obj?:BaseEntity|any) {
    // setFcm não pode ser chamado aqui pois as propriedades ainda não foram definidas
    // É necessário chamar o this.setFcm(obj) no construtor da classe extendida
  }
  setFcm(obj?:BaseEntity|any) {
    if(typeof obj?.updatedAt?.toDate ==='function') {
      obj.updatedAt = obj.updatedAt.toDate();
    }
    if(typeof obj?.deletedAt?.toDate ==='function') {
      obj.deletedAt = obj.deletedAt.toDate();
    }
    if(typeof obj?.createdAt?.toDate ==='function') {
      obj.createdAt = obj.createdAt.toDate();
    }

    if(obj?.updatedAt && typeof obj.updatedAt === 'string') {
      obj.updatedAt = moment(obj.updatedAt)?.toDate();
    }
    if(obj?.deletedAt && typeof obj.deletedAt === 'string') {
      obj.deletedAt = moment(obj.deletedAt)?.toDate();
    }
    if(obj?.createdAt && typeof obj.createdAt === 'string') {
      obj.createdAt = moment(obj.createdAt)?.toDate();
    }

    if(!obj) {
      return;
    }
    for(const key of Object.keys(this)) {
      if(typeof obj[key]!=='undefined') {
        this[key] = obj[key];
      }
    }
    return this;
  }

  /**
   *
   * @returns {{}}
   */
  getMutation() {
    return BaseEntity.getMutation(this);
  }

  /**
   *
   * @param {object} data
   * @returns {{}}
   */
  static getMutation(data:any) {
    const newData:any = {};
    for (const key of Object.keys(data || {})) {
      if(key[0]==='_') {
        continue;
      }
      if(data[key] !== undefined) {
        newData[key] = {
          set: data[key]
        };
      }
    }
    return newData;
  }

  /**
   *
   * @param {string='id'} key
   * @returns {{update: {}, create: {}, [key]: string}}
   */
  getFullMutation(key='id') {
    return BaseEntity.getFullMutation(this, key);
  }

  /**
   *
   * @param {object} data
   * @param {string='id'} keyWhere
   * @returns {{update: {}, create: {}, where: {}}}
   */
  static getFullMutation(data:any, keyWhere='id') {
    const update:any = {};
    const create = cloneDeep(data);
    for (const key of Object.keys(create || {})) {
      if(key[0]==='_') {
        delete create[key];
        continue;
      }
      if (typeof create[key]?.toDate ==='function') {
        create[key] = create[key].toDate();
      }

      if(create[key] !== undefined) {
        update[key] = {
          set: create[key]
        };
      }
      if(Array.isArray(create[key])) {
        create[key] = {set: create[key]};
      }
    }
    return {
      update: update,
      create: create,
      where: {
        [keyWhere]: create[keyWhere]
      },
    };
  }

  /*
   * @deprecated em favor de toFirestore
   * @returns {BaseEntity}
   */
  // getFirestoreObject() {
  //   const lazy = {...this};
  //   for (const key of Object.keys(lazy || {})) {
  //     if (key[0] === '_') {
  //       delete lazy[key];
  //     }
  //   }
  //   return lazy;
  // }

  /*
   * @deprecated em favor de fromFirestore
   * @param obj
   * @returns {BaseEntity}
   */
  // static createByFirestoreObject(obj) {
  //   const result = new this(obj.data());
  //   result.id = obj.id;
  //   result.makeSearch();
  //   return result;
  // }

  static toFirestore(obj:any) {
    if (typeof obj.fixComputed==='function') {
      obj.fixComputed();
    }
    if (typeof obj.toFirestore==='function') {
      return obj.toFirestore();
    }
    return internalToFirestore(this);
  }

  // nomeclatura usada pelo firestore no .withConverter  ex: doc(db, "cities", "LA").withConverter(PaymentEntity);
  toFirestore() {
    if (typeof this.fixComputed==='function') {
      this.fixComputed();
    }
    return internalToFirestore(this);
  }

  toFirestoreClear() {
    return internalToFirestoreClear(this);
  }

  // nomeclatura usada pelo firestore no .withConverter  ex: doc(db, "cities", "LA").withConverter(PaymentEntity);
  static fromFirestore(obj:any) {
    const result = new this(obj.data());
    result.id = obj.id;
    result.makeSearch();
    return result;
  }

  makeSearch() {
    // Exemplos

    // this._search = StringUtils.deburr(this.name);

    // this._search = StringUtils.deburr([
    //   this.fetched.receiver.name,
    //   this.fetched.hospital.name,
    //   this.fetched.payer.name,
    //   this.fetched.payer.CNPJ
    // ].filter(a=>!!a).join(' '));
  }


  /**
   * Populate the object with a sampler of entity
   */
  example() {
    return this;
  }

  /**
   * Corrigir valores computados, o toFirestore chama antes de salvar
   */
  fixComputed() {
    // Corrigir valores computados
  }

  //
  // test() {
  //   console.log(this.constructor._defaults);
  // }
}

