<script setup lang="ts">
import AppBreadcrumb from './AppBreadcrumb.vue';
import {ref, onMounted, onBeforeUnmount} from 'vue';
import type {Ref} from 'vue';
import {useLayout} from '@/layouts/composables/layout';
import {useConfigStore} from '@/stores/config';

const {onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle} = useLayout();
const configStore = useConfigStore();

const outsideClickListener: Ref<null | ((value: any) => void)> = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event: any) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl: HTMLElement = document.querySelector('.layout-topbar-menu') as HTMLElement;
  const topbarEl: HTMLElement = document.querySelector('.layout-topbar-menu-button') as HTMLElement;

  return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const showProfileSidebar = () => {
  onProfileSidebarToggle();
};
const onConfigButtonClick = () => {
  onConfigSidebarToggle();
};
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-start">
      <Button type="button" class="topbar-menubutton p-link p-trigger" @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </Button>
      <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <li v-if="!configStore.isOnline" class="ml-3">
          <VIcon icon="mdi-wifi-strength-off" color="error" />
        </li>
        <li class="ml-3">
          <Button icon="pi pi-cog" rounded text @click="onConfigButtonClick"></Button>
        </li>
<!--        <li class="ml-3">-->
<!--          <Button v-if="layoutConfig.darkTheme.value" icon="pi pi-moon" rounded text-->
<!--                  @click="setTheme('volan-light')"></Button>-->
<!--          <Button v-else icon="pi pi-sun" rounded text @click="setTheme('volan-dark')"></Button>-->
<!--        </li>-->
        <li class="topbar-profile">
          <a @click="showProfileSidebar" class="hover:shadow-none jwCursorPointer">
            <Avatar icon="pi pi-user" rounded/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
