<script setup lang="ts">
import {computed, watch, ref, onBeforeUnmount} from 'vue';
import type {Ref} from 'vue';
import {usePrimeVue} from 'primevue/config';
import AppTopbar from './AppTopbar.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import AppProfileSidebar from './AppProfileSidebar.vue';
import AppBreadCrumb from './AppBreadcrumb.vue';
import {useLayout} from '@/layouts/composables/layout';
import {useRoute} from 'vue-router';

const $primevue = usePrimeVue();
const {layoutConfig, layoutState, isSidebarActive} = useLayout();
const outsideClickListener: Ref<null | ((value: any) => void)> = ref(null);
const sidebarRef: Ref<any> = ref(null);
const topbarRef: Ref<any> = ref(null);
const route = useRoute();

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const containerClass = computed(() => {
  return {
    'layout-light': !layoutConfig.darkTheme.value,
    'layout-dark': layoutConfig.darkTheme.value,
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-slim': layoutConfig.menuMode.value === 'slim',
    'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
    'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
    'layout-reveal': layoutConfig.menuMode.value === 'reveal',
    'layout-drawer': layoutConfig.menuMode.value === 'drawer',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-input-filled': $primevue.config.inputStyle === 'filled',
    'p-ripple-disabled': $primevue.config.ripple === false,
    'layout-sidebar-active': layoutState.sidebarActive.value,
    'layout-sidebar-anchored': layoutState.anchored.value,
    'layout-limit-width': !route.meta.disableWidthLimit,
    'layout-newMenu': layoutConfig.newMenu.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event: any) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.overlaySubmenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event: any) => {
  const sidebarEl = sidebarRef?.value.$el;
  const topbarEl = topbarRef?.value.$el.querySelector('.topbar-menubutton');

  return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
  <div :class="['layout-container', 'layout-colorscheme-menu', { ...containerClass }]">
    <AppSidebar ref="sidebarRef"/>
    <div class="layout-content-wrapper">
      <AppTopbar ref="topbarRef"/>
      <AppBreadCrumb class="content-breadcrumb"></AppBreadCrumb>
      <div class="layout-content">
        <RouterView></RouterView>
      </div>
    </div>
    <!--    <div class="teste"></div>-->
    <AppProfileSidebar/>
    <AppConfig/>
    <!--        <Toast></Toast>-->
    <div class="layout-mask"></div>
  </div>
</template>


<style lang="scss" scoped>

//.teste {
//  position: absolute;
//  z-index: 1000;
//  top: 0;
//  left: 16rem;
//  background-color: blue;
//  width: 40px;
//  height: 40px;
//}

</style>



















