'use strict';


if (!process.env.GCLOUD_PROJECT_ON_BUILD && process.env.GCLOUD_PROJECT) {
  process.env.GCLOUD_PROJECT_ON_BUILD = process.env.GCLOUD_PROJECT;
}

// console.log('AFEE', process.env.GCLOUD_PROJECT_ON_BUILD);

export let config:any;

const links = {
  site: {
    name: 'Site',
    link: 'https://cliniccompanion.com.br/'
  },
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/'
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/'
  }
};


const android = {
  linkStore: 'https://play.google.com/store/apps/details?id=br.com.justworks',
  storeCode: '',
  id: '',
  uri: 'vnd.companion://',
  name: 'Companion',
  packageName: 'br.com.justworks'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/app/',
  storeCode: '',
  id: 'id1550690941',
  uri: 'vnd.companion://',
  name: 'Companion',
  packageName: '',
};

// TODO FCM importar demais configs, configs add: publicPath

export const listConfig:any = {
  'default': {
    configId: 'default',
    name: 'companion DEVELOPER',
    maps: {
      key: ''
    },
    title: 'Companion DEV ',
    description: 'Companion!',

    baseUrl: 'https://cliniccompanion.com.br',
    publicPath: '/',
    firebase: {
      apiKey: 'AIzaSyCvpUoZZKH7z2-6kgw8DPrkIeDMJLmh1OE',
      authDomain: 'cliniccompanion-ffe6a.firebaseapp.com',
      projectId: 'cliniccompanion-ffe6a',
      storageBucket: 'cliniccompanion-ffe6a.appspot.com',
      messagingSenderId: '509484648209',
      databaseURL: 'https://cliniccompanion-ffe6a-default-rtdb.firebaseio.com',
      appId: '1:509484648209:web:c958b4199983f979b26eb0',
      measurementId: 'G-Y8Y89GX8RX'
    },
    functionsUrl: 'https://southamerica-east1-cliniccompanion-ffe6a.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'admin.cliniccompanion.com.br',
    region: 'southamerica-east1',
    isDevServer: true,
  },
  'cliniccompanion-ffe6a': {
    configId: 'cliniccompanion-ffe6a',
    name: 'ClinicCompanion',
    maps: {
      key: ''
    },
    title: 'ClinicCompanion',
    description: 'ClinicCompanion!',

    baseUrl: 'https://cliniccompanion.com.br',
    publicPath: '/',
    firebase: {
      apiKey: 'AIzaSyCvpUoZZKH7z2-6kgw8DPrkIeDMJLmh1OE',
      authDomain: 'cliniccompanion-ffe6a.firebaseapp.com',
      projectId: 'cliniccompanion-ffe6a',
      storageBucket: 'cliniccompanion-ffe6a.appspot.com',
      messagingSenderId: '509484648209',
      databaseURL: 'https://cliniccompanion-ffe6a-default-rtdb.firebaseio.com',
      appId: '1:509484648209:web:c958b4199983f979b26eb0',
      measurementId: 'G-Y8Y89GX8RX'
    },
    functionsUrl: 'https://southamerica-east1-cliniccompanion-ffe6a.cloudfunctions.net',
    fbId: '',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#4DBA87',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'admin.cliniccompanion.com.br',
    region: 'southamerica-east1',
    isDevServer: false,
  }
};


if (process.env.GCLOUD_PROJECT_ON_BUILD === 'cliniccompanion-ffe6a') {
  config = listConfig['cliniccompanion-ffe6a'];
  // console.log('Server config start configName: ' + config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}
else if (process.env.GCLOUD_PROJECT_ON_BUILD && listConfig[process.env.GCLOUD_PROJECT_ON_BUILD]) {
  config = listConfig[process.env.GCLOUD_PROJECT_ON_BUILD];
  console.log('Server config start configName: ' + config.name + ' on project: ' + (process.env.GCLOUD_PROJECT_ON_BUILD || ''));
}
else {
  config = listConfig['cliniccompanion-ffe6a']; // TODO FCM alterar para default
  console.log('Server config start configName: ' + config.name + ' on project: ' + (process.env.GCLOUD_PROJECT_ON_BUILD || ''));
}

// console.log('Server config start configName: ' + config.name);
// @ts-ignore
if (typeof window !== 'undefined' && window?.location?.origin?.includes('localhost')) {
  // @ts-ignore
  config.baseUrl = window.location.origin;
}

if (process.env.USE_LOCAL_CLOUD) {
  config.functionsUrl = 'http://localhost:47672/cliniccompanion-ffe6a/southamerica-east1';
}

export const appConfig = config;

