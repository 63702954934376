'use strict';


// Baseado no Vuetify
export function getNestedValue(obj:any, path:any[], fallback:any) {
  if(!path) {
    return obj;
  }
  const last = path.length - 1;

  if (last < 0) { return obj === undefined ? fallback : obj; }

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) { return fallback; }
  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

// Baseado no Vuetify
export function getObjectValueByPath(obj:any, path:string, fallback:any=null, getter:any=null) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if((typeof path !== 'string' || !path) && typeof getter ==='function') {
    return getter(obj || fallback);
  }
  if (obj == null || !path || typeof path !== 'string') { return fallback; }
  if (obj[path] !== undefined) {
    if(typeof getter ==='function') {
      return getter(obj[path]);
    }
    return obj[path];
  }
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot

  if(typeof getter ==='function') {
    return getter(getNestedValue(obj, path.split('.'), fallback));
  }
  return getNestedValue(obj, path.split('.'), fallback);
}

export function transverseUndefinedToNull(obj:any, maxDeep=8) {
  if(maxDeep<=0) {
    console.warn('transverseUndefinedToNull atingiu o número máximo de profundidade');
    return;
  }
  for (const k in obj) {
    if(obj[k] === undefined) {
      obj[k] = null;
    }
    else if(typeof obj[k] === 'object') {
      transverseUndefinedToNull(obj[k], maxDeep - 1);
    }
  }
  return obj;
}

export function transverse2Date(obj:any, maxDeep=8) {
  if(maxDeep<=0) {
    console.warn('transverse2Date atingiu o número máximo de profundidade');
    return;
  }
  for (const k in obj) {
    if(obj[k] && typeof obj[k]?.toDate === 'function') {
      obj[k] = obj[k].toDate();
    }
    else if(typeof obj[k] === 'object') {
      transverse2Date(obj[k], maxDeep - 1);
    }
  }
  return obj;
}

export function traverse2FirestoreDate(Timestamp:any, obj:any, maxDeep=8) {
  if(maxDeep<=0) {
    console.warn('traverse2FirestoreDate atingiu o número máximo de profundidade');
    return;
  }
  for (const k in obj) {
    if (obj[k]) {
      if(obj[k] instanceof Date) {
        obj[k] = Timestamp.fromDate(obj[k]);
      }
      else if(typeof obj[k] === 'object') {
        traverse2FirestoreDate(Timestamp, obj[k], maxDeep-1);
      }
    }
  }
}


export function mergeSumObj(objs:any, maxDeep=8, ret:any= {}) {
  if(maxDeep<=0) {
    console.warn('mergeSumObj atingiu o número máximo de profundidade');
    return;
  }
  const keys:any = {};
  for (const obj of objs) {
    for (const j in obj) {
      keys[j] = true;
    }
  }
  for (const i in keys) {
    const objItems = [];
    for (const obj of objs) {
      if(typeof obj?.[i] ==='undefined') {
        continue;
      }
      if(typeof obj[i] === 'object') {
        objItems.push(obj[i]);
      }
      else if(typeof obj[i] === 'number') {
        ret[i] = (ret[i]||0) + obj[i];
      }
    }
    if(objItems.length) {
      ret[i] = {};
      mergeSumObj(objItems, maxDeep-1, ret[i]);
    }
  }
  return ret;
}










